import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDropdown } from '../components';
import AjudaFormTela from './AjudaFormTela';
import IItinerarioTurma from '../interfaces/IItinerarioTurma';
import { CheckboxChangeParams } from 'primereact/checkbox';
import Loading from '../components/Loading';
import { ALERTAS } from '../utilities/constantes';

const ItinerarioTurmaAluno = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'FuncionarioForm';
    const dt = useRef<any>(null);

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [turmas, setTurmas] = useState<any>(null);
    const [itinerarios, setItinerarios] = useState<any>(null);
    const [alunos, setAlunos] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const defaultValues: IItinerarioTurma = {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        TurmaId: 0,
        ItinerariosFormativosId: 0,
        Codigo: '',
        DisciplinaId: 0,
        MateriaId: 0,
        TurmaAuxiliarId: 0,
        CargaHoraria: 0,
        Matricula: 0,
        Selecionado: 0,
        NomeAluno: '',
    };

    const methods = useForm({ defaultValues });
    const { handleSubmit, watch, setValue } = methods;

    const watchItinerarios = watch('ItinerariosFormativosId');
    const watchCodigoTurma = watch('Codigo');

    const load = async () => {
        const turma = await api.get('/turmas', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        });

        const aluns = await api.get('/alunosPeriodo');

        if (turma.data && aluns.data) {
            const turmasFiltradas = turma.data.filter((turma: any) =>
                aluns.data.some((aluno: any) => aluno.TurmaCodigo === turma.Codigo && aluno.SituacaoAcademica === 'M')
            );
            setTurmas(turmasFiltradas);
        } else {
            setTurmas([]);
        }

        const itinerario = await api.get('/itinerarios/comboItinerario');
        if (itinerario.data) {
            const itinerarioCodigoDescricao = itinerario.data.map((itifor: any) => ({
                ...itifor,
                ItiForCodigoDescricao: `${itifor.CodigoIti} - ${itifor.Descricao}`
            }));
            setItinerarios(itinerarioCodigoDescricao);
        } else {
            setItinerarios([]);
        }
    };

    const pesquisaAluno = async () => {
        setLoading(true)

        if (watchCodigoTurma) {
            const alunos = await api.get('/alunosPeriodo/listaAlunosTurma', {
                params: {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    TurmaCodigo: watchCodigoTurma,
                    SituacaoAcademica: 'M',
                    Alunos: 1,
                }
            });

            if (alunos.data) {
                setAlunos(alunos.data)
            }
        }
        setLoading(false)
    };

    const handleDropdownChange = (e: any, rowData: any) => {
        const updatedAlunos = alunos.map((aluno: any) => aluno.AlunoId === rowData.AlunoId ? { ...aluno, ItinerarioFormativoId: e.target.value } : aluno);
        setAlunos(updatedAlunos);
    };

    const limpaCampos = () => {
        setValue('Codigo', '')
    }

    const onSubmit = async (data: any) => {
        setLoading(true)
        data.CargaHoraria = Number(data?.CargaHoraria);

        const dadosFinais: IItinerarioTurma[] = alunos.map((aluno: any) => ({
            ...data,
            Matricula: aluno.Matricula,
            AlunosPeriodoId: aluno.AlunosPeriodoId,
            ItinerarioFormativoId: aluno.ItinerarioFormativoId,
        }));

        try {
            dadosFinais.map(async (item: any) => {
                await api.put('/alunosPeriodo', {
                    AlunosPeriodoId: item.AlunosPeriodoId,
                    ItinerarioFormativoId: item.ItinerarioFormativoId,
                });
            });

            toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
            limpaCampos()
            setAlunos([])

        } catch (error: any) {
            const errorMessage = error?.response?.data?.error || 'Erro desconhecido';
            toast.showToast({ severity: 'error', summary: 'Erro', detail: errorMessage });
        }

        setLoading(false)
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Atribui Itinerário por Turma/Aluno</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>

                    <div className='grid col-12 mt-3'>
                        <div className="col-12 md:col-6">
                            <SCDropdown
                                name="Codigo"
                                options={turmas}
                                optionLabel="TurmaDescricao"
                                optionValue='Codigo'
                                placeholder='Selecione a Turma'
                                label='Turma'
                                style={{ width: '100%' }}
                                value={watchCodigoTurma}
                                onChange={(e) => setValue('Codigo', e.target.value)}
                                required
                            />
                        </div>
                        <div className='col-12 md:col-2 flex align-items-end'>
                            <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" onClick={pesquisaAluno} />
                        </div>
                    </div>

                    <Divider />

                    {loading ? <Loading full={false} /> : (
                        <DataTable
                            size='small'
                            stripedRows
                            ref={dt}
                            value={alunos}
                            dataKey="id"
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            globalFilter={globalFilter}
                            emptyMessage="Nenhum registro encontrado."
                            header={null}
                            showGridlines
                            responsiveLayout="scroll">

                            <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
                            <Column field="Nome" header="Nome" sortable headerStyle={{ width: 365 }} />
                            <Column header='Intinerarios' body={(rowData: any) => (
                                <div className="col-12 flex justify-content-center">
                                    <SCDropdown
                                        options={itinerarios}
                                        optionLabel="ItiForCodigoDescricao"
                                        optionValue='ItinerariosFormativosId'
                                        placeholder='Selecione a Disciplina'
                                        style={{ width: '100%' }}
                                        value={rowData.ItinerarioFormativoId}
                                        onChange={(e) => handleDropdownChange(e, rowData)}
                                    />
                                </div>
                            )} alignHeader='center' align='center'
                            />
                        </DataTable>
                    )}

                    <div className='grid flex justify-content-end'>
                        <div className='col-12 md:col-2'>
                            <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='submit' style={{ width: '100%' }} onClick={handleSubmit(onSubmit)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItinerarioTurmaAluno;