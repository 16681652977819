import { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCButton, SCConfirmDialog, SCDialog, SCDropdown, SCInputText } from '../components';
import { ALERTAS } from '../utilities/constantes';
import SelectAlunoAutoComplete from '../components/SelectAlunoAutoComplete';
import { IAlunoForm } from '../interfaces/IAlunos';
import { IAlunosPeriodo } from '../interfaces/IAlunosPeriodo';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import util from '../utilities/util';
import { Badge } from 'primereact/badge';
import RecebimentoMensalidade from './RecebimentoMensalidade';
import { addDays, format } from 'date-fns';
import { FaCheck, FaCheckCircle, FaExclamationCircle, FaMoneyBillWave } from 'react-icons/fa';
import RecebimentoMensalidadeEstorno from './RecebimentoMensalidadeEstorno';
import IMensalidadesPagamento from '../interfaces/IMensalidadesPagamento';
import IDiarioFinanceiro from '../interfaces/IDiarioFinanceiro';


const FichaFinanceira = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();

  let defaultValues: any = {
    Aluno: undefined,
    AlunoPeriodo: undefined,
    Parcelas: [],
    Mensalidade: undefined
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [parcelas, setParcelas] = useState<any[]>([]);
  const [pagamentos, setPagamentos] = useState([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);	
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const { usuario } = useAuth();

  const dt = useRef<any>(null);

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors },  setValue, setError, clearErrors, watch, getValues, trigger } = methods;

  const watchAluno: IAlunoForm = watch('Aluno');
  const watchAlunoPeriodo:IAlunosPeriodo = watch('AlunoPeriodo');  

  const load = async () => {
    setLoading(true);
    const alunoPeriodo = getValues().AlunoPeriodo;
    try {
      const result = await api.get('/mensalidades', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: alunoPeriodo?.Matricula
        }
      });
      const resulPagamentos = await api.get('/mensalidades/pagamentos', {
        params: { Matricula:  alunoPeriodo?.Matricula  }
      });  
      setPagamentos(resulPagamentos.data);
      setParcelas(result.data);

    } catch (e: any) {
      console.error(e.response?.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (watchAluno?.Matricula) {
      getAlunoPeriodo(watchAluno.Matricula);
    }
  }, [watchAluno]);
  
  const getAlunoPeriodo = async (matricula: any) => {
    setLoading(true);
    const { data } = await api.get('/alunosPeriodo/matricula', {
        params: { 
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,                
            Matricula: matricula 
        }
    });  
    setValue('AlunoPeriodo', data);
    setLoading(false);
  }

  useEffect(() => {
    if (watchAlunoPeriodo?.Matricula) {
      load();
    }
  }, [watchAlunoPeriodo]);
  

  const salvaRecebimento = async (status: string) => {        
    const data = getValues();
    data.Status = status;
    setLoading(true)

    // JSON para enviar ao backend
    const postData: IMensalidadesPagamento = {
      MensalidadeId: data.Mensalidade.MensalidadeId,
      PagamentoData: data.DataPagamento,
      PagamentoHora: format(new Date(), 'HH:mm'),
      PagamentoValor: data.ValorPagamento,
      PagamentoJuros: data.ValorJuros,
      PagamentoMulta: data.ValorMulta,
      PagamentoDesconto: data.ValorDesconto,
      FormasPagamentoId: data.FormasPagamentoId,
      FonteId: data.FonteId,
      PagamentoOperador: usuario?.Apelido,      
      DataBomPara: data.DataBomPara,
      DataDisponivel: data.DataDisponivel,
      Referencia: data.Status == 'Q' ? `RECEBIMENTO (${data.Mensalidade.ParcelaDescricao})` : `ADIANTAMENTO (${data.Mensalidade.ParcelaDescricao})`,
      MensalidadesPagamentoId: null,
      Excluido: false,
      FlgMeuCaixa: data.FonteId === 11 ? true : false, // iD = 11 - Meu Caixa      
    }

    try {
      let resp;
      let id = null;

      if (!postData.MensalidadesPagamentoId) {
        resp = await api.post('/mensalidadesPagamento', {mensalidade: postData});
        if (resp.data) {          
          id = resp.data.MensalidadesPagamentoId;
          setValue('MensalidadesPagamentoId', id);          
        }
      } else {
        resp = await api.put('/mensalidadesPagamento', {mensalidade: postData});
      }      

      if (resp) {
        
        await atualisaMensalidade({...postData, Status: data.Status});
        await salvaDiarioFinanceiro(data, id);
        await salvaEnceramentoCaixa(data);

        if (data.CartaoSelecionado) {
          await salvaContasAPagar(data, id);
        }
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setLoading(false)
      load();
      setShowDialog(false);

    } catch (error: any) {
      load();
      setLoading(false)
      console.log("Erro ao salvar outros recebimentos:", error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: `Falha ao salvar: ${error.message}`        
      });
    }
  }

  const atualisaMensalidade = async (dadosRecebimento: any) => {
    try {
      await api.put('/mensalidade', dadosRecebimento);
    } catch (error) {
      console.error('Erro ao salvar no Encerramento de Caixa:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Encerramento de Caixa',
      });
    }
  }

  const salvaDiarioFinanceiro = async (data: any, id: any) => {
    const isMeuCaixa = data.FonteId === 1 ? 'MEU CAIXA' : 'CAIXA GERAL';

    let dadosDiarioFinanceiro: IDiarioFinanceiro = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      LancamentoData: format(new Date(), 'yyyy-MM-dd'),
      LancamentoHora: format(new Date(), 'HH:mm'),
      LancamentoOperador: usuario?.Apelido,
      Parcela: data.Mensalidade.Parcela,      
      Tarefa: 'RECMEN',
      Opcao: 'INC',
      DataPagamento: data.DataPagamento,
      DataBomPara: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
      DataDisponivel: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
      Nome: data.Aluno.Nome.toUpperCase(),
      ValorMulta: data.ValorMulta,
      ValorJuros: data.ValorJuros,
      ValorDesconto: data.ValorDesconto,
      Valor: showDelete ? data.ValorPagamento * -1 : data.ValorPagamento,
      Referencia: data.Status == 'Q' ? `RECEBIMENTO (${data.Mensalidade.ParcelaDescricao})` : `ADIANTAMENTO (${data.Mensalidade.ParcelaDescricao})`,
      NumeroRecibo: data?.MensalidadesPagamentoId ? data?.MensalidadesPagamentoId : id,
      FonteId: data.FonteId,
      FormasPagamentoId: data.FormasPagamentoId,      
      CartaoNumero: data.NumeroDocumento||'',
      FlgMeuCaixa: isMeuCaixa === 'MEU CAIXA' ? true : false,
      TurmaId: data.AlunoPeriodo?.TurmaId,
      Matricula: data.Aluno.Matricula,
      PlanosPagamentoId: watchAlunoPeriodo.PlanoPagamentoId,
      ContaValorId: data.AlunoPeriodo?.PlanoPagamento?.ContaValorId,
      ContaDescontoId: data.ValorDesconto > 0 ? data.AlunoPeriodo?.PlanoPagamento?.ContaDescontoId : null,
      ContaMultaId: data.ValorMulta > 0 ? data.AlunoPeriodo?.PlanoPagamento?.ContaMultaId : null,
      ContaJurosId: data.ValorJuros > 0 ? data.AlunoPeriodo?.PlanoPagamento?.ContaJurosId : null,
    }

    let resp;

    try {
      resp = await api.post('/diariofinanceiro', dadosDiarioFinanceiro)
    } catch (error) {
      console.error('Erro ao salvar no Diário Financeiro:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Diário Financeiro',
      });
    }
    return true;
  }

  const salvaEnceramentoCaixa = async (data: any) => {

    let dadosEncerramentoCaixa = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      CaixaData: new Date(),
      CaixaHora: format(new Date(), 'HH:mm'),
      CaixaOperador: usuario?.Apelido,
      CaixaValor: data.ValorPagamento
    }

    try {
      await api.post('/gravacaixa', dadosEncerramentoCaixa)
    } catch (error) {
      console.error('Erro ao salvar no Encerramento de Caixa:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Encerramento de Caixa',
      });
    }
  }

  const salvaContasAPagar = async (data: any, id: any) => {

    let valorTarifa;
    let mensalidadesPagamentoId;

    const dataVencimentoCartao = data.DataPagamento && data.Cartao ? addDays(data.DataPagamento, data.Cartao.QtdDiasRecebimento) : null

    if (data.ValorPagamento && data.TarifaValor) {
      if (data.TarifaTipo == 1) {
        valorTarifa = (data.ValorPagamento * Number(data.TarifaValor)) / 100
      } else {
        valorTarifa = data.TarifaValor
      }
    }

    if (data?.MensalidadesPagamentoId === undefined) {
      mensalidadesPagamentoId = id
    } else {
      mensalidadesPagamentoId = data?.MensalidadesPagamentoId
    }

    // Pesquisando ou criando o Credor Cartão
    let credorId;

    try {
      const { data: credores } = await api.get('/credor/pesquisaMaquina', {
        params: { Nome: data?.CartaoSelecionado?.Maquina }
      });

      // Verifica se encontrou o credor, caso contrário, cadastra um novo
      if (credores.length > 0) {
        credorId = credores[0].CredorId;
      } else {
        const novoCredor = { Nome: data?.CartaoSelecionado?.Maquina.toUpperCase() };
        const { data: novoCredorCadastrado } = await api.post('/credor/', novoCredor);

        credorId = novoCredorCadastrado?.id ?? null;

        if (!credorId) {
          console.error('Falha ao cadastrar novo credor.');
        }
      }
    } catch (error) {
      console.error('Erro na consulta ou cadastro de credor:', error);
    }

    const consulta = await api.get('/parametro', {
      params: {
        EmpresaId: 0,
        Ano: '0000',
        Sequencial: '0',
        Codigo: 'ENTCARAU',
        Grupo: 'F'
      }
    })

    const consultaDinheiro = await api.get('/formasPagamento/localizaDinheiro')

    let dadosContasAPagar = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      CredorId: credorId,
      CentroCustoId: data.CentroDeCusto,
      Referente: `TAXA DE CARTÃO - ${data?.CartaoSelecionado?.Maquina}`,
      DataVencimento: dataVencimentoCartao,
      Valor: valorTarifa,
      ContaValorId: data.ContaId,
      DataProgramada: dataVencimentoCartao,
      ValorProgramado: valorTarifa,
      MensalidadesPagamentoId: data.MensalidadesPagamentoId,
      DataPagamento: consulta.data.Valor == '1' ? data.DataPagamento : null,
      ValorPagamento: consulta.data.Valor == '1' ? valorTarifa : null,
      FormasPagamentoId: consultaDinheiro.data.FormasPagamentoId,
      FonteId: consulta.data.Valor == '1' ? data.FonteId : null,
      DataBomPara: consulta.data.Valor == '1' ? data.DataBomPara : null,
    }

    try {
      await api.post('/apagar/tarifaCartao', dadosContasAPagar)

      if (consulta.data.Valor === '1') {

        const isMeuCaixa = data?.Fonte?.Codigo

        let resp;
        let dadosDiarioFinanceiro = {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          LancamentoData: new Date(),
          LancamentoHora: format(new Date(), 'HH:mm'),
          LancamentoOperador: usuario?.Apelido,
          Tarefa: 'RECMEN',
          Opcao: 'INC',
          DataPagamento: data.DataPagamento,
          DataBomPara: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
          DataDisponivel: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
          Nome: watchAluno.Nome?.toUpperCase(),
          Valor: valorTarifa,
          Referencia: `TAXA DE CARTÃO - ${data?.CartaoSelecionado?.Maquina}`,
          NumeroRecibo: data?.OutrosRecebimentosId ? data?.OutrosRecebimentosId : id,
          FonteId: data.FonteId,
          FormasPagamentoId: consultaDinheiro.data.FormasPagamentoId,
          ChequeNumero: data.NumeroDocumento || '',
          FlgMeuCaixa: data.FonteId === 11 ? true : false,
          Matricula: watchAluno.Matricula,
          PlanosPagamentoId: watchAlunoPeriodo.PlanoPagamentoId,
          ContaValorId: data.PlanoContaId,
          CartaoNumero: null
        }

        resp = await api.post('/diariofinanceiro', dadosDiarioFinanceiro)
      }
    } catch (error) {
      console.error('Erro ao salvar o registro no Contas a Pagar:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Contas a Pagar',
      });
    }
  }

  const editar = (item: any) => {
    if (item.DataVencimento)
    {
      item.DataVencimento = new Date(item.DataVencimento);
      item.DataVencimentoFormatado = format(new Date(item.DataVencimento), 'dd/MM/yyyy');
    }
    
    item.DataPagamento = new Date();
    setValue('Mensalidade', item);
    setShowDialog(true);

  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const cancelar = () => {
    setValue('Mensalidade', undefined);
    setValue('DataPagamento', undefined);
    setValue('PagamentoSemValor', false);
    setValue('ValorMulta', 0);
    setValue('ValorJuros', 0);
    setValue('ValorPagamento', 0);
    setValue('FormasPagamentoId', undefined);
    setValue('CartoesId', undefined);
    setValue('TarifaValor', 0);
    setValue('NumeroDocumento', '');
    setValue('FonteId', undefined);
    setValue('DataBomPara', undefined);
    setValue('DataDisponivel', undefined);
    setShowDelete(false);
    setShowDialog(false);
  }

  const onSubmitCad = async () => {
    const data = getValues();
    let hoje = new Date();

    if (data.DataPagamento) {
      let dataFormat = format(new Date(data.DataPagamento), 'yyyy-MM-dd')

      try {
        const result = await api.get('/consultaEncerramento', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Data: dataFormat,
            Operador: usuario?.Apelido,
            Acao: 'F'
          }
        })

        let dataFormatada = format(new Date(data.DataPagamento), 'dd-MM-yyyy')

        if (result.data.length > 0) {
          toast.showToast({
            severity: 'error',
            summary: 'Atenção',
            detail: `O Caixa está encerrado para a data: ${dataFormatada}`,
          });

          return false;
        }
      } catch (error) {
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro na consulta',
        });
      }
    }

    // Verifica se o código de pagamento está no formato 'Código - Descrição' e ajusta apenas se estiver
    if (data.CodigoPagamento && data.CodigoPagamento.includes(' - ')) {
      data.CodigoPagamento = data.CodigoPagamento.split(' - ')[1];
    }

    // Define o operador com base no OutrosRecebimentosId
    const operadorKey = data.OutrosRecebimentosId === undefined ? 'OperadorLancamento' : 'OperadorAtualizacao';
    data[operadorKey] = usuario?.Apelido;

    // Validação do valor
    if (data.Valor == 0) {
      setError('Valor', { type: 'manual', message: 'Informe um valor válido!' });
      return false;
    }

    // Validação de pagamento
    if (data.DataPagamento && !data.PagamentoSemValor && data.ValorPagamento == 0) {
      setError('ValorPagamento', { type: 'manual', message: 'Valor de Pagamento inválido' });
      return false;
    } else if (data.PagamentoSemValor) {
      setValue('ValorPagamento', 0);
    }

    // Verifica se a Data de Pagamento não é maior que a data de hoje
    if (data.DataPagamento > hoje) {
      setError('DataPagamento', { type: 'manual', message: 'Data inválida!' });
      return false;
    }

    // Define FormasPagamentoId se não estiver presente
    if (!data.FormasPagamentoId) {
      data.FormasPagamentoId = data.FormaPagamento?.FormasPagamentoId || null;
    }

    // Define FonteId se não estiver presente
    if (!data.FonteId) {
      data.FonteId = data.Fonte?.FonteId || null;
    }

    // Define DataBomPara se não estiver presente
    if (!data.DataBomPara && data.DataPagamento) {
      data.DataBomPara = data.DataPagamento;
    }

    // Define DataDisponivel se não estiver presente
    if (!data.DataDisponivel && data.DataPagamento) {
      data.DataDisponivel = data.DataPagamento;
    }

    if (data.ValorPagamento < data.ValorAPagar) {
      setShowConfirmDialog(true);
      return;
    }else{

      // Chama a função para salvar o recebimento passando status 'Q' para quitar a parcela
      await salvaRecebimento('Q');
    }
  }

  const handleConfirm = async (status: string) => {
    setShowConfirmDialog(false);
    
    // Chama a função para salvar o recebimento
    await salvaRecebimento(status);
  }

  const handleCancel = () => {
    setShowConfirmDialog(false);
  }

  const deletar = (mensalidade: any, pagamento: any) => {
    if (mensalidade.DataVencimento)
    {
      mensalidade.DataVencimento = new Date(mensalidade.DataVencimento);
      mensalidade.DataVencimentoFormatado = format(new Date(mensalidade.DataVencimento), 'dd/MM/yyyy');
    }
    setShowDelete(true);
    setValue('Mensalidade', mensalidade);
    setValue('Pagamento', pagamento);    
    setShowDialog(true);

  };

  const linhaDetalheTemplate = (data: any) => {    
    return (
      <div style={{paddingLeft: 10}}>
      <DataTable stripedRows={false} rowHover={false}
        value={pagamentos.filter((x: any) => x.MensalidadeId == data.MensalidadeId)} 
        lazy 
        emptyMessage="Nenhum registro encontrado.">
        <Column field="PagamentoData" header="Data Pgto" headerStyle={{ width: 80 }} body={(rowData: any) => {
            return (rowData.PagamentoData ? new Date(rowData.PagamentoData).toLocaleDateString() : '');
        }}></Column>
        <Column field="PagamentoHora" header="Hora Pgto" ></Column>
        <Column field="PagamentoValor" header="Valor Pago" body={(rowData: any) => {
            return (util.formatCurrency(rowData.PagamentoValor));
        }}></Column>
        <Column field="PagamentoJuros" header="Juros" body={(rowData: any) => {
            return (util.formatCurrency(rowData.PagamentoJuros));
        }}></Column>
        <Column field="PagamentoMulta" header="Multa" body={(rowData: any) => {
            return (util.formatCurrency(rowData.PagamentoMulta));
        }}></Column>
        <Column field="FormasPagamentoDescricao" header="Forma de Pagamento" ></Column>
        <Column field="FonteDescricao" header="Fonte" ></Column>
        <Column field="MensalidadesPagamentoId" body={(rowData: any) => {
            return (<SCButton type='button' className='p-button-outlined p-button-danger' onClick={() => { deletar(data, rowData);}} >
                <i className="pi pi-trash" ></i>
              </SCButton>);
        }}></Column>
      </DataTable>
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
    <form onSubmit={handleSubmit(onSubmitCad)} className='pt-3' >
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
              <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Ficha Financeira - [{`${periodoSelecionado?.Ano} / ${periodoSelecionado?.Sequencial}`}]</h5>
            </div>

            <div className='grid mt-2'>
              <div className='col-12'>
                <div className='grid'>
                  <div className='col-12 md:col-6'>
                    <SelectAlunoAutoComplete 
                      name='AlunoId'
                      control={control}
                      errors={errors}
                      label='Aluno'
                      loading={loading}
                      minLength={3}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>
                  
                  <div className='col-12 md:col-2'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Aluno.TurmaCodigo"
                      label="Código da Turma"
                      disabled
                      className='w-full'
                    />
                  </div>
                  <div className='col-12 md:col-4'>
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Aluno.TurmaDescricao"
                      label="Nome da Turma"
                      disabled
                      className='w-full'
                    />
                  </div>
                </div>                
              </div>              
            </div>

            <Divider />

            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={parcelas}
              dataKey="MensalidadeId"
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll"
              rowExpansionTemplate={linhaDetalheTemplate}
              expandedRows={expandedRows}    
              resizableColumns 
              // rowClassName={rowClass}
              onRowToggle={(e) => setExpandedRows(e.data)}         
              >
              <Column expander className='p-1'/>
              <Column field="Parcela" header="" headerStyle={{ width: 20 }} body={(rowData: any) => {
                if (rowData.Parcela == watchAlunoPeriodo.ParcelaMatricula)
                  return (<Badge size="normal" value="M" style={{background: 'green'}} />);
              }}></Column>              
              <Column field="Parcela" header="Parcela" sortable bodyStyle={{textAlign: 'center'}} headerStyle={{ width: 40 }}></Column>
              <Column field="ParcelaDescricao" header="Descrição" sortable ></Column>
              <Column field="ValorMensalidade" header="Valor Total" headerStyle={{ width: 120 }} body={(rowData: any) => {
                  return rowData.ValorMensalidade.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
              }}></Column>
              
              <Column field="DataVencimento" header="Vencimento" sortable headerStyle={{ width: 70 }} body={(rowData: any) => {
                  return rowData.DataVencimento ? new Date(rowData.DataVencimento).toLocaleDateString() : '';
              }}></Column>

              <Column field="ValorPago" header="Valor Pago" headerStyle={{ width: 120 }} body={(rowData: any) => {
                  return util.formatCurrency(rowData.ValorPago);
              }}></Column>

              <Column field="DataUltimoPagamento" header="Data Último Pagto" sortable headerStyle={{ width: 160 }} body={(rowData: any) => {
                  return rowData.DataUltimoPagamento ? `${new Date(rowData.DataUltimoPagamento).toLocaleDateString()} ${rowData.HoraUltimoPagamento.substr(0,5)}` : '';
              }}></Column>              

              <Column field="ValorPago" header="Situação" bodyStyle={{textAlign: 'center'}} headerStyle={{ width: 60 }} body={(rowData: any) => {                  
                  return rowData.Status == 'Q' ? <FaCheckCircle size={20} color='green'/> : (rowData.Status == 'A' ? <FaExclamationCircle size={20} color='blue' /> : '');
              }}></Column>
              
              <Column field="ValorPago" header="Saldo a Pagar" headerStyle={{ width: 120 }} body={(rowData: any) => {
                  return rowData.Status == 'Q' ? '' : util.formatCurrency(rowData.ValorMensalidade - rowData.ValorPago);
              }}></Column>

              <Column body={(rowData: any) => {
                return (
                  <div className="flex align-items-center justify-content-between">
                    {rowData.Status != 'Q' && (
                      <Button type='button' icon="pi pi-dollar" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} 
                      disabled={rowData.SituacaoAcademica === 'R'} />
                    )}
                    {/* <Button icon="pi pi-arrow-up-right" className="p-button-rounded p-button-info ml-2" onClick={() => editar(rowData)} disabled={rowData.SituacaoAcademica === 'R'} /> */}
                  </div>
                );
              }} style={{ width: 50 }}
              />
            </DataTable>

          </div>
        </div>
      </div>
      <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
          header={`Recebimento de Mensalidade`}
          modal className="p-fluid" onHide={hideDialog}
          footer={!showDelete &&  (
            <div className="flex flex-1 justify-content-between">
                  <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                  <SCButton onClick={onSubmitCad} label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" />
            </div>
          )}>
            {showDelete ?(
              <RecebimentoMensalidadeEstorno
                aluno={watchAluno}
                alunoPeriodo={watchAlunoPeriodo}
                mensalidade={getValues('Mensalidade')}
                pagamento={getValues('Pagamento')}
                reload={() => load()}
                loading={loading}
                deletar={() => {}}
                setLoading={setLoading}
                showDelete={showDelete}
                cancelar={cancelar}
              />
            ) : (
              <RecebimentoMensalidade
                setShowDialog={setShowDialog}
                aluno={watchAluno}
                alunoPeriodo={watchAlunoPeriodo}
                mensaliade={getValues().Mensalidade}
                pagamentos={pagamentos.filter((x: any) => x.MensalidadeId == getValues()?.Mensalidade?.MensalidadeId)}
                reload={() => load()}
                showDelete={showDelete}
                setShowDelete={setShowDelete}
                loading={loading}
                deletar={() => {}}
                setLoading={setLoading}
              />
            )}
      </SCDialog>
    </form>

    <SCConfirmDialog      
          visible={showConfirmDialog}
          onHide={handleCancel}
          message={() => {
            return (
              <div className="p-grid p-dir-col">
                <div className="p-col">
                  <h4>O valor informado é menor que o valor a pagar. Quitar a parcela?</h4>
                  <span >Clique <strong className='text-green-600'>SIM</strong> para quitar a parcela</span> <br />
                  <span >Clique <strong style={{color: 'red'}}>NÃO</strong> para adiantar a parcela</span>
                </div>
              </div>
            )
          }}
          header="Atenção"
          accept={() => handleConfirm('Q')}
          reject={() => handleConfirm('A')}
          acceptLabel='Sim'
          rejectLabel='Não'
          acceptClassName='p-button-success'
          rejectClassName='p-button-danger'
          
        />
    </FormProvider>


  );
};

export default FichaFinanceira;
