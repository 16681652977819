import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCCheckbox, SCDropdown, SCFieldset, SCInputNumber, SCInputText, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, TURNOS, TIPO_FREQUENCIA_CONTROLE, CALCULA_FALTA_BASEADO, MODELO_CONTROLE_FALTAS, TIPO_LANCAMENTO_FALTAS } from '../utilities/constantes';
import util from '../utilities/util';
import ITurmaRegular from '../interfaces/ITurmas';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputNumberChangeParams } from 'primereact/inputnumber';
import axios from 'axios';
import config from '../utilities/config';
import ITurmas from '../interfaces/ITurmas';

interface Props {
  registro?: ITurmaRegular;
  novoComBase?: any;
  setHeader: any
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const TurmaRegularForm: React.FC<Props> = ({ setShowDialog, setHeader, registro, novoComBase, reload, deletar, showDelete, setShowDelete }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'TurmaRegularForm';
  const toast = useToast();

  const { periodoSelecionado } = useAuth()

  const [loading, setLoading] = useState<boolean>(false);
  const [listaGrauSerie, setListaGrauSerie] = useState<any[]>([]);
  const [listaCurso, setListaCurso] = useState<any[]>([]);
  const [listaTurmaEF, setListaTurmaEF] = useState<any[]>([]);
  const [listaBancos, setListaBancos] = useState<any[]>([]);
  const [listaProfessores, setListaProfessores] = useState<any[]>([]);
  const [desabilitaCampo, setDesabilitaCampo] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');

  const defaultValues: ITurmas = {
    TurmaId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Codigo: '',
    Descricao: '',
    CodigoTurmaEF: null,
    NaoAplicaEF: false,
    Turno: '',
    NivelSequenciaId: null,
    VagasPrevistas: 0,
    ProfessorPrincipal: null,
    SituacaoFinal: '',
    DiasRealizados: 0,
    NumeroDependencias: 0,
    DiasLetivos: 0,
    UsuarioFechamentoId: 0,
    DataFechamento: new Date(''),
    HoraFechamento: '',
    CursoId: 0,
    QuatidadeDisciplinaRecuperacao: 0,
    DataProcessamentoBoletim: new Date(''),
    HoraProcessamentoBoletim: '',
    UsuarioProcessamentoBoletimId: 0,
    AvaliacaoProcessamentoBoletim: '',
    QuatidadeDisciplinaRecuperacaoNormal: 0,
    QuatidadeDisciplinaRecuperacaoDependecia: 0,
    QuatidadeDisciplinaRecuperacaoAdaptacao: 0,
    QuatidadeDisciplinaRecuperacaoMaxima: 0,
    QuatidadeDisciplinaDependeciaNormal: 0,
    QuatidadeDisciplinaDependeciaDependencia: 0,
    QuatidadeDisciplinaDependenciaAdaptacao: 0,
    QuatidadeDisciplinaDependenciaMaxima: 0,
    QuatidadeDisciplinaReprovadoDireto: 0,
    EstatisticaExibir: true,
    EstatisticaComputaVagas: true,
    NFSeCodigoAtividade: '',
    NFSeTipo: '',
    NFSeAliquota: 0,
    ModeloControleFaltas: 0,
    TipoLancamentoFaltas: 0,
    BloqueiaTurmaNovasMatriculas: false,
    FaltaProcessar: false,
    TurmaEncerrada: false,
    MatriculaParcelada: false,
    ModeloAvaliacaoQualitativaId: 0,
    BancoId: 0,
    EscolaNFSe: 0,
    ItinerarioId: 0,
    ModeloBoletim: '',
    ModeloDiario: '',
    ModeloHistorico: '',
    ModeloAgendaMob: '',
    Ativo: false,
    ExigeItinerario: 0,
    FinalCurso: 0,
    Nivel: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, reset, setError, clearErrors, trigger, setValue, watch } = methods;

  const watchNivel = watch('Nivel');
  const watchNaoAplicaEF = watch('NaoAplicaEF');

  useEffect(() => {

    async function load() {
      setLoading(true);

      console.log('REGISTRO', registro)

      if (registro?.TurmaId) {
        setHeader('Alterando Turma: ' + registro?.Codigo + ' - ' + registro?.Descricao)
      } else {
        setHeader('Cadastrando Turma')
      }

      if (registro?.TurmaId !== undefined) {
        const turmaSel = await api.get('/turma', { params: { TurmaId: registro?.TurmaId } });
        reset(turmaSel.data);
      } else if (novoComBase) {
        const turmaSel = await api.get('turma', { params: { TurmaId: novoComBase } });
        reset(turmaSel.data);
      }

      const professor = await api.get('professores/list');
      setListaProfessores(professor.data);

      let epDados = [
        config.baseURL + 'bancos/list',
        config.baseURL + 'curso',
        config.baseURL + 'turmasEF',
      ];

      Promise.all(epDados.map(r => api.get(r,
        {
          params: {
            EducacaoFisica: true,
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial
          }
        }))).then(([bancos, cursos, turmasEF]) => {
          setListaBancos(bancos.data);
          setListaCurso(cursos.data);
          setListaTurmaEF(turmasEF.data);
          setLoading(false);
        }
        ).catch((error) => {
          console.log('Erro ao carregar tabelas', error);
          setLoading(false);
        });
    }


    if (registro) {
      load();
    }
  }, [registro]);

  useEffect(() => {
    const buscaTurmaEF = async () => {
      try {
        const resp = await api.get('turmasEF', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
          }
        });

        setListaTurmaEF(resp.data)
      } catch (error) {
        // Trate o erro aqui, se necessário
        console.error('Erro ao buscar dados:', error);
      }
    };

    const buscaCurso = async () => {
      try {
        const resp = await api.get('/curso');

        setListaCurso(resp.data)
      } catch (error) {
        // Trate o erro aqui, se necessário
        console.error('Erro ao buscar dados:', error);
      }
    };

    const buscaNivelSequencia = async () => {
      try {
        const resp = await api.get('/nivelSequencia/list', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
          }
        });

        setListaGrauSerie(resp.data);
      } catch (error) {
        // Trate o erro aqui, se necessário
        console.error('Erro ao buscar dados:', error);
      }
    };

    buscaCurso();
    buscaTurmaEF();
    buscaNivelSequencia();
  }, []);


  const handleCodigoChange = (e: any) => {
    const value = e.target.value.toUpperCase().slice(0, 10);
    setCodigo(value);

    if (registro?.TurmaId) {
      setHeader('Alterando Turma: ' + value + ' - ' + registro?.Descricao);
    } else {
      setHeader('Cadastrando Turma: ' + e.target.value.toUpperCase().slice(0, 10) + ' - ' + descricao);
    }
  };

  const handleDescricaoChange = (e: any) => {
    const value = e.target.value.toUpperCase().slice(0, 60);
    setDescricao(value);

    if (registro?.TurmaId) {
      setHeader('Alterando Turma: ' + registro?.Codigo + ' - ' + value);
    } else {
      setHeader('Cadastrando Turma: ' + codigo + ' - ' + e.target.value.toUpperCase().slice(0, 60));
    }
  };

  const onSubmit = async (data: ITurmaRegular) => {
    let resp: any = undefined;
    try {
      if (novoComBase) {
        data.TurmaId = undefined;
      }

      // let MaximaDep = 0;
      // if (data.QuatidadeDisciplinaDependenciaMaxima) {
      //   MaximaDep = data.QuatidadeDisciplinaDependenciaMaxima;
      // }
      // let QtDiscDepAd = 0;
      // if (data.QuatidadeDisciplinaDependeciaNormal) {
      //   QtDiscDepAd = parseInt(data.QuatidadeDisciplinaDependeciaNormal.toString())
      // }
      // let QtDiscDepN = 0;
      // if (data.QuatidadeDisciplinaDependeciaDependencia) {
      //   QtDiscDepN = parseInt(data.QuatidadeDisciplinaDependeciaDependencia.toString())
      // }
      // let QtDiscDepDep = 0;
      // if (data.QuatidadeDisciplinaDependenciaAdaptacao) {
      //   QtDiscDepDep = parseInt(data.QuatidadeDisciplinaDependenciaAdaptacao.toString())
      // }
      // const totalDep = QtDiscDepAd + QtDiscDepN + QtDiscDepDep
      // if (totalDep > MaximaDep) {
      //   toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Quantidade máxima para Dependência está inválida!' });
      //   return;
      // }

      // let Maxima = 0;
      // if (data.QuatidadeDisciplinaRecuperacaoMaxima) {
      //   Maxima = data.QuatidadeDisciplinaRecuperacaoMaxima;
      // }
      // let QtDiscRecAd = 0;
      // if (data.QuatidadeDisciplinaRecuperacaoAdaptacao) {
      //   QtDiscRecAd = parseInt(data.QuatidadeDisciplinaRecuperacaoAdaptacao.toString())
      // }
      // let QtDiscRecN = 0;
      // if (data.QuatidadeDisciplinaRecuperacaoNormal) {
      //   QtDiscRecN = parseInt(data.QuatidadeDisciplinaRecuperacaoNormal.toString())
      // }
      // let QtDiscRecDep = 0;
      // if (data.QuatidadeDisciplinaRecuperacaoDependecia) {
      //   QtDiscRecDep = parseInt(data.QuatidadeDisciplinaRecuperacaoDependecia.toString())
      // }
      // const total = QtDiscRecAd + QtDiscRecN + QtDiscRecDep
      // if (total > Maxima) {
      //   toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Quantidade máxima para Recuperação Final está inválida!' });
      //   return;
      // }

      setShowDialog(true);
      let { Nivel, ...newData } = data;

      if (data.TurmaId === undefined) {
        resp = await api.post('/turma', newData);
        const turmaUd = Number(resp.data);

        if (novoComBase) {
          const listOfertas = await api.get('/ofertaDisciplina/listProfessorDisciplina', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              TurmaCodigo: registro?.Codigo,
            }
          });

          if (listOfertas.data.length > 0) {
            const ofertasParaInserir = listOfertas.data.map((oferta: any) => {
              return {
                ...oferta,
                OfertaDisciplinaId: undefined,
                TurmaCodigo: data.Codigo,
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
              };
            });

            await api.post('/ofertaDisciplina', { Lista: ofertasParaInserir });
          }
        }

      } else {
        console.log('data', data)
        resp = await api.put('/turma', newData);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset();
    if (novoComBase) {
      reload();
    }
  }

  useEffect(() => {
    if (watchNaoAplicaEF) {
      clearErrors('CodigoTurmaEF');
      setValue('CodigoTurmaEF', undefined);
    }
  }, [watchNaoAplicaEF])

  const watchCursoId = watch('CursoId');

  useEffect(() => {
    const filtraNivelEsc = () => {
      if (watchCursoId && listaCurso) {
        const curso = listaCurso.find((item: any) => item.CursoId == watchCursoId);
        if (curso) {
          setValue('Nivel', curso.TipoCurso);
        }
      }
    };

    filtraNivelEsc();

  }, [watchCursoId, listaCurso]);

  useEffect(() => {
    setValue('TipoLancamentoFaltas', 3)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >

      {loading ? <Loading /> : (
        <>
          <TabView>
            <TabPanel header="Principal">
              <div className='grid'>
                <div className="col-2 md:col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    upperCase
                    name="Codigo"
                    label='Código'
                    maxLength={10}
                    onChange={(e: any) => {
                      if (e.target.value.length > 10) {
                        setError('Codigo', {
                          type: 'manual',
                          message: 'Máximo 10 caracteres'
                        });
                      } else {
                        clearErrors('Codigo');
                      }

                      return e.target.value;
                    }}
                    onInput={(e) => {
                      handleCodigoChange(e);
                    }}
                    required={!showDelete}
                    disabled={showDelete || registro?.TurmaId !== undefined}
                    tarefa={tarefa}
                    tooltip='Máximo de 10 caracteres'
                    tooltipOptions={{ event: 'focus', position: 'top' }}
                  />
                </div>
                <div className="col-7 md:col-6">
                  <SCInputText
                    control={control}
                    errors={errors}
                    upperCase
                    name="Descricao"
                    label='Descrição'
                    maxLength={60}
                    required={!showDelete}
                    disabled={showDelete}
                    onInput={(e) => {
                      handleDescricaoChange(e);
                    }}
                    tarefa={tarefa}
                    tooltip='Máximo de 60 caracteres'
                    tooltipOptions={{ event: 'focus', position: 'top' }}
                  />
                </div>
                <div className="col-3 md:col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="CodigoInep"
                    label='Código INEP'
                    maxLength={10}
                    disabled={showDelete || desabilitaCampo}
                    tooltip='Máximo de 10 caracteres'
                    tooltipOptions={{ event: 'focus', position: 'top' }}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-2">
                  <SCCheckbox
                    control={control}
                    name="TurmaEncerrada"
                    label='Turma encerrada'
                    disabled={showDelete}
                  />
                </div>
                <div className="col-3">
                  <SCCheckbox
                    control={control}
                    name="ExigeItinerario"
                    label='Exige Itinerário Formativo'
                    disabled={showDelete}
                  />
                </div>
                <div className="col-3">
                  <SCCheckbox
                    control={control}
                    name="FinalCurso"
                    label='Turma Final de Curso'
                    disabled={showDelete}
                  />
                </div>
              </div>
              <Divider />
              <div className='grid'>
                <div className="col-6">
                  <SCDropdown
                    control={control}
                    name="CursoId"
                    label='Curso'
                    errors={errors}
                    disabled={showDelete || desabilitaCampo}
                    tarefa={tarefa}
                    options={listaCurso}
                    optionLabel='Descricao'
                    optionValue='CursoId'
                    filter={false}
                    required={!showDelete}
                    onChange={(e) => {
                      setValue('NivelSequenciaId', null);
                    }}
                  />
                </div>
                <div className="col-4">
                  <SCDropdown
                    errors={errors}
                    control={control}
                    name="NivelSequenciaId"
                    label='Nível/Escolaridade'
                    disabled={showDelete || desabilitaCampo}
                    options={listaGrauSerie.filter((item: any) => item.Nivel == watchNivel)}
                    filter={false}
                    optionLabel='Descricao'
                    optionValue='NivelSequenciaId'
                    required={!showDelete}
                  />
                </div>
                <div className="col-2">
                  <SCDropdown
                    errors={errors}
                    control={control}
                    name="Turno"
                    label='Turno'
                    disabled={showDelete}
                    options={TURNOS}
                    optionLabel='descricao'
                    optionValue='value'
                    required={!showDelete}
                    filter={false}
                  />
                </div>
                <div className="col-6">
                  <SCDropdown
                    control={control}
                    name="ProfessorPrincipal"
                    label='Professor Principal'
                    disabled={showDelete || desabilitaCampo}
                    tarefa={tarefa}
                    options={listaProfessores}
                    optionLabel='NomeComposto'
                    optionValue='FuncionarioId'
                    filter={false}
                  />
                </div>
                <div className="col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="VagasPrevistas"
                    label='Vagas Previstas'
                    disabled={showDelete}
                    max={999}
                    maxLength={3}
                  />
                </div>

                <div className="col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="DiasLetivos"
                    label='Qtd de dias letivos'
                    disabled={showDelete || desabilitaCampo}
                    max={365}
                    maxLength={3}
                  />
                </div>
              </div>

              <Divider align="left" type="solid">
                <b>Qtd. Máxima de Disciplina</b>
              </Divider>
              <div className='grid'>
                <div className="col-4">
                  <Divider align="center" type="dashed">
                    <b>Disciplinas para Reprovar Direto</b>
                  </Divider>
                  <div className='flex flex-row align-items-center justify-content-center'>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        errors={errors}
                        control={control}
                        name="QuatidadeDisciplinaReprovadoDireto"
                        label='Máxima'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <Divider align="center" type="dashed">
                    <b>Recuperação Final</b>
                  </Divider>
                  <div className='flex flex-row align-items-center justify-content-center'>
                    <div className="col-3 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaRecuperacaoMaxima"
                        label='Máxima'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaRecuperacaoNormal"
                        label='Regular'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaRecuperacaoDependecia"
                        label='Dep.'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaRecuperacaoAdaptacao"
                        label='Adap.'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <Divider align="center" type="dashed">
                    <b>Aprova Com Dependência</b>
                  </Divider>
                  <div className='flex flex-row align-items-center justify-content-center'>
                    <div className="col-3 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaDependenciaMaxima"
                        label='Máxima'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaDependeciaNormal"
                        label='Regular'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaDependeciaDependencia"
                        label='Dep.'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                    <div className="col-2 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="QuatidadeDisciplinaDependenciaAdaptacao"
                        label='Adap.'
                        disabled={showDelete || desabilitaCampo}
                        max={99}
                        maxLength={2}
                        numberOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Configurações" style={{}}>
              <Divider align="left" type="dashed">
                <b>Lançamento de Faltas</b>
              </Divider>
              <div className='grid pt-3 pb-2'>
                <div className="col-12 md:col-8">
                  <div className="flex align-items-center justify-content-between">
                    <SCRadioButton control={control} name='TipoLancamentoFaltas' label='Avaliação' value={1} />
                    <SCRadioButton control={control} name='TipoLancamentoFaltas' label='Mês' value={2} />
                    <SCRadioButton control={control} name='TipoLancamentoFaltas' label='Dia' value={3} />
                    <SCRadioButton control={control} name='TipoLancamentoFaltas' label='Eletrônica (Catraca/Totem) - DIA' value={4} />
                    <SCRadioButton control={control} name='TipoLancamentoFaltas' label='Eletrônica (Catraca/Totem) - HORÁRIO' value={5} />
                  </div>
                </div>
              </div>

              {/* <Divider align="left" type="dashed">
                <b>Tipo de Lançamento</b>
              </Divider>
              <div className="col-12 md:col-6">
                <div className="flex align-items-center justify-content-between">
                  <SCRadioButton control={control} name='LancamentoFaltasTipo' label='Detalhado por Disciplina' value={1} />
                  <SCRadioButton control={control} name='LancamentoFaltasTipo' label='Global (Somente 1 Professor Lança)' value={2} />
                </div>
              </div> */}

              <Divider align="left" type="dashed">
                <b>Estatística de turma</b>
              </Divider>
              <div className='grid'>
                <div className="col-4">
                  <div className='pb-2'>Exibir?</div>
                  <div className="flex align-items-center">
                    <SCRadioButton
                      control={control}
                      name="EstatisticaExibir"
                      label='Sim'
                      value={true}
                      disabled={showDelete}
                    />
                    <SCRadioButton
                      control={control}
                      name="EstatisticaExibir"
                      label='Não'
                      value={false}
                      className='ml-3'
                      disabled={showDelete}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className='pb-2'>Computar Vaga?</div>
                  <div className="flex align-items-center">
                    <SCRadioButton
                      control={control}
                      name="EstatisticaComputaVagas"
                      label='Sim'
                      value={true}
                      disabled={showDelete}
                    />
                    <SCRadioButton
                      control={control}
                      name="EstatisticaComputaVagas"
                      label='Não'
                      value={false}
                      className='ml-3'
                      disabled={showDelete}
                    />
                  </div>
                </div>
              </div>
              <Divider align="left" type="dashed">
                <b>Padrões</b>
              </Divider>
              <div className='grid'>
                <div className="col-3">
                  <SCDropdown
                    control={control}
                    name="BancoId"
                    label='Banco para os Boletos'
                    disabled={showDelete || desabilitaCampo}
                    options={listaBancos}
                    optionLabel='NomeBanco'
                    optionValue='Sequencial'
                    filter={false}
                  />
                </div>
                <div className='col-12 md:col-3'>
                  <SCDropdown
                    label='Modelo Agenda APP'
                  />
                </div>
                <div className='col-12 md:col-3'>
                  <SCDropdown
                    label='Modelo de Boletim'
                  />
                </div>
                <div className='col-12 md:col-3'>
                  <SCDropdown
                    label='Modelo de Histórico'
                  />
                </div>
                <div className='col-12 md:col-3'>
                  <SCDropdown
                    label='Modelo de Diário de Classe'
                  />
                </div>
              </div>
              <Divider align='left' type='dashed'>
                <b>Cabeçalhos para Instrumentos de Avaliações</b>
              </Divider>
              <div className='grid mt-2 mb-2'>
                <div className='col-3 md:col-2'>
                  <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA1</label>
                  <SCInputText
                    control={control}
                    name='Cabecalho1'
                    style={{ width: '100%' }}
                    className='pr-0'
                  />
                </div>
                <div className='col-3 md:col-2'>
                  <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA2</label>
                  <SCInputText
                    control={control}
                    name='Cabecalho2'
                    style={{ width: '100%' }}
                    className='pr-0'
                  />
                </div>
                <div className='col-3 md:col-2'>
                  <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA3</label>
                  <SCInputText
                    control={control}
                    name='Cabecalho3'
                    style={{ width: '100%' }}
                    className='pr-0'
                  />
                </div>
                <div className='col-3 md:col-2'>
                  <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA4</label>
                  <SCInputText
                    control={control}
                    name='Cabecalho4'
                    style={{ width: '100%' }}
                    className='pr-0'
                  />
                </div>
                <div className='col-3 md:col-2'>
                  <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA5</label>
                  <SCInputText
                    control={control}
                    name='Cabecalho5'
                    style={{ width: '100%' }}
                    className='pr-0'
                  />
                </div>
                <div className='col-3 md:col-2'>
                  <label style={{ display: 'block', textAlign: 'center', width: '100%' }}>Cabeçalho IA6</label>
                  <SCInputText
                    control={control}
                    name='Cabecalho6'
                    style={{ width: '100%' }}
                    className='pr-0'
                  />
                </div>
              </div>
              <Divider align="left" type="dashed">
                <b>Quantitativos</b>
              </Divider>
              <div className='grid bg-blue-100' style={{ padding: 10, borderRadius: 6, margin: 10 }}>
                <div className="col-3"></div>
                <div className="col-6">
                  <div className='grid'>
                    <div className="col">
                      <SCInputText
                        labelClassName='justify-content-center'
                        control={control}
                        errors={errors}
                        // name="VagasOcupadas"
                        label='Vagas Ocupadas'
                        disabled
                        style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}

                      />
                    </div>
                    <div className="col">
                      <SCInputText
                        labelClassName='justify-content-center'
                        control={control}
                        errors={errors}
                        // name="UltimoNumeroOrdem"
                        label='Último Nº Ordem'
                        disabled
                        style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}
                      />
                    </div>
                    <div className="col">
                      <SCInputText
                        labelClassName='justify-content-center'
                        control={control}
                        errors={errors}
                        // name="AlunosFichados"
                        label='Alunos Registrados'
                        disabled
                        style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3"></div>
              </div>
            </TabPanel>
          </TabView>

          <div className='grid'>

            <Divider />

            {showDelete ? (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
                <Button label="Confirmar Exclusão" icon="pi pi-check"
                  className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                  onClick={deletar} type='button' />
              </div>
            ) : (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
              </div>
            )}

          </div>
        </>
      )}
    </form >

  );
};

export default TurmaRegularForm;
