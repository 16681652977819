const getAreaConhecimento = (area: any): string => {
  switch (area) {
    case 'I':
      return "Não Informado";
    case 'G':
      return "Conhecimentos Gerais";
    case 'N':
      return "Ciências da Natureza e suas Tecnologias";
    case 'H':
      return "Ciências Humanas e suas Tecnologias";
    case 'L':
      return "Linguagens e Códigos";
    case 'M':
      return "Matemática";
    default:
      return "-";
  }
}

const SISTEMA_ID = 1;

const DIAS_SEMANA = [
  'Domingo',
  'Segunda-Feira',
  'Terça-Feira',
  'Quarta-Feira',
  'Quinta-Feira',
  'Sexta-Feira',
  'Sábado',
];

const MESES = [
  { mes: 1, mes_: '01', des: 'Janeiro' },
  { mes: 2, mes_: '02', des: 'Fevereiro' },
  { mes: 3, mes_: '03', des: 'Março' },
  { mes: 4, mes_: '04', des: 'Abril' },
  { mes: 5, mes_: '05', des: 'Maio' },
  { mes: 6, mes_: '06', des: 'Junho' },
  { mes: 7, mes_: '07', des: 'Julho' },
  { mes: 8, mes_: '08', des: 'Agosto' },
  { mes: 9, mes_: '09', des: 'Setembro' },
  { mes: 10, mes_: '10', des: 'Outubro' },
  { mes: 11, mes_: '11', des: 'Novembro' },
  { mes: 12, mes_: '12', des: 'Dezembro' },
];

const AREAS_CONHECIMENTO = [
  { value: 'I', descricao: 'Não Informado', detalhes: null },
  { value: 'G', descricao: 'Conhecimentos Gerais', detalhes: null },
  { value: 'N', descricao: 'Ciências da Natureza e suas Tecnologias', detalhes: 'Inclui química, física, biologia, energia e preservação ambiental.' },
  { value: 'H', descricao: 'Ciências Humanas e suas Tecnologias', detalhes: 'Inclui história, sociologia, geografia e filosofia.' },
  { value: 'L', descricao: 'Linguagens e Códigos', detalhes: 'Inclui português, literatura, artes, educação física e línguas (inglês e espanhol).' },
  { value: 'M', descricao: 'Matemática', detalhes: 'Inclui comparações numéricas, sistemas de medidas, álgebra, interpretação de gráficos e tabelas, entre outros.' },
]

const ALERTAS = {
  registroExcluido: 'Registro excluído com sucesso!',
  erroExcluir: 'Erro ao tentar excluir o registro',
  registroGravado: 'Registro gravado com sucesso!',
  erroGravar: 'Erro ao tentar gravar o registro!'
}

const TIPO_CURSO = [
  { value: 'I', descricao: 'INFANTIL' },
  { value: 'F', descricao: 'FUNDAMENTAL' },
  { value: 'E', descricao: 'FUNDAMENTAL - EJA' },
  { value: 'M', descricao: 'MÉDIO' },
  { value: 'J', descricao: 'MÉDIO - EJA' },
  { value: 'B', descricao: 'SUPERIOR - BACHAREL' },
  { value: 'L', descricao: 'SUPERIOR - LICENCIATURA' },
  { value: 'G', descricao: 'GRADUADO' },
  { value: 'P', descricao: 'PÓS-GRADUADO' },
  { value: 'M', descricao: 'MESTRADO' },
  { value: 'D', descricao: 'DOUTORADO' },
  { value: 'V', descricao: 'LIVRE' },
]

const GRAU_NIVEL_ESCOLARIDADE = [
  { value: 'I', descricao: 'ENSINO INFANTIL' },
  { value: 'F', descricao: 'ENSINO FUNDAMENTAL' },
  { value: 'M', descricao: 'ENSINO MÉDIO' },
  { value: 'S', descricao: 'SUPERIOR' },
]

const FORMA_INGRESSO = [
  { value: 'AN', descricao: 'ALUNO NOVO' },
  { value: 'AA', descricao: 'ALUNO ANTIGO' },
]

const TURNOS = [
  { value: 'M', descricao: 'MANHÃ' },
  { value: 'T', descricao: 'TARDE' },
  { value: 'N', descricao: 'NOITE' },
  { value: 'S', descricao: 'SEMI-INTEGRAL' },
  { value: 'I', descricao: 'INTEGRAL' },
]


//// Turma regular
// Frequência
const TIPO_FREQUENCIA_CONTROLE = [
  { value: 1, descricao: 'Diário-Global (1 Professor/Turma)' },
  { value: 2, descricao: 'Diário de Classe' },
  { value: 3, descricao: 'Horário (Carteirinha)' },
  { value: 4, descricao: 'Relógio de Ponto' },
]

const CONTROLE_FREQUENCIA = [
  { value: 1, descricao: 'Global' },
  { value: 2, descricao: 'Disciplina' },
  { value: 3, descricao: 'Não Controla' },
]

const CALCULA_FALTA_BASEADO = [
  { value: 1, descricao: 'CHR Global' },
  { value: 2, descricao: 'CHR por Disciplina' },
  { value: 3, descricao: 'Não Controla' },
]

// Modelo de lançamento de Faltas
const MODELO_CONTROLE_FALTAS = [
  { value: 1, descricao: 'Lançamento por (Avaliação)' },
  { value: 2, descricao: 'Lançamento por Mês' },
  { value: 2, descricao: 'Lançamento por Dia' },
]

const TIPO_LANCAMENTO_FALTAS = [
  { value: 1, descricao: 'Detalhado por disciplina' },
  { value: 2, descricao: 'Global (somente 1 professor lança)' },
]
// Fim - Turma regular

const TIPO_CONTA = [
  { value: 'ENTRADA', descricao: 'Entrada' },
  { value: 'SAÍDA', descricao: 'Saída' },
]

const APROVA_BASE = [
  { value: 1, descricao: 'Sim' },
  { value: 2, descricao: 'Não' },
]

const TIPO_FORMA_PAGAMENTO = [
  { value: '1', descricao: 'Dinheiro' },
  { value: '2', descricao: 'Cheque' },
  { value: '3', descricao: 'Cartão' },
  { value: '4', descricao: 'Outro' },
]

const TIPO_CARTAO = [
  { value: 'C', descricao: 'Crédito' },
  { value: 'D', descricao: 'Débito' },
  { value: 'P', descricao: 'Parcelado' },
  { value: 'X', descricao: 'PIX' }
]

const TIPO_SEXO = [
  { value: 'M', descricao: 'Masculino' },
  { value: 'F', descricao: 'Feminino' },
  { value: 'O', descricao: 'Outro' },
  { value: 'N', descricao: 'Não Informado' },

]

const TIPO_ESTADO_CIVIL = [
  { value: 'N', descricao: 'Não Informado' },
  { value: 'S', descricao: 'Solteiro(a)' },
  { value: 'C', descricao: 'Casado(a)' },
  { value: 'D', descricao: 'Divorciado(a)' },
  { value: 'V', descricao: 'Viuvo(a)' },
  { value: 'O', descricao: 'Outro(a)' },
  { value: 'U', descricao: 'União Estável' },
]


const TIPO_DISCIPLINA = [
  { value: '1', descricao: 'Base Nacional Comum' },
  { value: '2', descricao: 'Parte Diversif. Obrigatória' },
  { value: '4', descricao: 'Adaptação' },
  { value: '5', descricao: 'Dependência' },
  { value: 'X', descricao: 'Optativa' },
  { value: 'W', descricao: 'Artigo 50' },
  { value: 'T', descricao: 'Técnica Profissionalizante' },
  { value: 'F', descricao: 'Formação Geral Básica' },
  { value: 'I', descricao: 'Itinerário Formativo' },
  { value: 'D', descricao: 'Formação G. Básica / It. Formativo' },
]

const TIPO_GRAU_INSTRUCAO = [
  { value: '01', descricao: '1º Grau Completo' },
  { value: '02', descricao: '1º Grau Incompleto' },
  { value: '03', descricao: '2º Grau Completo' },
  { value: '04', descricao: '2º Grau Incompleto' },
  { value: '05', descricao: 'Superior Completo' },
  { value: '06', descricao: 'Superior Incompleto' },
  { value: '07', descricao: 'Especialização Incompleta' },
  { value: '08', descricao: 'Especialização Completa' },
  { value: '09', descricao: 'Mestrado Completo' },
  { value: '10', descricao: 'Mestrado Incompleto' },
  { value: '11', descricao: 'Doutorado Completo' },
  { value: '12', descricao: 'Doutorado Incompleto' },
]

const TIPO_REGIME_TRABALHO = [
  { value: '01', descricao: 'CLT' },
  { value: '02', descricao: 'EST Jurid. Lei 8112/90' },
]

const FORMULAS_MATERIA = [
  { value: 'S', descricao: 'SOMA' },
  { value: 'M', descricao: 'MÉDIA' }
]

const SITUACAO_ALUNO = [
  { value: 'A', descricao: 'ABANDONO(A)' },
  { value: 'C', descricao: 'CANCELADO(A)' },
  { value: 'M', descricao: 'MATRICULADO' },
  { value: 'P', descricao: 'PRÉ-MATRICULADO' },
  { value: 'R', descricao: 'REGISTRADO' },
  { value: 'S', descricao: 'TRANCADO(A)' },
  { value: 'T', descricao: 'TRANSFERIDO(A)' },
  { value: 'N', descricao: 'TRANSFERIDO(A) - INTERNAMENTE' },
  { value: 'X', descricao: 'TRANSFERIDO(A) - FINAL DE ANO' },
  { value: 'V', descricao: 'NÃO RENOVOU' },
]

const SITUACAO_ALUNO_R_M = [
  { value: 'M', descricao: 'MATRICULADO' },
  { value: 'R', descricao: 'REGISTRADO' },
]

const SITUACAO_FINAL_ALUNO = [
  { value: 'A', descricao: 'Aprovado' },
  { value: 'C', descricao: 'Recuperação' },
  { value: 'D', descricao: 'Aprovado com dependência' },
  { value: 'R', descricao: 'Reprovado' },
  { value: 'X', descricao: 'Repetente' },
]

const SITUACAO_ALUNO_HISTORICO = [
  { value: 'A', descricao: 'Aprovado(a)' },
  { value: 'R', descricao: 'Reprovado(a)' },
  { value: 'Z', descricao: 'Retido(a)' },
  { value: 'B', descricao: 'Abandono' },
  { value: 'C', descricao: 'Cancelado' },
  { value: 'S', descricao: 'Trancado' },
  { value: 'T', descricao: 'Transferido' },
]

const LOCAL_PAGAMENTO = [
  { value: 'B', descricao: 'Banco' },
  { value: 'I', descricao: 'Instituição' },
  { value: '', descricao: 'Não imprime boleto' },
]

const UF_LISTA = [
  { nome: "ACRE", sigla: "AC" },
  { nome: "ALAGOAS", sigla: "AL" },
  { nome: "AMAPÁ", sigla: "AP" },
  { nome: "AMAZONAS", sigla: "AM" },
  { nome: "BAHIA", sigla: "BA" },
  { nome: "CEARÁ", sigla: "CE" },
  { nome: "DISTRITO FEDERAL", sigla: "DF" },
  { nome: "ESPÍRITO SANTO", sigla: "ES" },
  { nome: "GOIÁS", sigla: "GO" },
  { nome: "MARANHÃO", sigla: "MA" },
  { nome: "MATO GROSSO", sigla: "MT" },
  { nome: "MATO GROSSO DO SUL", sigla: "MS" },
  { nome: "MINAS GERAIS", sigla: "MG" },
  { nome: "PARÁ", sigla: "PA" },
  { nome: "PARAÍBA", sigla: "PB" },
  { nome: "PARANÁ", sigla: "PR" },
  { nome: "PERNAMBUCO", sigla: "PE" },
  { nome: "PIAUÍ", sigla: "PI" },
  { nome: "RIO DE JANEIRO", sigla: "RJ" },
  { nome: "RIO GRANDE DO NORTE", sigla: "RN" },
  { nome: "RIO GRANDE DO SUL", sigla: "RS" },
  { nome: "RONDÔNIA", sigla: "RO" },
  { nome: "RORAIMA", sigla: "RR" },
  { nome: "SANTA CATARINA", sigla: "SC" },
  { nome: "SÃO PAULO", sigla: "SP" },
  { nome: "SERGIPE", sigla: "SE" },
  { nome: "TOCANTINS", sigla: "TO" }
];

const AGENTEINTEGRADOR = [
  { value: 'CIEE', descricao: 'CIEE' },
  { value: 'IEL', descricao: 'IEL' },
]

const PARENTESCO = [
  { value: 0, descricao: 'Nenhum' },
  { value: 1, descricao: 'Avô(ó)' },
  { value: 2, descricao: 'Filho(a)' },
  { value: 3, descricao: 'Irmão(ã)' },
  { value: 4, descricao: 'Mãe' },
  { value: 5, descricao: 'Pai' },
  { value: 6, descricao: 'Primo(a)' },
  { value: 7, descricao: 'Sobrinho(a)' },
  { value: 8, descricao: 'Tio(a)' },
  { value: 9, descricao: 'Bisavô(ó)' },
  { value: 10, descricao: 'Trisavô(ó)' },
  { value: 11, descricao: 'Sogro(a)' },
  { value: 12, descricao: 'Genro' },
  { value: 13, descricao: 'Nora' },
  { value: 14, descricao: 'Cunhado(a)' },
  { value: 15, descricao: 'Concunhado(a)' },
  { value: 16, descricao: 'Padrasto' },
  { value: 17, descricao: 'Madrasta' },
  { value: 18, descricao: 'Enteado(a)' }
];

const getParentesco = (parentesco: number) => {
  return PARENTESCO.find(s => s.value === parentesco)?.descricao;
}

const getSituacaoAcademica = (sit: string) => {
  return SITUACAO_ALUNO.find(s => s.value === sit)?.descricao;
}

const AVALIACAO = [
  { value: 'NotaB1', descricao: 'AVA1' },
  { value: 'NotaB2', descricao: 'AVA2' },
  { value: 'NotaRI', descricao: 'R.I' },
  { value: 'NotaB3', descricao: 'AVA3' },
  { value: 'NotaB4', descricao: 'AVA4' },
  { value: 'NotaB5', descricao: 'AVA5' },
  { value: 'NotaB6', descricao: 'AVA6' },
]

const FEZ_AVALIACAO = [
  { value: 'S', descricao: 'Sim' },
  { value: 'N', descricao: 'Não' },
  { value: 'L', descricao: 'Sem Lançamento' },
  { value: 'I', descricao: 'Ignorar' },
]

const STATUS_DISCIPLINA = [
  { value: '0', descricao: 'Cursando' },
  { value: '3', descricao: 'Dispensado' },
  { value: '4', descricao: 'Adaptação' },
  { value: '5', descricao: 'Dependência' },
  { value: '6', descricao: 'Reprovado' },
  { value: '7', descricao: 'Não Cursando' },
  { value: '8', descricao: 'Creditado' },
  { value: '9', descricao: 'Aprovado' },
  { value: 'R', descricao: 'Trancado' },
  { value: 'Y', descricao: 'A Cursar' },
]

const SIM_NAO = [
  { value: 'S', descricao: 'Sim' },
  { value: 'N', descricao: 'Não' },
]

const EXIBIR_ALUNOS = [
  { value: 1, descricao: 'Somente os Matriculados' },
  { value: 2, descricao: 'Todos' },
]

const TIPO_DISCIPLINA_COMPLEMENTAR = [
  { value: '1', descricao: 'Base Nacional Comum' },
  { value: '2', descricao: 'Parte Diversif. Obrigatória' },
  { value: '4', descricao: 'Adaptação' },
  { value: '5', descricao: 'Dependência' },
  { value: 'X', descricao: 'Optativa' },
  { value: 'W', descricao: 'Artigo 50' },
  { value: 'T', descricao: 'Técnica Profissionalizante' },
  { value: 'F', descricao: 'Formação Geral Básica' },
  { value: 'I', descricao: 'Itinerário Formativo' },
  { value: 'D', descricao: 'Formação G. Básica / It. Formativo' },
];

const MODULO_PERFIL = [
  { value: 'Secretaria', descricao: 'Secretaria' },
  { value: 'Financeiro', descricao: 'Financeiro' },
  { value: 'Administrativo', descricao: 'Administrativo' },
  { value: 'Gerenciador', descricao: 'Gerenciador' },
]

const CARTAO_BANDEIRA = [
  { value: '01', descricao: 'VISA' },
  { value: '02', descricao: 'MASTERCARD' },
  { value: '03', descricao: 'AMEX' },
  { value: '04', descricao: 'ELO' },
  { value: '05', descricao: 'HIPERCARD' },
  { value: '06', descricao: 'DINERS CLUB' },
  { value: '07', descricao: 'AURA' },
  { value: '08', descricao: 'DISCOVER' },
  { value: '09', descricao: 'JCB' },
]

const ACAO_CAIXA = [
  { value: 'A', descricao: 'Abrir o Caixa' },
  { value: 'F', descricao: 'Encerrar o Caixa' }
]

export {
  getAreaConhecimento,
  getSituacaoAcademica,
  getParentesco,
  DIAS_SEMANA,
  MESES,
  AREAS_CONHECIMENTO,
  ALERTAS,
  TIPO_CURSO,
  GRAU_NIVEL_ESCOLARIDADE,
  SISTEMA_ID,
  FORMA_INGRESSO,
  TURNOS,
  TIPO_FREQUENCIA_CONTROLE,
  CONTROLE_FREQUENCIA,
  CALCULA_FALTA_BASEADO,
  TIPO_CONTA,
  APROVA_BASE,
  MODELO_CONTROLE_FALTAS,
  TIPO_LANCAMENTO_FALTAS,
  TIPO_FORMA_PAGAMENTO,
  TIPO_CARTAO,
  TIPO_SEXO,
  TIPO_ESTADO_CIVIL,
  TIPO_DISCIPLINA,
  TIPO_GRAU_INSTRUCAO,
  TIPO_REGIME_TRABALHO,
  FORMULAS_MATERIA,
  SITUACAO_ALUNO,
  SITUACAO_FINAL_ALUNO,
  UF_LISTA,
  PARENTESCO,
  AGENTEINTEGRADOR,
  LOCAL_PAGAMENTO,
  AVALIACAO,
  FEZ_AVALIACAO,
  STATUS_DISCIPLINA,
  SIM_NAO,
  EXIBIR_ALUNOS,
  TIPO_DISCIPLINA_COMPLEMENTAR,
  MODULO_PERFIL,
  SITUACAO_ALUNO_R_M,
  CARTAO_BANDEIRA,
  ACAO_CAIXA,
  SITUACAO_ALUNO_HISTORICO
};