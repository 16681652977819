import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCFieldset, SCInputNumber, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, FORMULAS_MATERIA, TIPO_DISCIPLINA } from '../utilities/constantes';
import util from '../utilities/util';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import IOfertaDisciplina, { IOfertaDisciplinaForm, IOfertaDisciplinaPut } from '../interfaces/IOfertaDisciplina';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ITurmas from '../interfaces/ITurmas';

interface ITurmaOferta {
  TurmaId?: string;
  TurmaCodigo?: string;
  DisciplinaCodigo?: string;
}

interface Props {
  registro?: ITurmaOferta;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const OfertaDisciplinaMateriaProfessorForm: React.FC<Props> = ({ setShowDialog, registro, reload, deletar, showDelete, setShowDelete }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'OfertaDisciplinaForm';
  const toast = useToast();

  const { periodoSelecionado } = useAuth()

  const [loading, setLoading] = useState<boolean>(false);
  const [turma, setTurma] = useState<ITurmas>({} as ITurmas);
  const [listaDisciplinas, setListaDisciplinas] = useState<any[]>([]);
  const [lista, setLista] = useState<any[]>([]);
  const [listaProfessores, setListaProfessores] = useState<any[]>([]);

  const defaultValues: IOfertaDisciplinaForm = {
    TurmaCodigo: registro?.TurmaCodigo,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, reset, setError, clearErrors, setValue, watch, getValues } = methods;

  useEffect(() => {

    async function load() {
      setLoading(true);
      if (registro?.TurmaId)
      {
        const turmaResult = await api.get('/turma', {
          params: {
            TurmaId: registro?.TurmaId,
          }});
        setTurma(turmaResult.data);

        const result = await api.get('/ofertaDisciplina', {
          params: {
            TurmaCodigo: registro?.TurmaCodigo,
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial
          }
        });
        
        setListaDisciplinas(result.data);
      
        let newArray: any[] = [];

        result.data.forEach((item: any) => {
          const temMateria = Array.from({ length: 6 }).some((_, i) => item[`MateriaCodigo${i + 1}`]);
          if (temMateria) {
            for (let i = 1; i <= 6; i++) {
              if (item[`MateriaCodigo${i}`]) {
                let newItem = { ...item };
                newItem.MateriaCodigo = newItem[`MateriaCodigo${i}`];
                newItem.MateriaProfessor = newItem[`ProfessorMateria${i}`];
                newItem.CampoMateria = `MateriaCodigo${i}`;
                newItem.CampoProfessor = `ProfessorMateria${i}`;
                newArray.push(newItem);
              }
            }
          } else {
            newArray.push(item);
          }
        });
        
        setLista(newArray);
      }
      const professoresResult = await api.get('/professores');
      setListaProfessores(professoresResult.data);

      setLoading(false);
    }


    if (registro) {
      load();
    }
  }, [registro]);

  const onSubmit = async (data: any) => {    
    clearErrors();
    setLoading(true);

    let originalArray: any[] = [];
    lista.forEach((item: any, index: any) => {
      originalArray = listaDisciplinas.map((reg: any) => {
        if (item.DisciplinaCodigo === reg.DisciplinaCodigo) {
          reg[`${item.CampoMateria}`] = item.MateriaCodigo;
          reg[`${item.CampoProfessor}`] = item.MateriaProfessor;
        }
        return reg;
      });
    });

    let resp: any = undefined;
    const oferta: IOfertaDisciplinaPut[] = [];
    originalArray.forEach((item: any, index: any) => { 
      oferta.push({
        OfertaDisciplinaId: item?.OfertaDisciplinaId,
        EmpresaId: item?.EmpresaId,
        Ano: item?.Ano,
        Sequencial: item?.Sequencial,
        TurmaCodigo: item?.TurmaCodigo,
        DisciplinaCodigo: item.DisciplinaCodigo,
        Ordem: item.Ordem,
        Chr: item.Chr,
        Tipo: item.Tipo,
        MateriaCodigo1: item.MateriaCodigo1,
        MateriaCodigo2: item.MateriaCodigo2,
        MateriaCodigo3: item.MateriaCodigo3,
        MateriaCodigo4: item.MateriaCodigo4,
        MateriaCodigo5: item.MateriaCodigo5,
        MateriaCodigo6: item.MateriaCodigo6,
        ProfessorMateria1: item.ProfessorMateria1,
        ProfessorMateria2: item.ProfessorMateria2,
        ProfessorMateria3: item.ProfessorMateria3,
        ProfessorMateria4: item.ProfessorMateria4,
        ProfessorMateria5: item.ProfessorMateria5,
        ProfessorMateria6: item.ProfessorMateria6,
        Calculo: item.Calculo,
        ProfessorDisciplina: item.ProfessorDisciplina,
        Formula: item.Formula,
        Link: item.Link,
        Cabecalho1: item.Cabecalho1,
        Cabecalho2: item.Cabecalho2,
        Cabecalho3: item.Cabecalho3,
        Cabecalho4: item.Cabecalho4,
        Cabecalho5: item.Cabecalho5,
        Cabecalho6: item.Cabecalho6,
        Rodape: item.Rodape,
      });      
    });
    
    try {
      if (registro?.TurmaCodigo === undefined) {
        resp = await api.post('/ofertaDisciplina', { Lista: oferta });
      } else {
        resp = await api.put('/ofertaDisciplina', {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: registro?.TurmaCodigo,
          Lista: oferta,
          ListaInicial: oferta
        });
      }
      
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset();
  }

  const setProfessorLista = (rowData: any, value: string) => {
    console.log(value);
      setLista(lista.map((item: any) => {
        if (item.DisciplinaCodigo === rowData.DisciplinaCodigo && item.MateriaCodigo === rowData.MateriaCodigo) {
          item.MateriaProfessor = value;
        }
        return item;
      }));
  }

  const rowClass = (data: any) => {
    return {
        'bg-orange-500 text-white': data.Calculo === '' && (data.MateriaCodigo1 != '' || data.MateriaCodigo2 != '' || data.MateriaCodigo3 != '' || data.MateriaCodigo4 != '' || data.MateriaCodigo5 != '' || data.MateriaCodigo6 != ''),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >

      {loading ? <Loading /> : (
        <>
          <div className='grid'>
            <div className='col-6'>              
              <SCInputText label='Turma' disabled value={`${turma?.Codigo} - ${turma?.Descricao}`} />
            </div>            
          </div>

          <Divider />
         
          <Divider />
          <div className='grid'>
            <div className='col-12'>
              <DataTable
                size='small'
                stripedRows           
                rowClassName={rowClass}     
                value={lista}
                scrollHeight='400px'
                dataKey="id"
                emptyMessage="Nenhum registro."
                responsiveLayout="scroll">
                <Column header="Disciplina" field='DisciplinaDescricao' sortable body={(rowData: any) => (
                  rowData?.DisciplinaCodigo + ' - ' + rowData?.DisciplinaDescricao
                )} />
                <Column header="Matéria" field='MateriaCodigo' bodyStyle={{width: 120}} body={(rowData: any) => {
                  return <SCInputText value={rowData?.MateriaCodigo} disabled /> 
                }} />     

                <Column header="Professor(a)" field='MateriaProfessor'  body={(rowData: any) => {
                 return <SCDropdown showClear emptyMessage="Nenhum(a) professor(a) encontrato(a)" key={rowData.MateriaCodigo} 
                 options={listaProfessores}
                 optionValue='Guerra' 
                 optionLabel='Guerra' 
                 value={rowData.MateriaProfessor} 
                 onChange={(sel: any) => setProfessorLista(rowData, sel.value)} />                    
                }} />     
              </DataTable>
            </div>
          </div>
          <div className='grid'>

            <Divider />

            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />                
              <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
            </div>

          </div>
        </>
      )}
    </form >

  );
};

export default OfertaDisciplinaMateriaProfessorForm;
