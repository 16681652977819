import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCDropdown, SCFieldset, SCInputText } from '../components';
import AjudaFormTela from './AjudaFormTela';
import IItinerarioTurma from '../interfaces/IItinerarioTurma';
import { CheckboxChangeParams } from 'primereact/checkbox';
import IDisciplina from '../interfaces/IDisciplina';
import IMateria from '../interfaces/IMateria';
import ITurmaAuxiliar from '../interfaces/ITurmaAuxiliar';
import Loading from '../components/Loading';
import { ALERTAS } from '../utilities/constantes';

const ItinerarioTurma = () => {
  const toast = useToast();
  const { periodoSelecionado } = useAuth();
  const tarefa = 'FuncionarioForm';
  const dt = useRef<any>(null);

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [turmas, setTurmas] = useState<any>(null);
  const [itinerarios, setItinerarios] = useState<any>(null);
  const [alunosItinerario, setAlunosItinerario] = useState<any>([]);
  const [botaoPesquisa, setBotaoPesquisa] = useState(true);
  const [disciplinas, setDisciplinas] = useState<IDisciplina[]>([]);
  const [materias, setMaterias] = useState<IMateria[]>([]);
  const [turmaAuxiliar, setTurmaAuxiliar] = useState<ITurmaAuxiliar[]>([]);
  const [habilitaDisciplina, setHabilitaDisciplina] = useState(true);
  const [habilitaMateria, setHabilitaMateria] = useState(true);
  const [habilitaTurmaAux, setHabilitaTurmaAux] = useState(true);
  const [habilitaCargaHoraria, setHabilitaCargaHoraria] = useState(true);
  const [habilitaMarcarTodos, setHabilitahabilitaMarcarTodos] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const defaultValues: IItinerarioTurma = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    TurmaId: 0,
    ItinerariosFormativosId: 0,
    Codigo: '',
    DisciplinaId: 0,
    MateriaId: 0,
    TurmaAuxiliarId: 0,
    CargaHoraria: 0,
    Matricula: 0,
    Selecionado: 0,
    NomeAluno: '',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  const load = async () => {
    const turma = await api.get('/turma/listaVagasOcupadas', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Situacao: 1
      }
    });
    if (turma.data) {
      const turmasComCodigoDescricao = turma.data.map((turma: any) => ({
        ...turma,
        CodigoDescricao: `${turma.Codigo} - ${turma.Descricao}`
      }));
      setTurmas(turmasComCodigoDescricao);
    } else {
      setTurmas([]);
    }
  };

  const watchCodigoTurma = watch('Codigo');
  const watchItinerario = watch('ItinerariosFormativosId');

  const loadItinerario = async () => {
    const itinerario = await api.get('/itinerarios/comboItinerario');
    if (itinerario.data) {
      const itinerarioCodigoDescricao = itinerario.data.map((itifor: any) => ({
        ...itifor,
        ItiForCodigoDescricao: `${itifor.CodigoIti} - ${itifor.Descricao}`
      }));
      setItinerarios(itinerarioCodigoDescricao);
    } else {
      setItinerarios([]);
    }
  };

  const pesquisaAluno = async () => {
    setLoading(true)

    if (watchItinerario && watchCodigoTurma) {
      const { data } = await api.get('/alunosPeriodo/alunosItinerario', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          ItinerarioFormativoId: watchItinerario,
          Turma: watchCodigoTurma
        }
      });


      if (data.length > 0) {
        setHabilitaDisciplina(false);
        setHabilitaMateria(false);
        setHabilitaTurmaAux(false);
        setHabilitaCargaHoraria(false);
        setHabilitahabilitaMarcarTodos(false)
        setAlunosItinerario(data);
      } else {
        setHabilitaDisciplina(true);
        setHabilitaMateria(true);
        setHabilitaTurmaAux(true);
        setHabilitaCargaHoraria(true);
        setHabilitahabilitaMarcarTodos(true)
        setAlunosItinerario([])
      }

      setLoading(false)
    }
  };

  useEffect(() => {
    const loadDisciplina = async () => {
      const resultDisciplina = await api.get('/disciplina/combo', { params: { Descricao: '' } });
      if (resultDisciplina.data) {
        const filtraDisciplinasItinerario = resultDisciplina.data.filter((iti: any) => iti.Itinerario == 1);
        setDisciplinas(filtraDisciplinasItinerario || []);
      }
    };

    loadDisciplina();
  }, [watchCodigoTurma, watchItinerario]);

  const watchDisciplina = watch('DisciplinaId');

  useEffect(() => {
    const loadMateria = async () => {
      if (watchDisciplina) {
        const resultMateria = await api.get('/materia/buscaMateriaPorDisciplina', {
          params: { DisciplinaId: watchDisciplina }
        });
        setMaterias(resultMateria.data);
      }
    };

    loadMateria();
  }, [watchDisciplina]);

  const watchMateria = watch('MateriaId');

  useEffect(() => {
    const loadTurmaAux = async () => {
      if (watchDisciplina) {
        const disciplina = disciplinas.find(d => d.DisciplinaId === watchDisciplina);
        if (disciplina) {
          const { data } = await await api.get('/ofertaDisciplina/buscaDescricaoTurmaAuxiliar', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              DisciplinaCodigo: disciplina.Codigo
            }
          });

          const semTurmaDoAluno = data.filter((turma: any) => turma.TurmaCodigo !== watchCodigoTurma)

          const resultado = semTurmaDoAluno.map((turma: any) => {
            return { ...turma, DescricaoTurmaAuxiliar: `${turma.TurmaCodigo} - ${turma.descricaoTurma}` }
          })

          console.log('DATA', data)
          setTurmaAuxiliar(resultado);
        }
      }
    };

    loadTurmaAux();
  }, [watchDisciplina, disciplinas]);

  const watchTurmaAux = watch('TurmaAuxiliarId');

  const handleCheckboxChange = (rowData: any, checked: boolean) => {
    const updatedAlunosItinerario = alunosItinerario.map((item: any) => {
      if (item.Matricula === rowData.Matricula) {
        return { ...item, Selecionado: checked ? 1 : 0 };
      }
      return item;
    });
    setAlunosItinerario(updatedAlunosItinerario);
  };

  const handleSelectAllChange = (event: any) => {
    const checked = event.target.checked;
    const updatedAlunosItinerario = alunosItinerario.map((item: any) => ({
      ...item,
      Selecionado: checked ? 1 : 0
    }));
    setAlunosItinerario(updatedAlunosItinerario);
    setSelectAllChecked(checked);
  };

  const limpaCampos = () => {
    setValue('Codigo', '')
    setValue('ItinerariosFormativosId', 0)
    setValue('DisciplinaId', 0)
    setValue('MateriaId', 0)
    setValue('TurmaAuxiliarId', 0)
    setValue('CargaHoraria', 0)
    setSelectAllChecked(false)
  }

  const onSubmit = async (data: any) => {
    setLoading(true)
    data.CargaHoraria = Number(data?.CargaHoraria);

    // Filtra os alunos selecionados
    const alunosSelecionados = alunosItinerario.filter((aluno: any) => aluno.Selecionado === 1);

    // Verifica se há alunos selecionados
    if (alunosSelecionados.length === 0) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Por favor, selecione pelo menos um aluno.' });
      return false
    }

    if (!watchDisciplina) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Por favor, selecione uma DISCIPLINA.' });
      return false
    }


    if (!watchTurmaAux) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Por favor, selecione uma TURMA AUXLIAR.' });
      return false
    }

    if (!getValues('CargaHoraria')) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Por favor, informe a CARGA HORÁRIA.' });
      return false
    }

    // Cria o array de objetos com os dados do formulário e dos alunos selecionados
    const dadosFinais: IItinerarioTurma[] = alunosSelecionados.map((aluno: any) => ({
      ...data,
      Matricula: aluno.Matricula,
      Selecionado: aluno.Selecionado
    }));

    const newDadosFinais = dadosFinais.map(({ Selecionado, ...rest }) => rest);

    try {
      const sucesso: any[] = [];
      const falha: any[] = [];

      const postData = newDadosFinais.map(async (item: any) => {
        try {
          const ordemNumeroResponse = await api.get('/disciplinaComplementar/ordem', {
            params: {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              Matricula: item?.Matricula,
            },
          });

          let ordemMaxima = ordemNumeroResponse.data[0]?.maximo === null ? 100 : ordemNumeroResponse.data[0]?.maximo;
          const ordemNumero = ordemMaxima + 1;

          const disciplina = disciplinas.find(d => d.DisciplinaId === item.DisciplinaId);

          await api.post('/disciplinaComplementar', {
            EmpresaId: item?.EmpresaId,
            Ano: item?.Ano,
            Sequencial: item?.Sequencial,
            Matricula: item?.Matricula,
            DisciplinaCodigo: disciplina?.Codigo,
            MateriaCodigo: item?.MateriaId,
            Tipo: 'I',
            CargaHoraria: Number(item?.CargaHoraria),
            Ordem: ordemNumero,
            TurmaAuxiliar: item?.TurmaAuxiliarId,
            TurmaCodigo: item?.Codigo,
          });

          sucesso.push(item);

        } catch (error) {
          const aluno = alunosItinerario.find((a: any) => a.Matricula === item?.Matricula);
          falha.push({ item, error, Matricula: item?.Matricula, NomeAluno: aluno?.Nome });
          const errorMessage = `${aluno?.Nome} (Matrícula: ${item?.Matricula}): Erro - Disciplina/Matéria/Tipo já existe.`;
          toast.showToast({ severity: 'error', summary: 'Atenção!', detail: errorMessage, life: 15000 });
        }
      });

      await Promise.all(postData);

      if (falha.length === 0) {
        toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      }

      limpaCampos()
      setAlunosItinerario([])

    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Erro desconhecido';
      toast.showToast({ severity: 'error', summary: 'Erro', detail: errorMessage });
    }

    setLoading(false)
  };

  useEffect(() => {
    load();
    loadItinerario();
  }, []);

  useEffect(() => {
    if (watchCodigoTurma && watchItinerario)
      setBotaoPesquisa(false);
    else
      setBotaoPesquisa(true);
  }, [watchCodigoTurma, watchItinerario]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Atribui Disciplinas por Itinerário Formativo</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid col-12 mt-3'>
            <div className="col-12 md:col-5">
              <SCDropdown
                name="Codigo"
                options={turmas}
                optionLabel="CodigoDescricao"
                optionValue='Codigo'
                placeholder='Selecione a Turma'
                label='Turma'
                style={{ width: '100%' }}
                value={watchCodigoTurma}
                onChange={(e) => setValue('Codigo', e.target.value)}
                required
              />
            </div>
            <div className="col-12 md:col-5">
              <SCDropdown
                name="ItinerariosFormativosId"
                options={itinerarios}
                optionLabel="ItiForCodigoDescricao"
                optionValue='ItinerariosFormativosId'
                placeholder='Selecione o Itinerário Formativo'
                label='Itinerário Formativo'
                style={{ width: '100%' }}
                value={watchItinerario}
                onChange={(e) => setValue('ItinerariosFormativosId', e.target.value)}
                required
              />
            </div>
            <div className='col-12 md:col-2 flex align-items-end'>
              <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined" onClick={pesquisaAluno} disabled={botaoPesquisa} />
            </div>
          </div>

          <Divider />

          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={alunosItinerario}
              dataKey="id"
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              showGridlines
              responsiveLayout="scroll">

              <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
              <Column field="Nome" header="Nome" sortable headerStyle={{ width: 665 }} />
              <Column
                header={
                  <div>
                    <SCCheckbox
                      label='Marcar Todos'
                      name='selecionarTodos'
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                      disabled={habilitaMarcarTodos}
                    />
                  </div>
                }
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCCheckbox
                      key={rowData.Matricula}
                      checked={rowData.Selecionado === 1}
                      onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                      id={rowData.Matricula}
                      name="Selecionado"
                      value={rowData.Selecionado === 1}
                    />
                  </div>
                )}
                alignHeader='center'
                align='center'
              />
            </DataTable>
          )}


          <div className='grid col-12 mt-3'>
            <SCFieldset legend='Configurar' className='col-12'>
              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCDropdown
                    name="DisciplinaId"
                    options={disciplinas}
                    optionLabel="CodigoDescricao"
                    optionValue='DisciplinaId'
                    placeholder='Selecione a Disciplina'
                    label='Disciplina'
                    style={{ width: '100%' }}
                    value={watchDisciplina}
                    onChange={(e) => setValue('DisciplinaId', e.target.value)}
                    required
                    disabled={habilitaDisciplina}
                  />
                </div>

                <div className="col-12 md:col-6">
                  <SCDropdown
                    name="MateriaId"
                    options={materias}
                    optionLabel="Descricao"
                    optionValue='Codigo'
                    placeholder='Selecione a Matéria'
                    label='Matéria'
                    style={{ width: '100%' }}
                    value={watchMateria}
                    onChange={(e) => setValue('MateriaId', e.target.value)}
                    disabled={habilitaMateria}
                  />
                </div>
              </div>

              <div className='grid'>
                <div className="col-12 md:col-6">
                  <SCDropdown
                    name="TurmaAuxiliarId"
                    options={turmaAuxiliar}
                    optionLabel='DescricaoTurmaAuxiliar'
                    optionValue='TurmaCodigo'
                    placeholder='Selecione a Turma Secundária'
                    label='Turma Secundária'
                    style={{ width: '100%' }}
                    value={watchTurmaAux}
                    onChange={(e) => setValue('TurmaAuxiliarId', e.target.value)}
                    required={true}
                    disabled={habilitaTurmaAux}
                  />
                </div>

                <div className="col-12 md:col-1">
                  <SCInputText
                    control={control}
                    name="CargaHoraria"
                    label='CHR'
                    style={{ width: '100%' }}
                    required
                    disabled={habilitaCargaHoraria}
                  />
                </div>
              </div>
            </SCFieldset>
          </div>

          <div className='grid flex justify-content-end'>
            <div className='col-12 md:col-2'>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='submit' style={{ width: '100%' }} onClick={handleSubmit(onSubmit)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItinerarioTurma;
