import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputText, SCInputTextarea, SCRadioButton } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import util from '../utilities/util';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import IHistoricoEscolar from '../interfaces/IHistoricoEscolar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ALERTAS, SITUACAO_ALUNO, SITUACAO_ALUNO_HISTORICO, TIPO_DISCIPLINA } from '../utilities/constantes';
import { TabPanel, TabView } from 'primereact/tabview';
import { Divider } from 'primereact/divider';
import Loading from '../components/Loading';


interface Props {
  historico?: IHistoricoEscolar;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const HistoricoEscolarForm: React.FC<Props> = ({ setShowDialog, historico, reload, deletar, showDelete, setShowDelete }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'HistoricoEscolarForm';
  const toast = useToast();

  const methods = useForm()
  const { handleSubmit, control, formState: { errors }, getValues, reset, watch, setValue } = methods;

  const { periodoSelecionado, usuario } = useAuth();
  const [alunos, setAlunos] = useState<any>();
  const [disciplinas, setDisciplinas] = useState<any>();
  const [nivelSequencia, setNivelSequencia] = useState<any>();
  const [editando, setEditando] = useState<boolean>(false);
  const [lista, setLista] = useState<any>([]);
  const [detalhado, setDetalhado] = useState<boolean>()
  const [loading, setLoading] = useState(false)

  const watchLancamentoTipo = watch('LancamentoTipo');

  const load = async () => {
    try {
      const result = await api.get('/aluno/list', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
        }
      });
      if (result.data) {
        setAlunos(result.data)
      } else {
        setAlunos([])
      }

      const resultDisc = await api.get('/disciplina/combo', {
        params: {
          Descricao: '',
        }
      });
      if (resultDisc.data) {
        setDisciplinas(resultDisc.data)
      } else {
        setDisciplinas([])
      }

      const resultNivelSequencia = await api.get('/nivelSequencia/list', {
        params: {
          Descricao: '',
        }
      });
      if (resultNivelSequencia.data) {
        console.log('NIVEL_SEQUENCIA', resultNivelSequencia.data)
        setNivelSequencia(resultNivelSequencia.data)
      } else {
        setNivelSequencia([])
      }

      if (historico?.HistoricoId !== 0) {
        const resultHistorico = await api.get('/historicoEscolar/read', {
          params: {
            HistoricoId: historico?.HistoricoId,
          }
        });

        setLista(resultHistorico.data.disciplinas)
      }

      if (historico?.HistoricoId === 0) {
        setValue('LancamentoTipo', 2)
      }

    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }

  useEffect(() => {
    load();
    reset(historico);
  }, []);

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset({ HistoricoId: 0 });
  }

  const onSubmit = async (data: any) => {
    setLoading(true)

    try {
      const { HistoricoDisciplina, Nivel, NivelDescricao, Sequencia, Nome, ...newData } = data;
      if (data.LancamentoTipo == 1) {
        if (data.HistoricoId !== 0) {
          await api.put('/historicoEscolar/historico', { historicoUpdate: newData });
        } else {
          newData.TipoGeracao = 'M'
          await api.post('/historicoEscolar/historico', { novoHistorico: newData });
        }
      } else if (data.LancamentoTipo == 2) {
        data.CargaHorariaTotal = 0;
        data.FrequenciaAnual = 0;
        data.FrequenciaAnualImprimir = false;
        data.GlobalFaltas = 0;
        data.GlobalNotas = 0;

        lista.forEach((objeto: any) => { delete objeto.Descricao });
        const resultDisciplinas = Array.isArray(lista) ? lista : Object.values(lista);

        if (data.HistoricoId !== 0) {
          await api.put('/historicoEscolar/disciplina', { historicoUpdate: newData, disciplinasUpdate: resultDisciplinas });
        } else {
          newData.TipoGeracao = 'M'
          await api.post('/historicoEscolar/disciplina', { novoHistorico: newData, disciplinas: resultDisciplinas });
        }
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);
      setLoading(false)
    } catch (e: any) {
      console.log(e.response.data);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
      setLoading(false)
    }
  }

  const editar = (reg: any) => {
    setEditando(true);

    setValue('HistoricoDisciplina.DisciplinaId', reg.DisciplinaId)
    setValue('HistoricoDisciplina.Media', reg.Media)
    setValue('HistoricoDisciplina.CargaHoraria', reg.CargaHoraria)
    setValue('HistoricoDisciplina.Tipo', reg.Tipo)
    setValue('HistoricoDisciplina.Faltas', reg.Faltas)
  }

  const cancelarEdicao = () => {
    setEditando(false);
    const fieldsToClear = Object.keys(getValues('HistoricoDisciplina'));
    fieldsToClear.forEach(field => setValue(`HistoricoDisciplina.${field}`, ''));
  }

  const adicionarDisciplina = () => {
    const dados = getValues();

    if (dados.HistoricoDisciplina.DisciplinaId == '' || dados.HistoricoDisciplina.DisciplinaId === undefined) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Selecione uma Disciplina.' })
      return;
    }

    if (dados.HistoricoDisciplina.Media == '' || dados.HistoricoDisciplina.Media === undefined) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Preencha o campo Media.' })
      return;
    }

    if (dados.HistoricoDisciplina.CargaHoraria == '' || dados.HistoricoDisciplina.CargaHoraria === undefined) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Preencha o campo Carga Horaria.' })
      return;
    }

    if (dados.HistoricoDisciplina.Tipo == '' || dados.HistoricoDisciplina.Tipo === undefined) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Preencha o campo Tipo.' })
      return;
    }

    if (dados.HistoricoDisciplina.Faltas === undefined) {
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Preencha o campo Faltas.' })
      return;
    }

    const { HistoricoDisciplina } = dados;

    const disciplina = disciplinas.find((item: any) => item.DisciplinaId === HistoricoDisciplina?.DisciplinaId);
    const descricaoDisciplina = disciplina ? disciplina.Descricao : '';

    if (editando) {
      const index = lista.findIndex((item: any) => item.DisciplinaId === HistoricoDisciplina?.DisciplinaId);
      if (index !== -1) {
        const novaLista = [...lista];
        novaLista[index] = { ...HistoricoDisciplina, Descricao: descricaoDisciplina };
        setLista(novaLista);
      }
      setEditando(false);
    } else {
      setLista([...lista, { ...HistoricoDisciplina, Descricao: descricaoDisciplina }]);
    }

    const fieldsToClear = Object.keys(getValues('HistoricoDisciplina'));
    fieldsToClear.forEach(field => setValue(`HistoricoDisciplina.${field}`, ''));
  };

  const remover = (reg: any) => {
    setLista(lista.filter((item: any) => item.DisciplinaId !== reg.DisciplinaId));
  }

  useEffect(() => {
    if (watchLancamentoTipo === 1) {
      setDetalhado(false)
    } else {
      setDetalhado(true)
    }
  }, [watchLancamentoTipo]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className='pt-0' style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
            <SCFieldset legend='Dados do Aluno' className='pb-0'>
              <div className='grid'>
                <div className='col-12 md:col-6'>
                  <SCDropdown
                    name='Matricula'
                    control={control}
                    options={alunos}
                    optionValue='Matricula'
                    optionLabel='Nome'
                    label='Nome'
                    disabled={historico?.HistoricoId !== 0}
                    required
                  />
                </div>
                <div className='col-6 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='HistoricoAno'
                    label='Ano'
                    disabled={historico?.HistoricoId !== 0}
                    required
                  />
                </div>
                <div className='col-6 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='HistoricoSequencia'
                    label='Sequência'
                    disabled={historico?.HistoricoId !== 0}
                    required
                  />
                </div>
                <div className='col-6 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='TipoGeracao'
                    label='Tipo de Geração'
                    disabled
                    value={historico?.TipoGeracao === 'M' || historico?.HistoricoId === 0 ? 'Manual' : 'Automático'}
                    style={{ color: '#0f97c7', fontWeight: 'bold' }}
                  />
                </div>
              </div>
              <div className='grid'>
                <div className='col-12 md:col-4'>
                  <SCDropdown
                    name='NivelSequenciaId'
                    control={control}
                    options={nivelSequencia}
                    optionValue='NivelSequenciaId'
                    optionLabel='DescricaoCompleta'
                    label='Nível/Escolaridade'
                  />
                </div>
                <div className='col-6 md:col-4'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='Instituicao'
                    label='Instituição'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='Localizacao'
                    label='Local'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='SequenciaImpressao'
                    label='Sequência de Impressão'
                    maxLength={2}
                    numberOnly
                  />
                </div>
              </div>
              <div className='grid'>
                <div className='col-6 md:col-3'>
                  <SCDropdown
                    name='Situacao'
                    control={control}
                    options={SITUACAO_ALUNO_HISTORICO}
                    optionValue='value'
                    optionLabel='descricao'
                    label='Situação'
                  />
                </div>
                <div className='col-12 md:col-1'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='DiasLetivos'
                    label='Dias Letivos'
                    numberOnly
                    tarefa={tarefa}
                  />
                </div>
                <div className='col-12 md:col-4'>
                  <label>Baseado Em</label>
                  <div className='flex justify-content-between mt-1'>
                    <SCRadioButton
                      control={control}
                      name='Baseado'
                      label='Série'
                      value={1}
                    />
                    <SCRadioButton
                      control={control}
                      name='Baseado'
                      label='Ano'
                      value={2}
                    />
                    <SCRadioButton
                      control={control}
                      name='Baseado'
                      label='Módulo'
                      value={3}
                    />
                    <SCRadioButton
                      control={control}
                      name='Baseado'
                      label='Período'
                      value={4}
                    />
                  </div>
                </div>
              </div>
              <div className='grid'>
                <div className='col-12 md:col-2'>
                  <label>Tipo de Lançamento</label>
                  <div className='flex justify-content-between mt-1'>
                    <SCRadioButton
                      control={control}
                      name='LancamentoTipo'
                      label='Global'
                      value={1}
                    />
                    <SCRadioButton
                      control={control}
                      name='LancamentoTipo'
                      label='Detalhado'
                      value={2}
                    />
                  </div>
                </div>
              </div>
            </SCFieldset>
            {detalhado ? (
              <>
                <SCFieldset legend='Disciplinas' className='mt-3'>
                  <div className='grid mb-2'>
                    <div className='col-6 md:col-4'>
                      <SCDropdown
                        name='HistoricoDisciplina.DisciplinaId'
                        control={control}
                        options={disciplinas}
                        optionValue='DisciplinaId'
                        optionLabel='CodigoDescricao'
                        label='Disciplina'
                        onChange={(e) => setValue('HistoricoDisciplina.DisciplinaId', e.target.value)}
                        disabled={editando}
                        resetFilterOnHide={true}
                      />
                    </div>
                    <div className='col-6 md:col-2'>
                      <SCInputText
                        control={control}
                        errors={errors}
                        name='HistoricoDisciplina.Media'
                        label='Média'
                      />
                    </div>
                    <div className='col-6 md:col-2'>
                      <SCInputText
                        control={control}
                        errors={errors}
                        name='HistoricoDisciplina.CargaHoraria'
                        label='Carga Horária'
                        numberOnly
                        onBlur={(e) => setValue('HistoricoDisciplina.CargaHoraria', isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value))} />
                    </div>
                    <div className='col-6 md:col-3'>
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name='HistoricoDisciplina.Tipo'
                        label='Tipo'
                        options={TIPO_DISCIPLINA}
                        optionLabel='descricao'
                        optionValue='value'
                        onChange={(e) => setValue('HistoricoDisciplina.Tipo', e.target.value)} />
                    </div>
                    <div className='col-6 md:col-1'>
                      <SCInputText
                        control={control}
                        errors={errors}
                        name='HistoricoDisciplina.Faltas'
                        label='Faltas'
                        numberOnly
                        onBlur={(e) => setValue('HistoricoDisciplina.Faltas', isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value))} />
                    </div>
                    <div className="col-1 flex ">
                      {editando ? (
                        <>
                          <SCButton type='button' icon="pi pi-times" className="p-button-danger mt-auto mr-2" onClick={cancelarEdicao} />
                          <SCButton type='button' icon="pi pi-check" className="p-button-success mt-auto" onClick={adicionarDisciplina} />
                        </>
                      ) : (
                        <SCButton type='button' icon="pi pi-plus" className="p-button-success mt-auto" onClick={adicionarDisciplina} />
                      )}
                    </div>
                  </div>
                  <DataTable
                    size='small'
                    stripedRows
                    value={lista}
                    //dataKey="DisciplinaId"
                    dataKey='id'
                    rows={10}
                    emptyMessage="Nenhum registro encontrado."
                    responsiveLayout="scroll"
                    className='mb-4'
                  >
                    <Column field='Descricao' header='Disciplina' style={{ width: 180 }} />
                    <Column field='Media' header='Média' style={{ width: 150 }} />
                    <Column field='CargaHoraria' header='Carga Horária' style={{ width: 150 }} />
                    <Column field='Tipo' header='Tipo' style={{ width: 240 }} body={(rowData) => {
                      const tipo = TIPO_DISCIPLINA.find(item => item.value === rowData.Tipo);
                      return tipo ? tipo.descricao : '';
                    }} />
                    <Column field='Faltas' header='Faltas' style={{ width: 180 }} />
                    <Column body={(rowData: any) => {
                      return (
                        <div className="actions flex align-items-center justify-content-between">
                          <SCButton
                            type='button' icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                          <SCButton
                            type='button' icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => remover(rowData)} />
                        </div>
                      );
                    }} style={{ width: 90 }} />
                  </DataTable>
                </SCFieldset>
              </>
            ) : (
              <div className='grid mt-2 mb-4'>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='GlobalNotas'
                    label='Média Global'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='GlobalFaltas'
                    label='Falta Global'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='CargaHorariaTotal'
                    label='Carga Horária Global'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='FrequenciaAnual'
                    label='Frequência Anual'
                  />
                </div>
                <div className='col-12 md:col-4 mt-4'>
                  <SCCheckbox
                    control={control}
                    errors={errors}
                    name='FrequenciaAnualImprimir'
                    label='NÃO imprimir o percentual de Frequência Anual'
                  />
                </div>
              </div>
            )}
            <SCFieldset legend='Educação Física' className='mt-3 pb-0'>
              <div className='grid'>
                <div className='col-12 md:col-1 mt-4'>
                  <SCCheckbox
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaIsento'
                    label='Isento'
                  />
                </div>
                <div className='col-12 md:col-1'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaAlinea'
                    label='Alínea'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaLei'
                    label='Lei'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaCargaHoraria'
                    label='Carga Horária'
                  />
                </div>
                <div className='col-12 md:col-1'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaFaltas'
                    label='Faltas'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaFrequencia'
                    label='% Frequência'
                  />
                </div>
                <div className='col-12 md:col-2'>
                  <SCInputText
                    control={control}
                    errors={errors}
                    name='EducacaoFisicaMedia'
                    label='Média'
                  />
                </div>
              </div>
            </SCFieldset>
            {showDelete ? (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
                <Button label="Confirmar Exclusão" icon="pi pi-check"
                  className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                  onClick={deletar} type='button' />
              </div>
            ) : (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
              </div>
            )}
          </form>
        </FormProvider >
      )}

    </>
  );
};

export default HistoricoEscolarForm;
