import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SCButton, SCCheckbox, SCDropdown, SCFieldset, SCInputText } from '../components';
import AlunoResumo from '../components/AlunoResumo';
import Loading from '../components/Loading';
import { useToast } from '../context/ToastContext';
import { IAlunoForm } from '../interfaces/IAlunos';
import IAlunosSituacao from '../interfaces/IAlunosSituacao';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { ALERTAS, APROVA_BASE, AVALIACAO, FEZ_AVALIACAO, TIPO_DISCIPLINA } from '../utilities/constantes';
import { IBoletim } from '../interfaces/IBoletim';
import { InputText } from 'primereact/inputtext';
import { CheckboxChangeParams } from 'primereact/checkbox';

interface Props {
  aluno: IAlunoForm;
  setAlunoDialog(param: boolean): void;
  reload(): void;
}

const NotasAlunoForm: React.FC<Props> = ({ setAlunoDialog, aluno, reload }) => {
  const tarefa = 'NotasAlunosForm';
  const [loading, setLoading] = useState<boolean>(false);
  const [listaBoletim, setListaBoletim] = useState<any[]>([]);
  const [avaliacao, setAvaliacao] = useState<any>();
  const [desabilitaNota, setDesabilitaNota] = useState(false)
  const [ignorar, setIgnorar] = useState(false)

  const toast = useToast();
  const { periodoSelecionado } = useAuth();

  const defaultValues: IBoletim = {
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    Matricula: 0,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const load = async () => {
    const { data } = await api.get('/aluno', {
      params: { AlunoId: aluno?.AlunoId }
    });

    const alunoperiodo = await api.get('/alunosPeriodo/matricula', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: data?.Matricula
      }
    });

    data.Turma = {
      Codigo: alunoperiodo.data.TurmaCodigo,
    };

    data.AlunosPeriodo = alunoperiodo.data;
    reset(data);
  };

  const onEditorValueChange = (props: any, value: any) => {
    let updatedBoletim = [...listaBoletim];
    updatedBoletim[props.rowIndex][props.field] = value;
    updatedBoletim[props.rowIndex]['FezProva' + avaliacao.slice(-2)] = 'S';
    setListaBoletim(updatedBoletim);
  };

  const inputNumberEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        // Limitar o valor máximo para 10.00
        value = Math.min(value, 10.00);

        if (value !== parseFloat(e.target.value)) {
          toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Nota máxima permitida é 10.00' });
          return false
        }

        value = parseFloat(value.toFixed(2)); // Formatar para até 2 casas decimais
        onEditorValueChange({ field: field, rowIndex: rowIndex }, value); // Atualizar o estado
      } else {
        let value = e.target.value;
        if (value === '') {
          onEditorValueChange({ field: field, rowIndex: rowIndex }, null);
        } else {
          let numberValue = parseFloat(value);
          if (!isNaN(numberValue)) {
            numberValue = parseFloat(numberValue.toFixed(1)); // Formatar para até 2 casas decimais
            onEditorValueChange({ field: field, rowIndex: rowIndex }, numberValue); // Atualizar o estado
          }
        }
      }
    };

    return (
      <SCInputText
        type="number"
        step="0.1"
        defaultValue={props.rowData[props.field]}
        onBlur={handleBlur}
        disabled={props.rowData.desabilitaNota}
        style={{ borderWidth: 1, borderColor: "#0772b3" }}
      />
    );
  };

  const buscaAvaliacao = async () => {
    if (avaliacao) {
      const listaDisciplinas = await api.get('/boletim/buscaNotas', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          Matricula: aluno?.Matricula,
          Nota: avaliacao
        }
      });

      if (listaDisciplinas) {
        console.log(listaDisciplinas.data)
        setListaBoletim(listaDisciplinas.data);
      }
    } else {
      setListaBoletim([]);
    }
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    buscaAvaliacao();
  }, [avaliacao]);

  const tipoDisciplinaBodyTemplate = (rowData: any) => {
    const tipo = TIPO_DISCIPLINA.find(item => item.value === rowData.Tipo);
    return tipo ? tipo.descricao : rowData.Tipo;
  };

  const getFezProvaField = (avaliacao: string, rowData: any) => {
    switch (avaliacao) {
      case 'NotaB1':
        return rowData.FezProvaB1;
      case 'NotaB2':
        return rowData.FezProvaB2;
      case 'NotaRI':
        return rowData.FezProvaRI;
      case 'NotaB3':
        return rowData.FezProvaB3;
      case 'NotaB4':
        return rowData.FezProvaB4;
      case 'NotaB5':
        return rowData.FezProvaB5;
      case 'NotaB6':
        return rowData.FezProvaB6;
      default:
        return null;
    }
  };

  const handleDropdownChange = (selectedValue: any, id: any) => {
    setListaBoletim((prevBoletim: any) => {
      return prevBoletim.map((boletim: any) => {
        if (boletim.BoletimId !== id) {
          return boletim;
        }

        const updatedBoletim = { ...boletim };

        const updateFezProva = (field: string, notaField: string) => {
          updatedBoletim[field] = selectedValue;
        };

        switch (avaliacao) {
          case 'NotaB1':
            updateFezProva('FezProvaB1', 'NotaB1');
            break;
          case 'NotaB2':
            updateFezProva('FezProvaB2', 'NotaB2');
            break;
          case 'NotaRI':
            updateFezProva('FezProvaRI', 'NotaRI');
            break;
          case 'NotaB3':
            updateFezProva('FezProvaB3', 'NotaB3');
            break;
          case 'NotaB4':
            updateFezProva('FezProvaB4', 'NotaB4');
            break;
          case 'NotaB5':
            updateFezProva('FezProvaB5', 'NotaB5');
            break;
          case 'NotaB6':
            updateFezProva('FezProvaB6', 'NotaB6');
            break;
          default:
            break;
        }
        return updatedBoletim;
      });
    });
  };

  useEffect(() => {
    listaBoletim.forEach(boletim => {
      handleDropdownChange('I', boletim.BoletimId);
    });

    if (!ignorar) {
      buscaAvaliacao()
    }
  }, [ignorar]);

  const onSubmit = async () => {

    let resp: any = undefined;

    const updatedListaBoletim = listaBoletim.map(item => {
      const { Descricao, desabilitaNota, ...rest } = item;
      return rest;
    });

    const boletimAluno = updatedListaBoletim.map((item: any) => {

      if (avaliacao === 'NotaB1') {
        if (item.FezProvaB1 !== 'S' && item.FezProvaB1 !== 'I') {
          item.NotaB1 = 0
        }
      }

      if (avaliacao === 'NotaB2') {
        if (item.FezProvaB2 !== 'S' && item.FezProvaB2 !== 'I') {
          item.NotaB2 = 0
        }
      }

      if (avaliacao === 'NotaRI') {
        if (item.FezProvaRI !== 'S' && item.FezProvaRI !== 'I') {
          item.NotaRI = 0
        }
      }

      if (avaliacao === 'NotaB3') {
        if (item.FezProvaB3 !== 'S' && item.FezProvaB3 !== 'I') {
          item.NotaB3 = 0
        }
      }

      if (avaliacao === 'NotaB4') {
        if (item.FezProvaB4 !== 'S' && item.FezProvaB4 !== 'I') {
          item.NotaB4 = 0
        }
      }

      if (avaliacao === 'NotaB5') {
        if (item.FezProvaB5 !== 'S' && item.FezProvaB5 !== 'I') {
          item.NotaB5 = 0
        }
      }

      if (avaliacao === 'NotaB6') {
        if (item.FezProvaB6 !== 'S' && item.FezProvaB6 !== 'I') {
          item.NotaB6 = 0
        }
      }

      return item;

    })

    try {
      setLoading(true)
      resp = await api.put('/boletim/multiUpdate', boletimAluno)

      setLoading(false)
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setAlunoDialog(false);

    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const notaBodyTemplate = (rowData: any) => {
    const value = rowData[avaliacao];
    //return value !== undefined && value !== null && !isNaN(value) ? value.toFixed(2) : '';

    return (<SCInputText
      type="number"
      step="0.1"
      value={value !== undefined && value !== null && !isNaN(value) ? value.toFixed(1) : ''}
      disabled={true}
      style={{ borderWidth: 1, borderColor: "#0772b3" }}
    />)
  };

  const cancelar = (e: any) => {
    setAlunoDialog(false);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
        <div className='grid'>
          <AlunoResumo mostraFoto mostraTurma aluno={getValues() as IAlunoForm} />
        </div>
        <Divider />
        <div className='grid justify-content-center'>
          <SCFieldset legend='Avaliação' className='col-6 mt-2' >
            <div className='grid flex justify-content-around'>
              <div className="col-12 md:col-4">
                <SCDropdown
                  autoFocus={true}
                  control={control}
                  errors={errors}
                  name="Avaliacao"
                  label=''
                  options={AVALIACAO}
                  optionLabel='descricao'
                  value={avaliacao}
                  onChange={(e: any) => setAvaliacao(e.target.value)}
                  optionValue='value'
                  required
                />
              </div>
              <div className="col-12 md:col-6 mt-2">
                <SCCheckbox
                  name="ignorar"
                  label='Fez avaliação: IGNORAR para todos?'
                  checked={ignorar}
                  onChange={e => setIgnorar(e.target.checked)}
                />
              </div>
            </div>
          </SCFieldset>
        </div>

        <Divider />

        {loading ? <Loading /> : (
          <DataTable
            size='small'
            stripedRows
            dataKey="id"
            value={listaBoletim}
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            emptyMessage="Selecione uma avaliação."
            editMode='cell'
            responsiveLayout="scroll"
          >
            <Column field="Tipo" header="Tipo" headerStyle={{ width: 180 }} body={tipoDisciplinaBodyTemplate}></Column>
            <Column field="DisciplinaCodigo" header="Disciplina"  ></Column>
            <Column field="Descricao" header="Descrição"  ></Column>
            <Column field="Materia" header="Matéria"  ></Column>
            <Column field="TurmaAuxiliar" header="Turma Auxiliar"></Column>
            <Column field={avaliacao} header="Nota" alignHeader='center' headerStyle={{ width: 90 }} editor={inputNumberEditor}
              body={notaBodyTemplate}></Column>

            <Column field="FezAvaliacao" style={{ width: 180 }} header="Fez Avaliação?" body={(rowData: any) => (
                <SCDropdown
                  value={getFezProvaField(avaliacao, rowData)}
                  onChange={(e) => handleDropdownChange(e.value, rowData.BoletimId)}
                  options={FEZ_AVALIACAO}
                  optionLabel='descricao'
                  style={{ width: 180 }}
                />
            )}
              alignHeader='center'              
              align='center'></Column>
          </DataTable>
        )}

        <Divider />

        <div className="flex flex-1 justify-content-between pt-3 px-2">
          <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
          <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
        </div>

      </form >
    </FormProvider>
  );
};

export default NotasAlunoForm;
