import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCFieldset, SCInputNumber, SCInputText, SCCheckbox } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, TIPO_DISCIPLINA, CONTROLE_FREQUENCIA } from '../utilities/constantes';
import util from '../utilities/util';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import IOfertaDisciplina, { IOfertaDisciplinaForm, IOfertaDisciplinaPut } from '../interfaces/IOfertaDisciplina';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaCheck } from 'react-icons/fa';

interface Props {
  registro?: IOfertaDisciplina;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const OfertaDisciplinaForm: React.FC<Props> = ({ setShowDialog, registro, reload, deletar, showDelete, setShowDelete }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'OfertaDisciplinaForm';
  const toast = useToast();

  const { periodoSelecionado } = useAuth()

  const [loading, setLoading] = useState<boolean>(false);
  const [listaProfessores, setListaProfessores] = useState<any[]>([]);
  const [listaTurmas, setListaTurmas] = useState<any[]>([]);
  const [turma, setTurma] = useState<any>(undefined);
  const [listaDisciplinas, setListaDisciplinas] = useState<any[]>([]);
  const [ChrTotal, setChrTotal] = useState<string>('');
  const [lista, setLista] = useState<IOfertaDisciplinaPut[]>([]);
  const [listaItem, setListaItem] = useState<IOfertaDisciplinaForm>({});
  const [listaInicial, setListaInicial] = useState<IOfertaDisciplinaForm[]>([]);
  const [editando, setEditando] = useState<boolean>(false);
  const [disableDisciplina, setDisableDisciplina] = useState<boolean>(false);

  const defaultValues: IOfertaDisciplinaForm = {
    TurmaCodigo: registro?.TurmaCodigo,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, reset, setError, clearErrors, setValue, watch, getValues } = methods;

  useEffect(() => {

    async function load() {
      setLoading(true);
      if (registro?.TurmaCodigo) {
        const turmas = await api.get('/turmas', { params: { EmpresaId: periodoSelecionado?.EmpresaId, Ano: periodoSelecionado?.Ano, Sequencial: periodoSelecionado?.Sequencial } });
        setListaTurmas(turmas.data);
      } else {
        const turmas = await api.get('/turmasSemOfertaDisciplina', { params: { EmpresaId: periodoSelecionado?.EmpresaId, Ano: periodoSelecionado?.Ano, Sequencial: periodoSelecionado?.Sequencial } });
        setListaTurmas(turmas.data);
      }
      const discpilinas = await api.get('/disciplina', { params: { Descricao: '' } });
      setListaDisciplinas(discpilinas.data);

      const professoresResult = await api.get('/professores');
      setListaProfessores(professoresResult.data);

      if (registro?.TurmaCodigo) {
        const result = await api.get('/ofertaDisciplina', {
          params: {
            TurmaCodigo: registro?.TurmaCodigo,
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial
          }
        });

        setListaInicial(result.data)
        setLista(result.data);
      }
      setLoading(false);
    }


    if (registro) {
      load();
    }
  }, [registro]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    let resp: any = undefined;
    const oferta: IOfertaDisciplinaPut[] = [];
    let i = 0;
    let Turma;
    lista.forEach((item: any) => {
      i++;
      Turma = item?.TurmaCodigo
      oferta.push({
        OfertaDisciplinaId: item?.OfertaDisciplinaId,
        EmpresaId: item?.EmpresaId,
        Ano: item?.Ano,
        Sequencial: item?.Sequencial,
        TurmaCodigo: item?.TurmaCodigo,
        DisciplinaCodigo: item.DisciplinaCodigo,
        Ordem: i,
        Chr: item.Chr,
        Tipo: item.Tipo,
        MateriaCodigo1: item.MateriaCodigo1,
        MateriaCodigo2: item.MateriaCodigo2,
        MateriaCodigo3: item.MateriaCodigo3,
        MateriaCodigo4: item.MateriaCodigo4,
        MateriaCodigo5: item.MateriaCodigo5,
        MateriaCodigo6: item.MateriaCodigo6,
        ProfessorMateria1: item.ProfessorMateria1,
        ProfessorMateria2: item.ProfessorMateria2,
        ProfessorMateria3: item.ProfessorMateria3,
        ProfessorMateria4: item.ProfessorMateria4,
        ProfessorMateria5: item.ProfessorMateria5,
        ProfessorMateria6: item.ProfessorMateria6,
        DisciplinaConcluida: item.DisciplinaConcluida,
        ControleFrequencia: item.ControleFrequencia,
        Calculo: item.Calculo,
        ProfessorDisciplina: item.ProfessorDisciplina,
        Formula: item.Formula,
        Link: item.Link,
        Cabecalho1: item.Cabecalho1,
        Cabecalho2: item.Cabecalho2,
        Cabecalho3: item.Cabecalho3,
        Cabecalho4: item.Cabecalho4,
        Cabecalho5: item.Cabecalho5,
        Cabecalho6: item.Cabecalho6,
        Rodape: item.Rodape,
      });
    });


    try {
      const alunos = await api.get('/alunosPeriodo/listaAlunosTurma', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: Turma,
        }
      })
      if (registro?.OfertaDisciplinaId === undefined) {
        resp = await api.post('/ofertaDisciplina', { Lista: oferta });
        lista.forEach((item: any) => {
          alunos.data.forEach(async (item1: any) => {
            const novoBoletim = {
              EmpresaId: periodoSelecionado?.EmpresaId,
              Ano: periodoSelecionado?.Ano,
              Sequencial: periodoSelecionado?.Sequencial,
              Matricula: item1.Matricula,
              TurmaCodigo: item?.TurmaCodigo,
              DisciplinaCodigo: item.DisciplinaCodigo,
              Tipo: item.Tipo,
              Ordem: item.Ordem,
              CargaHoraria: item.Chr,
            }
            await api.post('/boletim', novoBoletim)
          })
        })
      } else {
        resp = await api.put('/ofertaDisciplina', {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: registro?.TurmaCodigo,
          Lista: oferta,
          ListaInicial: listaInicial
        });

        const getIdsSet = (oferta: IOfertaDisciplinaPut[]) => new Set(oferta.map(item => item.OfertaDisciplinaId));

        const idsOferta = getIdsSet(oferta);
        const idsInicial = getIdsSet(listaInicial);

        const comuns = oferta.filter(item => idsInicial.has(item.OfertaDisciplinaId));
        comuns.forEach(async (item) => {
          let envioBoletim = {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial,
            DisciplinaCodigo: item.DisciplinaCodigo,
            TurmaCodigo: item.TurmaCodigo,
            Boletim: {
              CargaHoraria: item.Chr,
              Ordem: item.Ordem,
            }
          }
          await api.put('/boletim/updateOferta', envioBoletim)
        })

        const novosElementos = oferta.filter(item => !idsInicial.has(item.OfertaDisciplinaId));
        if (novosElementos.length > 0) {
          novosElementos.forEach((item) => {
            alunos.data.forEach(async (item1: any) => {
              const novoBoletim = {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Matricula: item1.Matricula,
                TurmaCodigo: item?.TurmaCodigo,
                DisciplinaCodigo: item.DisciplinaCodigo,
                Tipo: item.Tipo,
                Ordem: item.Ordem,
                CargaHoraria: item.Chr,
              }
              await api.post('/boletim', novoBoletim)
            })
          })
        }

        const elementosExcluidos = listaInicial.filter(item => !idsOferta.has(item.OfertaDisciplinaId));
        if (elementosExcluidos.length > 0) {
          elementosExcluidos.forEach((item) => {
            alunos.data.forEach(async (item1: any) => {
              await api.delete('/boletim/deleteOferta', {
                params: {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Ano: periodoSelecionado?.Ano,
                  Sequencial: periodoSelecionado?.Sequencial,
                  Matricula: item1.Matricula,
                  TurmaCodigo: item?.TurmaCodigo,
                  DisciplinaCodigo: item.DisciplinaCodigo,
                }
              });
            })
          })
        }
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset();
  }

  const cancelarEdicao = () => {
    setEditando(false);
    setDisableDisciplina(false);
    reset({ TurmaCodigo: registro?.TurmaCodigo });
  }

  const editar = (reg: any) => {
    setEditando(true);
    console.log(reg)
    if (naoPossuiMateria(reg)) {
      setDisableDisciplina(false)
    } else {
      setDisableDisciplina(true)
    }

    reset(reg);
  }

  const remover = (reg: any, options: any) => {
    setLista(lista.filter((item, index) => index !== options.rowIndex));
  }

  const adicionarDisciplina = () => {
    const dados = getValues();
    clearErrors();

    if (dados.DisciplinaCodigo === undefined || dados.DisciplinaCodigo === '') {
      setError('DisciplinaCodigo', { type: 'manual', message: 'Disciplina é obrigatória.' });
      return;
    }
    if (dados.Chr === undefined || dados.Chr === null || dados.Chr === 0) {
      setError('Chr', { type: 'manual', message: 'CHR é obrigatório.' });
      return;
    }
    if (dados.Tipo === undefined || dados.Tipo === '') {
      setError('Tipo', { type: 'manual', message: 'Tipo é obrigatório.' });
      return;
    }
    if (dados.ControleFrequencia === undefined || dados.ControleFrequencia === 0) {
      setError('ControleFrequencia', { type: 'manual', message: 'Controle de Frequência é obrigatório.' });
      return;
    }

    // Usando OfertaDisciplinaId para encontrar o item existente
    const index = lista.findIndex((item) => item.DisciplinaCodigo === dados.DisciplinaCodigo && item.Tipo === dados.Tipo);

    if (index > -1 && editando) {
      // Substituir o item existente
      const novaLista = lista.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            Chr: dados.Chr,
            Tipo: dados.Tipo,
            ControleFrequencia: dados.ControleFrequencia,
            MateriaCodigo1: '',
            MateriaCodigo2: '',
            MateriaCodigo3: '',
            MateriaCodigo4: '',
            MateriaCodigo5: '',
            MateriaCodigo6: '',
            ProfessorMateria1: '',
            ProfessorMateria2: '',
            ProfessorMateria3: '',
            ProfessorMateria4: '',
            ProfessorMateria5: '',
            ProfessorMateria6: '',
          };
        }
        return item;
      });
      setLista(novaLista);
      setEditando(false);
    }
    else if (index == -1) {
      setLista([...lista, {
        ...dados,
        TurmaCodigo: dados?.TurmaCodigo,
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial
      }]);
    }
    else if (index > -1 && !editando) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Disciplina já foi adicionada.' });
      return false;
    }

    reset({ TurmaCodigo: dados?.TurmaCodigo });
    setDisableDisciplina(false)
  };

  useEffect(() => {
    let total = 0;
    lista.forEach((item) => {
      total += item.Chr || 0;
    });
    setChrTotal(total.toString());
  }, [lista]);

  const copiar = () => {
    localStorage.setItem('lista-oferta-disciplina', JSON.stringify(lista));
    toast.showToast({ severity: 'success', summary: 'Atenção', detail: 'Lista copiada com sucesso.', life: 2000 });
  }

  const colar = () => {
    const listaCopiada = localStorage.getItem('lista-oferta-disciplina');
    if (listaCopiada) {
      const copiaOferta = JSON.parse(listaCopiada).map((item: any) => {
        item.TurmaCodigo = getValues().TurmaCodigo;
        item.OfertaDisciplinaId = undefined;
        return item;
      });
      setLista(copiaOferta);
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: 'Lista colada com sucesso.', life: 2000 });
    }
  }

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const update = result.map((item: any, index: any) => { item.Ordem = index + 1; return item; });

    return update;
  };

  const onDragEnd = async (result: any) => {

    if (result.dropIndex === null || result.dropIndex === undefined) {
      return;
    }

    const items = reorder(
      lista,
      result.dragIndex,
      result.dropIndex
    );
    //const update = await api.put('/documentosMatricula/reordenar', items);
    setLista(items);
  }

  const naoPossuiMateria = (rowData: any) => {
    return (rowData.MateriaCodigo1 === undefined || rowData.MateriaCodigo1 === '' || rowData.MateriaCodigo1 === null) &&
      (rowData.MateriaCodigo2 === undefined || rowData.MateriaCodigo2 === '' || rowData.MateriaCodigo2 === null) &&
      (rowData.MateriaCodigo3 === undefined || rowData.MateriaCodigo3 === '' || rowData.MateriaCodigo3 === null) &&
      (rowData.MateriaCodigo4 === undefined || rowData.MateriaCodigo4 === '' || rowData.MateriaCodigo4 === null) &&
      (rowData.MateriaCodigo5 === undefined || rowData.MateriaCodigo5 === '' || rowData.MateriaCodigo5 === null) &&
      (rowData.MateriaCodigo6 === undefined || rowData.MateriaCodigo6 === '' || rowData.MateriaCodigo6 === null);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >

      {loading ? <Loading /> : (
        <>
          <div className='grid'>
            <div className='col-6'>
              <SCDropdown
                label='Turma'
                control={control}
                name="TurmaCodigo"
                options={listaTurmas}
                optionLabel='TurmaDescricao'
                optionValue='Codigo'
                placeholder='Selecionar turma'
                required
                disabled={showDelete || registro?.TurmaCodigo !== undefined}
              />
            </div>
            <div className="col-2">
              <SCInputText label='CHR Total' disabled value={ChrTotal} />
            </div>
          </div>

          <Divider />
          <SCFieldset legend="Disciplina">
            <div className='grid'>
              <div className='col-4'>
                <SCDropdown
                  resetFilterOnHide
                  control={control}
                  errors={errors}
                  name='DisciplinaCodigo'
                  label='Nova Disciplina'
                  options={listaDisciplinas}
                  optionLabel='Descricao'
                  optionValue='Codigo'
                  placeholder='...'
                  disabled={disableDisciplina}
                  showFilterClear
                />
              </div>
              <div className="col-2">
                <SCInputNumber
                  name='Chr'
                  control={control}
                  errors={errors}
                  label='Carga Horária'
                  useGrouping={false}
                  onValueChange={(e) => setValue('Chr', e.value)}
                />
              </div>
              <div className='col-4'>
                <SCDropdown
                  control={control}
                  errors={errors}
                  name='Tipo'
                  label='Tipo'
                  options={TIPO_DISCIPLINA}
                  optionLabel='descricao'
                  optionValue='value'
                  placeholder='...'
                />
              </div>
              <div className='col-4'>
                <SCDropdown
                  control={control}
                  errors={errors}
                  name='ControleFrequencia'
                  label='Controle de Frequência'
                  options={CONTROLE_FREQUENCIA}
                  optionLabel='descricao'
                  optionValue='value'
                  placeholder='...'
                />
              </div>
              <div className="col-1 flex ">
                {editando ? (
                  <>
                    <SCButton type='button' icon="pi pi-times" className="p-button-danger mt-auto mr-2" onClick={cancelarEdicao} />
                    <SCButton type='button' icon="pi pi-check" className="p-button-success mt-auto" onClick={adicionarDisciplina} />
                  </>
                ) : (
                  <SCButton type='button' icon="pi pi-plus" className="p-button-success mt-auto" onClick={adicionarDisciplina} />
                )}
              </div>
            </div>
            <div className='grid'>
              <div className="col-3 flex pt-3">
                <SCCheckbox
                  checked={true}
                  control={control}
                  name="DisciplinaConcluida"
                  label='Disciplina Concluída'
                  disabled={showDelete}
                  style={{ color: 'red' }}
                  tarefa={tarefa}
                />
              </div>
            </div>
          </SCFieldset>

          <Divider />
          <div className='grid'>
            <div className='col-12'>
              <DataTable
                stripedRows
                value={lista}
                scrollHeight='300px'
                dataKey="id"
                emptyMessage="Nenhum registro."
                onRowReorder={(e) => onDragEnd(e)}
                responsiveLayout="scroll">
                <Column rowReorder rowReorderIcon='pi pi-sort-alt' header="" headerStyle={{ width: 30 }}></Column>
                <Column header="Ord" field='Ordem' bodyStyle={{ width: 50, textAlign: 'center' }} sortable />
                <Column header='Concluída' field='DisciplinaConcluida' headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'center' }} body={(rowData: any) => (
                  rowData.DisciplinaConcluida ? <FaCheck /> : null
                )} />
                <Column header="Código" field='DisciplinaCodigo' bodyStyle={{ width: 80 }} sortable />
                <Column header="Descrição" field='Descricao' sortable body={(rowData: any) => {
                  return listaDisciplinas.find((item) => item.Codigo === rowData.DisciplinaCodigo)?.Descricao
                }} />
                <Column header="Carga Horária" field='Chr' bodyStyle={{ width: 50 }} />
                <Column header="Tipo" field='Tipo' body={(rowData: any) => {
                  return TIPO_DISCIPLINA.find((item) => item.value === rowData.Tipo)?.descricao;
                }} />
                <Column header="Controle de Frequência" field='ControleFrequencia' body={(rowData: any) => {
                  return CONTROLE_FREQUENCIA.find((item) => item.value === rowData.ControleFrequencia)?.descricao;
                }} />
                <Column header="Professor(a)" field='ProfessorDisciplina' body={(rowData: any) => {
                  const professor = (rowData.ProfessorMateria1 ? rowData.ProfessorMateria1 : '') +
                    (rowData.ProfessorMateria2 ? ', ' + rowData.ProfessorMateria2 : '') +
                    (rowData.ProfessorMateria3 ? ', ' + rowData.ProfessorMateria3 : '') +
                    (rowData.ProfessorMateria4 ? ', ' + rowData.ProfessorMateria4 : '') +
                    (rowData.ProfessorMateria5 ? ', ' + rowData.ProfessorMateria5 : '') +
                    (rowData.ProfessorMateria6 ? ', ' + rowData.ProfessorMateria6 : '');

                  return professor;
                }} />
                <Column header="Matérias" field='Materias' body={(rowData: any) => {
                  const materias = (rowData.MateriaCodigo1 ? rowData.MateriaCodigo1 : '') +
                    (rowData.MateriaCodigo2 ? ', ' + rowData.MateriaCodigo2 : '') +
                    (rowData.MateriaCodigo3 ? ', ' + rowData.MateriaCodigo3 : '') +
                    (rowData.MateriaCodigo4 ? ', ' + rowData.MateriaCodigo4 : '') +
                    (rowData.MateriaCodigo5 ? ', ' + rowData.MateriaCodigo5 : '') +
                    (rowData.MateriaCodigo6 ? ', ' + rowData.MateriaCodigo6 : '');

                  return materias;
                }} style={{ width: 90 }}
                />
                <Column body={(rowData: any, options: any) => {
                  return (
                    <div className="actions flex align-items-center justify-content-between">
                      <SCButton
                        type='button' icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                      <SCButton disabled={!naoPossuiMateria(rowData)}
                        type='button' icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => remover(rowData, options)} />
                    </div>
                  );
                }} style={{ width: 90 }}
                />
              </DataTable>
            </div>
          </div>
          <div className='grid'>

            <Divider />

            {showDelete ? (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
                <Button label="Confirmar Exclusão" icon="pi pi-check"
                  className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                  onClick={deletar} type='button' />
              </div>
            ) : (
              <div className="flex flex-1 justify-content-between pt-3 px-2">
                <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
                {lista.length > 0 && (
                  <Button label="Copiar disciplinas" icon="pi pi-copy" className="p-button w-auto p-button-info" onClick={copiar} type='button' />
                )}

                {(!registro?.TurmaCodigo && localStorage.getItem('lista-oferta-disciplina')) && (
                  <Button label="Colar disciplinas" icon="pi pi-save" className="p-button w-auto p-button-info" onClick={colar} type='button' />
                )}
                <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
              </div>
            )}

          </div>
        </>
      )}
    </form >

  );
};

export default OfertaDisciplinaForm;
