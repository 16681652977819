import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDialog, SCDropdown } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { useAuth } from '../providers/auth';
import HistoricoEscolarForm from './HistoricoEscolarForm';
import IHistoricoEscolar from '../interfaces/IHistoricoEscolar';


const HistoricoEscolar = () => {
  const toast = useToast();
  const tarefa = 'HistoricoEscolar';

  let defaultValues: IHistoricoEscolar = {
    HistoricoId: 0,
    HistoricoAno: '',
    HistoricoSequencia: '',
    Matricula: 0,
    SequenciaImpressao: 0,
    CursoId: 0,
    NivelSequenciaId: 0,
    Baseado: 0,
    Instituicao: '',
    Localizacao: '',
    CargaHorariaTotal: 0,
    EducacaoFisicaIsento: false,
    EducacaoFisicaAlinea: '',
    EducacaoFisicaLei: '',
    EducacaoFisicaCargaHoraria: 0,
    EducacaoFisicaFrequencia: 0,
    EducacaoFisicaMedia: 0,
    EducacaoFisicaFaltas: 0,
    Situacao: '',
    GlobalFaltas: 0,
    GlobalNotas: 0,
    Observacao: '',
    DiasLetivos: 0,
    TipoGeracao: '',
    LancamentoData: new Date(''),
    LancamentoHora: '',
    LancamentoOperador: '',
    LancamentoTipo: 0,
    AtualizacaoData: undefined,
    AtualizacaoHora: '',
    AtualizacaoOperador: '',
    FrequenciaAnual: 0,
    FrequenciaAnualImprimir: false,
    HistoricoDisciplina: {
      HistoricoDisciplinaId: 0,
      HistoricoId: 0,
      DisciplinaId: 0,
      Media: '',
      Faltas: 0,
      CargaHoraria: 0,
      Tipo: '',
      Status: '',
      Credito: 0,
      Ordem: 0,
    },
  };

  const { periodoSelecionado } = useAuth();
  const [historicos, setHistoricos] = useState<any>(null);
  const [historico, setHistorico] = useState<any>(defaultValues);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const [filter, setFilter] = useState<any>(null);
  const [alunos, setAlunos] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [allVisitantes, setAllVisitantes] = useState<any>(null);

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, getValues, watch, setValue } = methods;

  const watchAlunos = watch('Matricula')

  const load = async () => {
    const result = await api.get('historicoEscolar');

    if (result.data) {
      console.log('HISTORICO', result.data)
      setHistoricos(result.data);
    } else {
      setHistoricos([]);
    }

    const alunosHistorico = await api.get('historicoEscolar/list');

    if (alunosHistorico.data) {
      setAlunos(alunosHistorico.data)
    } else {
      setAlunos([])
    }
  }

  useEffect(() => {
    load();
  }, []);

  // useEffect(() => {
  //     applyFilters();
  // }, [watchNivelSequencia, watchTurno, watchAtendidos, watchAgendados]);

  const openNew = () => {
    setHistorico(defaultValues)
    setShowDialog(true);
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const editar = (item: any) => {
    setHistorico(item)
    console.log(item)
    setShowDialog(true);
  };

  const confirmDelete = (item: any) => {
    setHistorico(item);
    setShowDelete(true);
    setShowDialog(true);
  };

  const deleteHistorico = async () => {
    try {
      const resp = await api.delete('/historicoEscolar', { params: { HistoricoId: historico.HistoricoId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setShowDelete(false);
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
  }

  const filtroAlunos = async () => {
    let result;

    if (watchAlunos) {
      result = await api.get('historicoEscolar', {
        params: {
          Matricula: watchAlunos,
        }
      });
    } else {
      result = await api.get('historicoEscolar');
    }

    if (result.data) {
      setHistoricos(result.data)
    }
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Histórico Escolar</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-8">
                <SCDropdown
                  options={alunos}
                  optionLabel='Nome'
                  optionValue='Matricula'
                  name="Aluno"
                  label='Nome do Aluno'
                  value={watchAlunos}
                  onChange={(e) => setValue('Matricula', e.target.value)}
                  placeholder='Selecione o Aluno'
                  style={{ width: '100%' }}
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={filtroAlunos} />
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={historicos}
              //onSelectionChange={(e) => setVisitanteSelecionado(e.value)}
              dataKey="HistoricoId"
              paginator
              rows={20}
              rowsPerPageOptions={[10, 20, 40]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={filter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="Nome" header="Nome" sortable style={{ width: '30%' }} />
              <Column field="HistoricoAno" header="Ano" sortable style={{ width: 90 }} />
              <Column field="HistoricoSequencia" header="Sequência" sortable align='center' style={{ width: 90 }} />
              <Column field="Matricula" header="Matrícula" sortable style={{ width: 90 }} />
              <Column sortable header="Ordem de Impressão" field='SequenciaImpressao' align='center' style={{ width: 120 }} />
              <Column sortable header="Nivel Escolaridade" align='center' body={(rowData: any) => {
                return (
                  <span>{rowData.Nivel} - {rowData.Sequencia} - {rowData.NivelDescricao}</span>
                );
              }} style={{ width: '30%' }}
              />

              <Column sortable header="Tipo de Lançamento" align='center' body={(rowData: any) => {
                return (
                  rowData.LancamentoTipo == 1 ? 'Global' : 'Detalhado'
                );
              }} style={{ width: '10%' }}
              />

              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 80 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={showDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${historico.HistoricoId == 0 ? 'Cadastrando ' : (showDelete ? 'Excluíndo' : 'Alterando')} Histórico Escolar`}
            modal className="p-fluid" onHide={hideDialog}>
            <HistoricoEscolarForm
              historico={historico}
              setShowDialog={setShowDialog}
              deletar={deleteHistorico}
              reload={load}
              showDelete={showDelete}
              setShowDelete={setShowDelete}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default HistoricoEscolar;
