import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, SITUACAO_FINAL_ALUNO } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCButton, SCCheckbox, SCDropdown, SCInputNumber } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { format } from 'date-fns';
import Loading from '../components/Loading';
import { Divider } from 'primereact/divider';


const ConselhoClasseEdInfantil = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'NotasTurma';

  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [alunos, setAlunos] = useState<any>(null);
  const [turmas, setTurmas] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [habilitaMarcarTodos, setHabilitahabilitaMarcarTodos] = useState(true);
  const [habilitaAcao, setHabilitaAcao] = useState(true);
  const dt = useRef<any>(null);

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  const watchCodigoTurma = watch('Codigo');
  const watchAplicaFrequencia = watch('AplicarFrequenciaTodos')
  const watchAplicaSituacaoFinal = watch('AplicarSituacaoFinal')

  const load = async () => {
    const { data } = await api.get('/turmas', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
      }
    })

    if (data.length > 0) {
      const filtraTurmasInfantil = data.filter((turma: any) => turma.Nivel === "I")

      if (filtraTurmasInfantil) {
        setTurmas(filtraTurmasInfantil)
      } else {
        setTurmas([])
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  const handleTurmaChange = async (value: any) => {
    setLoading(true)
    const alunosTurma = await api.get('/alunosPeriodo/listaAlunosTurma', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Alunos: 1,
        TurmaCodigo: value,
        SituacaoAcademica: 'M',
      }
    })

    if (alunosTurma.data.length > 0) {
      setHabilitahabilitaMarcarTodos(false)
      setHabilitaAcao(false)
      setAlunos(alunosTurma.data)
    } else {
      setAlunos([])
      setHabilitaAcao(true)
    }

    setLoading(false)
  }

  const aplicarParaTodos = () => {
    const aplicando = alunos.map((aluno: any) => ({
      ...aluno,
      ConselhoClasseFrequencia: watchAplicaFrequencia || aluno.ConselhoClasseFrequencia,
      SituacaoFinal: watchAplicaSituacaoFinal || aluno.SituacaoFinal,
      Selecionado: 1
    }));

    setAlunos(aplicando);
  };

  const handleDropdownSituacaoChange = (e: any, rowIndex: number) => {
    const { value } = e.target;
    const updatedData = [...alunos];
    updatedData[rowIndex] = { ...updatedData[rowIndex], SituacaoFinal: value };
    setAlunos(updatedData);
  };

  const onEditorValueChange = (props: any, value: any) => {
    let updatedAlunos = [...alunos];
    updatedAlunos[props.rowIndex][props.field] = value;
    setAlunos(updatedAlunos);
  };

  const bodyTemplate = (rowData: any, field: any) => {
    return inputTextEditor({ rowData, field, rowIndex: alunos.indexOf(rowData) });
  };

  const inputTextEditor = (props: any) => {
    const field = props.field;
    const rowIndex = props.rowIndex;

    const handleBlur = (e: any) => {
      const value = e.target.value ? e.target.value.replace(',', '.') : null;
      onEditorValueChange({ field, rowIndex }, value);
    };

    const handleControl = (e: any) => {
      const charCode = e.charCode;
      const newValue = e.target.value + String.fromCharCode(charCode);

      if (!/^\d*\,?\d*$/.test(newValue)) {
        e.preventDefault();
      }
    };

    if (!props.rowData) {
      return null;
    }

    return (
      <InputText
        // value={props.rowData.ConselhoClasseFrequencia}
        type="number"
        maxLength={4}
        value={props.rowData.ConselhoClasseFrequencia}
        onChange={(e) => onEditorValueChange(props, e.target.value)}
        onBlur={handleBlur}
        onKeyPress={handleControl}
        style={{ maxWidth: 50 }}
      />
    );
  };

  const situacaoTemplate = (rowData: any, options: any) => {
    const { rowIndex } = options;
    return (
      <div className="col-12 flex justify-content-center">
        <SCDropdown
          name='sit'
          value={rowData.SituacaoFinal}
          onChange={(e) => handleDropdownSituacaoChange(e, rowIndex)}
          options={SITUACAO_FINAL_ALUNO}
          optionLabel='descricao'
          style={{ width: 180 }}
        />
      </div>
    );
  };

  const onSubmit = async () => {

    // Selecionando só os marcados
    const alunosSelecionados = alunos.filter((aluno: any) => aluno.Selecionado == 1);

    // Verificando se há algum marcado
    if (alunosSelecionados.length === 0) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Marque pelo menos um aluno' });
      return false;
    }

    // Tranformando o campo frenquência em numérico
    const updatedAlunos = alunosSelecionados.map((aluno: any) => ({ ...aluno, ConselhoClasseFrequencia: aluno.ConselhoClasseFrequencia ? parseFloat(aluno.ConselhoClasseFrequencia) : null }));

    // Verificar se todos os alunos têm ConselhoClasseFrequencia preenchido
    const validaFrequencia = updatedAlunos.some((aluno: any) => aluno.ConselhoClasseFrequencia === null || aluno.ConselhoClasseFrequencia === undefined);

    if (validaFrequencia) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Campo FREQUÊNCIA é obrigatório para todos os alunos!' });
      return false;
    }

    // Verificar se todos os alunos têm SituacaoFinal preenchido
    const validaSituacao = updatedAlunos.some((aluno: any) => aluno.SituacaoFinal === null || aluno.SituacaoFinal === undefined);

    if (validaSituacao) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Campo SITUAÇÃO FINAL é obrigatório para todos os alunos!' });
      return false;
    }

    try {
      setLoading(true)
      const arrayUpdates = alunosSelecionados.map((item: any) => ({
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: item.Matricula,
        ConselhoClasseFrequencia: item.ConselhoClasseFrequencia,
        SituacaoFinal: item.SituacaoFinal,
        ConselhoClasseOperador: usuario?.Apelido,
        ConselhoClasseData: new Date(),
        ConselhoClasseHora: format(new Date(), 'HH:mm'),
      }));

      // Enviar o array inteiro para a API
      await api.put('/alunosPeriodo/updateSituacao', arrayUpdates);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setValue('Codigo', null)
      setSelectAllChecked(false)
      setHabilitahabilitaMarcarTodos(true)
      setAlunos([])
      setLoading(false)
      setHabilitaAcao(true)
      setValue('AplicarFrequenciaTodos', null)
      setValue('AplicarSituacaoFinal', null)

    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const accept = async () => {
    const alunosSelecionados = alunos.filter((aluno: any) => aluno.Selecionado == 1)

    if (alunosSelecionados.length === 0) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Marque pelo menos um aluno para limpar o lançamento' });
      return false;
    }

    try {
      setLoading(true)
      const arrayUpdates = alunosSelecionados.map((item: any) => ({
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        Matricula: item.Matricula,
        ConselhoClasseFrequencia: null,
        SituacaoFinal: null,
        ConselhoClasseOperador: usuario?.Apelido,
        ConselhoClasseData: new Date(),
        ConselhoClasseHora: format(new Date(), 'HH:mm'),
      }));

      await api.put('/alunosPeriodo/updateSituacao', arrayUpdates);

      setLoading(false)
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      setValue('Codigo', null)
      setSelectAllChecked(false)
      setHabilitahabilitaMarcarTodos(true)
      setAlunos([])
      setLoading(false)
      setHabilitaAcao(true)

    } catch (e: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  }

  const confirm = () => {
    confirmDialog({
      message: (<div style={{ fontSize: '16px' }} className='col-12'>
        <div>
          Essa operação irá LIMPAR todos os registros de lançamento do conselho de classe dessa seleção.
        </div>
        <div className='pt-2'>
          Essa operação é IRREVERSÍVEL, ou seja, uma vez confirmada não poderá reverter.
        </div>
        <div className='pt-2'>
          VOCÊ TEM CERTEZA QUE QUER CONTINUAR?
        </div>
      </div>),
      header: 'Atenção!',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept,
      style: { maxWidth: '600px', width: '80%' }
    });
  };

  const handleCheckboxChange = (rowData: any, checked: boolean) => {
    const updatedAlunos = alunos.map((item: any) => {
      if (item.Matricula === rowData.Matricula) {
        return { ...item, Selecionado: checked ? 1 : 0 };
      }
      return item;
    });
    setAlunos(updatedAlunos);
  };

  const handleSelectAllChange = (event: any) => {
    const checked = event.target.checked;
    const updatedAlunos = alunos.map((item: any) => ({
      ...item,
      Selecionado: checked ? 1 : 0
    }));
    setAlunos(updatedAlunos);
    setSelectAllChecked(checked);
  };

  useEffect(() => {
    console.log(watchAplicaFrequencia)
  }, [watchAplicaFrequencia])


  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Conselho de Classe (Educação Infantil)</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <div className='grid pt-2 flex justify-content-center align-items-center'>
            <div className="col-12 md:col-6">
              <SCDropdown
                name="Codigo"
                options={turmas}
                optionLabel="TurmaDescricao"
                optionValue='Codigo'
                placeholder='Selecione a Turma'
                label='Turma'
                style={{ width: '100%' }}
                value={watchCodigoTurma}
                onChange={(e) => {
                  setValue('Codigo', e.target.value);
                  handleTurmaChange(e.target.value)
                }}
                required
              />
            </div>
          </div>

          <Divider />

          <div className="col-12 flex flex-column justify-content-center align-items-center">
            <span style={{ fontWeight: 'bold' }}>Ação em lote</span>
            <div className="col-12 grid pt-3 flex justify-content-center align-items-center">
              <div className="col-12 md:col-1">
                <SCInputNumber
                  control={control}
                  errors={errors}
                  name="AplicarFrequenciaTodos"
                  label="Frequência %"
                  style={{ width: '100%' }}
                  disabled={habilitaAcao}
                />
              </div>

              <div className="col-12 md:col-3">
                <SCDropdown
                  name="AplicarSituacaoFinal"
                  value={watchAplicaSituacaoFinal}
                  label="Situação Final"
                  onChange={(e: any) => setValue('AplicarSituacaoFinal', e.target.value)}
                  options={SITUACAO_FINAL_ALUNO}
                  optionLabel="descricao"
                  style={{ width: '100%' }}
                  disabled={habilitaAcao}
                />
              </div>

              <div className="col-12 md:col-2">
                <SCButton
                  name="AplicarSituacaoFinal"
                  label="Aplicar para Todos"
                  style={{ width: '100%', marginTop: 15 }}
                  disabled={habilitaAcao}
                  onClick={aplicarParaTodos}
                />
              </div>
            </div>
          </div>


          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              dataKey="id"
              value={alunos}
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              emptyMessage="Selecione uma avaliação."
              editMode='cell'
              responsiveLayout="scroll"
              className='pt-3'
              showGridlines
            >


              <Column field="NumeroOrdem" header="Nº Ordem" sortable headerStyle={{ width: 130 }} />
              <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: 100 }} />
              <Column field="Nome" header="Nome" sortable headerStyle={{ width: '40%' }} />
              <Column field='ConselhoClasseFrequencia' header="Frequência (%)" align='center' headerStyle={{ width: 120 }} body={(rowData) => bodyTemplate(rowData, 'ConselhoClasseFrequencia')} />
              <Column field="SituacaoFinal" alignHeader='center' align='center' header="Situação Final" body={situacaoTemplate} />
              <Column
                header={
                  <div>
                    <SCCheckbox
                      label='Marcar Todos'
                      name='selecionarTodos'
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                      disabled={habilitaMarcarTodos}
                    />
                  </div>
                }
                body={(rowData: any) => (
                  <div className="col-12 flex justify-content-center">
                    <SCCheckbox
                      key={rowData.Matricula}
                      checked={rowData.Selecionado === 1}
                      onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                      id={rowData.Matricula}
                      name="Selecionado"
                      value={rowData.Selecionado === 1}
                    />
                  </div>
                )}
                alignHeader='center'
                align='center'
              />
            </DataTable>
          )}
          <div className='grid flex justify-content-center mt-2'>
            <div className='col-6 md:col-2 flex justify-content-start mt-2'>
              <Button label='Limpar Lançamentos' className="p-button-danger p-button-outlined" type='button' style={{ width: '100%' }} onClick={confirm} />
            </div>
            <div className='col-12 md:col-8 pt-4 flex justify-content-center align-items-center'></div>
            <div className='col-6 md:col-2 flex justify-content-end mt-2 '>
              <Button label='Gravar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ConselhoClasseEdInfantil;
