import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SCAutoComplete, SCButton, SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputNumber, SCInputText, SCInputTextarea } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import IVisitantes from '../interfaces/IVisitantes';
import util from '../utilities/util';
import { Button } from 'primereact/button';
import { ALERTAS, TURNOS, PARENTESCO, TIPO_FORMA_PAGAMENTO } from '../utilities/constantes';
import { TabPanel, TabView } from 'primereact/tabview';
import FormEndereco from '../components/FormEndereco';
import { useAuth } from '../providers/auth';
import IApagar from '../interfaces/IApagar';
import { format, getHours, getYear } from 'date-fns';
import SelectCredor from '../components/SelectCredor';


interface Props {
  contaApagar?: IApagar;
  setAPagarDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteAPagar: boolean;
  setDeleteAPagar(param: boolean): void;
}

const ContasAPagarForm: React.FC<Props> = ({ setAPagarDialog, contaApagar, reload, deletar, deleteAPagar, setDeleteAPagar }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente

  const tarefa = 'VisitantesForm';
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();

  let defaultValues: IApagar = {
    ApagarId: undefined,
    AtualizacaoData: new Date(''),
    AtualizacaoHora: new Date(''),
    AtualizacaoOperador: '',
    CartoesId: null,
    CentroCustoId: null,
    ContaDescontoId: null,
    ContaJurosPagamento: null,
    ContaMultaPagamento: null,
    ContaValorId: null,
    CredorId: '',
    DataBomPara: null,
    DataPagamento: null,
    DataProgramada: null,
    DataVencimento: null,
    EmpresaId: periodoSelecionado?.EmpresaId,
    FonteId: 0,
    FormasPagamentoId: '',
    LancamentoData: new Date(''),
    LancamentoHora: new Date(''),
    LancamentoOperador: '',
    NumeroDocumento: '',
    NumeroDocumentoPagamento: '',
    Observacao: '',
    Referente: '',
    Valor: 0,
    ValorDesconto: 0,
    ValorDescontoPagamento: 0,
    ValorDescontoProgramado: 0,
    ValorJurosPagamento: 0,
    ValorJurosProgramado: 0,
    ValorMultaPagamento: 0,
    ValorMultaProgramado: 0,
    ValorPagamento: 0,
    ValorProgramado: 0,
    DataInicialVencimento: null,
    DataFinalVencimento: null,
    DataInicialPagamento: null,
    DataFinalPagamento: null,
    Nome: '',
    FlgMeuCaixa: 0,
    CodigosEstornoId: undefined,
    Status: '',
    CartaoInstitucionalId: 0,
    FaturaCartao: false,
    LiquidaFaturaId: 0,
  }

  const methods = useForm({ defaultValues })
  const { handleSubmit, control, formState: { errors }, getValues, reset, watch, setValue } = methods;
  const [editar, setEditar] = useState<boolean>(true);
  const [fontes, setFontes] = useState<any>(null);
  const [credor, setCredor] = useState<any>(null);
  const [centroCusto, setCentroCusto] = useState<any>(null);
  const [contas, setContas] = useState<any>(null);
  const [formasPag, setFormasPag] = useState<any>(null);
  const [desabilitaNumeroDocumentoPag, setDesabilitaNumeroDocumentoPag] = useState<boolean>(false);
  const [validaCampos, setValidaCampos] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCheque, setIsCheque] = useState<boolean>(false);
  const [isCartao, setIsCartao] = useState<boolean>(false);
  const [isDataPagamentoLoaded, setIsDataPagamentoLoaded] = useState(false);
  const [desabilitaCampos, setDesabilitaCampos] = useState<boolean>(false);
  const [codigoEstorno, setCodigoEstorno] = useState<any>(null);
  const [valorApagarProgramado, setValorApagarProgramado] = useState<number>(0);
  const [valorApagarVencimento, setValorApagarVencimento] = useState<number>(0);
  const [descontoConcedido, setDescontoConcedido] = useState<number>(0);
  const [cartoes, setCartoes] = useState<any>();
  const [formaDePagamentoDisabled, setFormaPagamentoDisabled] = useState<boolean>(false);
  const [fonteDisabled, setFonteDisabled] = useState<boolean>(false);
  const [bloqueiaGrupoPagamento, setBloqueiaGrupoPagamento] = useState<boolean>(false);
  const [cartaoInstitucional, setCartaoInstitucional] = useState<any>();
  const [NFatura, setNFatura] = useState<any>();
  const [lancamentoData, setLancamentoData] = useState<any>();
  const [lancamentoHora, setLancamentoHora] = useState<any>();
  const [lancamentoOperador, setLancamentoOperador] = useState<any>();
  const [todasFormasPag, setTodasFormasPag] = useState<any>();

  const loadFontes = async () => {
    try {
      const resultFontes = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });

      if (resultFontes.data) {
        setFontes(resultFontes.data);
      } else {
        setFontes([]);
      }
    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  }

  const loadCredor = async () => {
    try {
      let result;
      result = await api.get('/credor/list')

      if (result.data) {
        setCredor(result.data)
      } else {
        setCredor([])
      }
    } catch (error) {
      console.error('Erro ao carregar o Credor:', error);
      setCredor([])
    }
  }

  const loadCentroCusto = async () => {
    try {
      const result = await api.get('/centroCusto/combo');

      if (result.data) {
        const CodigoDescricao = result.data.map((item: any) => ({
          CentroCustoId: item.CentroCustoId,
          CodDesc: `${item.Codigo} - ${item.Descricao}`
        }));

        setCentroCusto(CodigoDescricao);
      } else {
        setCentroCusto([]);
      }
    } catch (error) {
      console.error('Erro ao carregar os Centros de Custo:', error);
      setCentroCusto([]);
    }
  };

  const loadConta = async () => {
    try {
      const resultContas = await api.get('/planoContas/list', { params: { Tipo: 'SAÍDA' } });
      if (resultContas.data) {
        setContas(resultContas.data);
      } else {
        setContas([]);
      }

    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      setContas([]);
    }
  };

  const loadFormasPagamento = async () => {
    try {
      const resultFormasPag = await api.get('/formasPagamento/combo');
      if (resultFormasPag.data) {
        const filteredFormasPag = resultFormasPag.data.filter((item: any) => item.Tipo != '4');
        setFormasPag(filteredFormasPag);
        setTodasFormasPag(resultFormasPag.data);
      } else {
        setFormasPag([]);
      }

    } catch (error) {
      console.error('Erro ao carregar as Formas de Pagamento:', error);
      setFormasPag([]);
    }
  };

  const loadCodigoEstorno = async () => {
    try {
      const resultCodigo = await api.get('/codigosestorno/combo');

      if (resultCodigo.data) {
        setCodigoEstorno(resultCodigo.data);
      } else {
        setCodigoEstorno([]);
      }
    } catch (error) {
      console.error('Erro ao carregar os Códigos de Estorno:', error);
      setCodigoEstorno([]);
    }
  }

  const loadCartoes = async () => {
    try {
      const result = await api.get('/cartoes/listCartaoInstitucional', { params: { Institucional: 1 } });
      if (result.data) {
        setCartoes(result.data);
      } else {
        setCartoes([])
      }

      if (contaApagar?.CartaoInstitucionalId !== 0) {
        if (contaApagar?.CartaoInstitucionalId) {
          const result1 = await api.get('/cartoes', { params: { CartoesId: contaApagar?.CartaoInstitucionalId } });
          if (result1.data[0].Tipo == 'D') {
            setBloqueiaGrupoPagamento(false)
            setFormaPagamentoDisabled(true)
            setFonteDisabled(true)

          } else if (result.data[0].Tipo == 'C') {
            setFonteDisabled(false)
            setFormaPagamentoDisabled(false)
            setBloqueiaGrupoPagamento(true)
          }
        }
      }
    } catch (error) {
      console.error('Erro ao carregar os Códigos de Estorno:', error);
      setCodigoEstorno([]);
    }
  }

  let horaGravacao = format(new Date(), 'HH:mm')

  const salvaDiarioFinanceiro = async (data: any, novoArray: any) => {

    const isMeuCaixa = fontes.find((f: any) => f.FonteId === data.FonteId)?.Codigo

    let resp;
    let dadosDiarioFinanceiro = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      Ano: periodoSelecionado?.Ano,
      Sequencial: periodoSelecionado?.Sequencial,
      LancamentoData: new Date(),
      LancamentoHora: horaGravacao,
      LancamentoOperador: usuario?.Apelido,
      Tarefa: 'APAGAR',
      Opcao: 'INC',
      DataPagamento: data.DataPagamento,
      DataBomPara: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
      DataDisponivel: data.DataBomPara ? data.DataBomPara : data.DataPagamento,
      Nome: data.Nome.toUpperCase(),
      Valor: deleteAPagar ? data.ValorPagamento * -1 : data.ValorPagamento,
      Referencia: data.Referente,
      NumeroRecibo: novoArray.ApagarId,
      FonteId: data.FonteId,
      FormasPagamentoId: data.FormasPagamentoId,
      ContaValorId: data.ContaValorId,
      ChequeNumero: isCheque ? data.NumeroDocumentoPagamento : null,
      CartaoNumero: isCartao ? data.NumeroDocumentoPagamento : null,
      FlgMeuCaixa: isMeuCaixa === 'MEU CAIXA' ? 1 : 0
    }

    try {
      resp = await api.post('/diariofinanceiro', dadosDiarioFinanceiro)
    } catch (error) {
      console.error('Erro ao salvar no Diário Financeiro:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Diário Financeiro',
      });
    }
    return true;
  }

  const salvaEnceramentoCaixa = async (data: any) => {

    let dadosEncerramentoCaixa = {
      EmpresaId: periodoSelecionado?.EmpresaId,
      CaixaData: new Date(),
      CaixaHora: horaGravacao,
      CaixaOperador: usuario?.Apelido,
      CaixaValor: data.ValorPagamento * -1
    }

    try {
      await api.post('/gravacaixa', dadosEncerramentoCaixa)
    } catch (error) {
      console.error('Erro ao salvar no Encerramento de Caixa:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao salvar o registro no Encerramento de Caixa',
      });
    }
  }

  useEffect(() => {
    if (contaApagar?.LiquidaFaturaId !== undefined) {
      if (contaApagar?.LiquidaFaturaId !== 0) {
        carregaFatura();
      }
    }
    loadFontes();
    loadCredor();
    loadCentroCusto();
    loadConta();
    loadFormasPagamento();
    loadCartoes();
    setDesabilitaNumeroDocumentoPag(true)
    reset(contaApagar);
  }, []);

  const carregaFatura = async () => {
    const fatura = await api.get('/liquidaFatura', {
      params: {
        LiquidaFaturaId: contaApagar?.LiquidaFaturaId
      }
    });
    if (fatura.data) {
      setNFatura(fatura.data.LiquidaFaturaId);
      setLancamentoData(fatura.data.LancamentoDataString);
      setLancamentoHora(fatura.data.LancamentoHora);
      setLancamentoOperador(fatura.data.LancamentoOperador);
    }
  }

  useEffect(() => {
    if (deleteAPagar) {
      loadCodigoEstorno()
    }
  }, []);

  const cancelar = (e: any) => {
    setDeleteAPagar(false);
    setAPagarDialog(false);
    reset();
  }

  const watchFormaPagamento = watch('FormasPagamentoId');
  const watchDataPagamento = watch('DataPagamento');

  useEffect(() => {
    if (watchDataPagamento !== undefined) {
      setIsDataPagamentoLoaded(true);
    }
  }, [watchDataPagamento]);

  useEffect(() => {
    if (!isDataPagamentoLoaded) return;

    if (contaApagar?.DataPagamento) {
      setDesabilitaCampos(true);
    } else {
      setDesabilitaCampos(false);
    }

    if (watchDataPagamento) {
      setValidaCampos(true);
      setValue('DataBomPara', watchDataPagamento)

      if (contaApagar?.ValorDescontoPagamento == 0) {
        setValue('ValorPagamento', valorApagarProgramado)
      }

      if (watchFormaPagamento && formasPag) {
        const filtroFormasPagamento = formasPag.find((fp: any) => fp.FormasPagamentoId === watchFormaPagamento)?.Tipo;

        if (['2', '3'].includes(filtroFormasPagamento)) {
          setIsCheque(filtroFormasPagamento === '2');
          setIsCartao(filtroFormasPagamento === '3');
          setDesabilitaNumeroDocumentoPag(false);
        }
        // else {
        //   setDesabilitaNumeroDocumentoPag(true);
        //   setValue('NumeroDocumentoPagamento', '');
        // }
      }

    } else {
      setValidaCampos(false);
      // setDesabilitaNumeroDocumentoPag(true);
      setValue('FormasPagamentoId', null);
      // setValue('NumeroDocumentoPagamento', '');
      setValue('FonteId', null);
      setValue('DataBomPara', null);
      setValue('ValorPagamento', 0);
    }
    calculaValorProgramado();
    calculaValorVencimento();
  }, [watchDataPagamento, watchFormaPagamento, formasPag, isDataPagamentoLoaded]);

  // useEffect(() => {
  //   if (watchDataPagamento) {
  //     if (formasPag) {
  //       let filtraDinheiro = formasPag.find((forma: any) => forma.Tipo == 1)?.FormasPagamentoId
  //       setValue('FormasPagamentoId', filtraDinheiro)
  //     }
  //   }
  // }, [watchDataPagamento])

  const watchValorProgramado = watch('ValorProgramado')
  const watchValorDescontoProgramado = watch('ValorDescontoProgramado')
  const watchValorMultaProgramado = watch('ValorMultaProgramado')
  const watchValorJurosProgramado = watch('ValorJurosProgramado')
  const watchValorPago = watch('ValorPagamento')
  const watchValorVencimento = watch('Valor')
  const watchValorDesconto = watch('ValorDesconto')
  const watchCartaoInstitucional = watch('CartaoInstitucionalId')

  const watchCentroCustoId = watch('CentroCustoId');

  useEffect(() => {
    console.log('watchCentroCustoId', watchCentroCustoId);
  }, [watchCentroCustoId])

  let calculoProgramado: number;
  let calculoVencimento: number;

  calculoProgramado = (watchValorProgramado + watchValorMultaProgramado + watchValorJurosProgramado) - watchValorDescontoProgramado
  calculoVencimento = watchValorVencimento - watchValorDesconto

  const cartaoHandler = async (value: any) => {
    setLoading(true)
    if (value) {
      // const result = await api.get('/cartoes', { params: { CartoesId: value } });
      const filterCartoes = cartoes.filter((item: any) => item.CartoesId == value)
      if (filterCartoes) {
        setValue('FonteId', filterCartoes[0].FonteSugeridaId)
        if (filterCartoes[0].Tipo == 'C') {
          setFormaPagamentoDisabled(true)
          setFormasPag(todasFormasPag);
          setValue('FonteId', undefined)
          setDesabilitaNumeroDocumentoPag(false)
          // const result1 = await api.get('/formasPagamento', { params: { Codigo: '99' } });
          const filterFormPag = todasFormasPag.filter((item: any) => item.Codigo == '99')
          if (filterFormPag[0]) {
            setValue('FormasPagamentoId', filterFormPag[0].FormasPagamentoId)
          }
        } else if (filterCartoes[0].Tipo == 'D') {
          setDesabilitaNumeroDocumentoPag(true)
          setFormaPagamentoDisabled(false)
          const filteredFormasPag = todasFormasPag.filter((item: any) => item.Tipo != '4');
          setFormasPag(filteredFormasPag);
        }
        setValue('DataPagamento', new Date())
        setFonteDisabled(true)
        setBloqueiaGrupoPagamento(false)
        setCartaoInstitucional(filterCartoes[0])
      }
    } else {
      setFormaPagamentoDisabled(false)
      setDesabilitaNumeroDocumentoPag(true)
      setFonteDisabled(false)
      const filterFormPag = todasFormasPag.filter((item: any) => item.Tipo == '1')
      if (filterFormPag?.[0]) {
        setValue('FormasPagamentoId', filterFormPag[0].FormasPagamentoId)
      }
      const filterFontes = fontes.filter((item: any) => item.Codigo == 'MEU CAIXA')
      if (filterFontes?.[0]) {
        setValue('FonteId', filterFontes[0].FonteId)
      }
    }
    setLoading(false)
  }

  const onSubmit = async (data: any) => {

    if (watchDataPagamento) {
      let dataFormat = format(new Date(watchDataPagamento), 'yyyy-MM-dd')

      try {
        const result = await api.get('/consultaEncerramento', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
            Data: dataFormat,
            Operador: usuario?.Apelido,
            Acao: 'F'
          }
        })

        let dataFormatada = format(new Date(watchDataPagamento), 'dd-MM-yyyy')

        if (result.data.length > 0) {
          toast.showToast({
            severity: 'error',
            summary: 'Atenção',
            detail: `O Caixa está encerrado para a data: ${dataFormatada}`,
          });

          return false;
        }
      } catch (error) {
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Erro na consulta',
        });
      }
    }

    setLoading(true);

    const usuarioApelido = usuario?.Apelido;
    const currentDateTime = new Date();
    const formattedTime = format(currentDateTime, 'HH:mm');

    delete data.DataVencimentoString
    delete data.DataPagamentoString
    delete data.DataProgramadaString

    if (!data.ContaValorId) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Conta é obrigatório',
      });

      return false
    }

    if (data.Valor == 0) {
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Valor é obrigatório',
      });

      return false
    }

    const isMeuCaixa = fontes.find((f: any) => f.FonteId === data.FonteId)?.Codigo

    data.FlgMeuCaixa = isMeuCaixa === 'MEU CAIXA' ? 1 : 0

    if (data.ApagarId === undefined) {
      data.LancamentoData = currentDateTime;
      data.LancamentoHora = horaGravacao;
      data.LancamentoOperador = usuarioApelido;
      data.AtualizacaoData = null;
      data.AtualizacaoHora = null;
      data.AtualizacaoOperador = null;
    } else {
      data.AtualizacaoData = currentDateTime;
      data.AtualizacaoHora = horaGravacao;
      data.AtualizacaoOperador = usuarioApelido;
    }

    const credorEncontrado = credor.find((c: any) => c.Nome === data.Nome);
    const idCredor = credorEncontrado ? credorEncontrado.CredorId : undefined;

    const { CartaoInstitucional, DataFinalPagamento, DataInicialPagamento, DataInicialVencimento, DataFinalVencimento, Nome, ...novoArray } = data;
    delete novoArray.Status;

    console.log(cartaoInstitucional)

    try {
      console.log(data);
      if (watchCartaoInstitucional !== null) {
        if (cartaoInstitucional) {
          if (cartaoInstitucional.Tipo === 'C') {
            novoArray.FaturaCartao = true;
          }
        }
      }

      if (data.ApagarId === undefined) {
        if (!credorEncontrado) {
          const novoCredor = { Nome: data.Nome.toUpperCase() };
          const credorResponse = await api.post('/credor', novoCredor);
          novoArray.CredorId = credorResponse.data.id;
        }

        const apagarResponse = await api.post('/apagar', novoArray);

        if (apagarResponse.data) {
          const idAPagar = apagarResponse.data.id;
          const ano = new Date().getFullYear().toString().slice(-2);
          const numSequencial = idAPagar.toString().padStart(7, '0');
          novoArray.NumeroDocumento = `${ano}/${numSequencial}`;
          novoArray.ApagarId = idAPagar;

          await api.put('/apagar', novoArray);
        }

        if (data.DataPagamento) {
          await salvaDiarioFinanceiro(data, novoArray)
          await salvaEnceramentoCaixa(novoArray)
        }

      } else {

        if (!credorEncontrado) {
          const novoCredor = { Nome: data.Nome.toUpperCase() };
          const credorResponse = await api.post('/credor', novoCredor);
          novoArray.CredorId = credorResponse.data.id;

        }

        novoArray.CredorId = idCredor

        if (data.DataPagamento) {
          await salvaDiarioFinanceiro(data, novoArray)
          await salvaEnceramentoCaixa(novoArray)
        }

        await api.put('/apagar', novoArray);
      }

      toast.showToast({
        severity: 'success',
        summary: 'Atenção',
        detail: ALERTAS.registroGravado,
      });

    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      toast.showToast({
        severity: 'error',
        summary: 'Atenção',
        detail: 'Erro ao gravar o registro!' + error,
      });
    } finally {

      setLoading(false);
      setAPagarDialog(false);
      reload();

      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 500);
    }
  };

  const watchCodigoEstornoId = watch('CodigosEstornoId')

  const gravaCodigoEstorno = async () => {
    let resp;

    let dadosCodigoEstorno = {
      ApagarId: contaApagar?.ApagarId,
      CodigosEstornoId: watchCodigoEstornoId
    }

    resp = await api.put('/apagar/excluir', dadosCodigoEstorno);

    if (resp) {
      deletar()
    }
  }

  useEffect(() => {
    if (getValues('DataPagamento'))
      calculaDescontoConcedido();
    else
      setDescontoConcedido(0);
  }, [watchValorPago, watchDataPagamento]);

  const calculaValorVencimento = () => {
    let valorVencimento = getValues('Valor');
    let valorDesconto = getValues('ValorDesconto');
    let valorApagarVencimento = valorVencimento - valorDesconto

    if (contaApagar?.ValorProgramado == 0) {
      setValue('ValorProgramado', valorVencimento);
      calculaValorProgramado()
    }
    if (contaApagar?.ValorDescontoProgramado == 0) {
      setValue('ValorDescontoProgramado', valorDesconto);
      calculaValorProgramado()
    }

    setValorApagarVencimento(valorApagarVencimento)
  }

  const calculaValorProgramado = () => {
    let valorProgramado = getValues('ValorProgramado');
    let valorDescontoProgramado = getValues('ValorDescontoProgramado');
    let valorMultaProgramado = getValues('ValorMultaProgramado');
    let valorJurosProgramado = getValues('ValorJurosProgramado');

    let valorApagarProgramado = (valorProgramado + valorMultaProgramado + valorJurosProgramado) - valorDescontoProgramado

    setValorApagarProgramado(valorApagarProgramado)

    // if (contaApagar?.ValorDescontoPagamento == 0) {
    //   setValue('ValorPagamento', valorApagarProgramado)
    // }
  }

  const calculaDescontoConcedido = () => {
    let valorPago = getValues('ValorPagamento');

    let descontoConcedido = (valorPago > valorApagarVencimento) ? 0 : (valorApagarVencimento - valorPago);

    setDescontoConcedido(descontoConcedido)
    setValue('ValorDescontoPagamento', descontoConcedido);
  }

  const changeCalendar = (data: any) => {
    setValue('DataProgramada', data)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >

          <TabView renderActiveOnly={false}>
            <TabPanel header="Principal">
              <div className='grid' style={{ marginTop: -10 }}>
                <div className="col-12 md:col-2">
                  <SCInputText
                    control={control}
                    errors={errors}
                    name="NumeroDocumento"
                    label='Nº Documento'
                    maxLength={10}
                    disabled
                    style={{ color: "#000", fontWeight: 'bold', background: '#fff' }}
                  />
                </div>
              </div>
              <div className='grid'>
                <div className="col-12 md:col-4">
                  <SelectCredor
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    setError={methods.setError}
                    clearErrors={methods.clearErrors}
                    name="Nome"
                    label='Credor'
                    disabled={deleteAPagar || desabilitaCampos}
                    required={!deleteAPagar}
                  />
                </div>

                <div className="col-12 md:col-4">
                  <SCDropdown
                    control={control}
                    errors={errors}
                    name="CentroCustoId"
                    label='Centro de Custo'
                    options={centroCusto}
                    resetFilterOnHide
                    placeholder='Selecione'
                    filter={false}
                    optionLabel='CodDesc'
                    optionValue='CentroCustoId'
                    disabled={deleteAPagar || desabilitaCampos}
                    required={!deleteAPagar}                    
                  />
                </div>

                <div className="col-12 md:col-4">
                  <SCDropdown
                    control={control}
                    errors={errors}
                    name="ContaValorId"
                    label='Conta'
                    options={contas}
                    optionLabel='ContaDescricao'
                    optionValue='PlanoContaId'
                    disabled={deleteAPagar || desabilitaCampos}
                    required={!deleteAPagar}
                  />
                </div>
              </div>

              <SCFieldset legend='Vencimento' className='col-12 mt-1 p-0'>
                <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
                  <div className="col-12 md:col-2">
                    <SCCalendar
                      errors={errors}
                      label='Data'
                      control={control}
                      name='DataVencimento'
                      dateFormat='dd/mm/yy'
                      disabled={deleteAPagar || desabilitaCampos}
                      required={!deleteAPagar}
                      showIcon
                      onSelect={(e) => changeCalendar(e.value)}
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      control={control}
                      name='Valor'
                      errors={errors}
                      label='Valor'
                      disabled={deleteAPagar || desabilitaCampos}
                      min={0.1}
                      allowEmpty={false}
                      max={10000}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                      required={!deleteAPagar}
                      onBlur={calculaValorVencimento}
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      control={control}
                      name='ValorDesconto'
                      errors={errors}
                      label='Desconto'
                      disabled={deleteAPagar || desabilitaCampos}
                      min={0}
                      max={10000}
                      allowEmpty={false}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                      onBlur={calculaValorVencimento}
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      name='ValorApagarVencimento'
                      value={valorApagarVencimento}
                      label='Valor a Pagar'
                      disabled
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                    />
                  </div>
                </div>
              </SCFieldset>

              <SCFieldset legend='Programado' className='col-12 mt-2 p-0'>
                <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
                  <div className="col-12 md:col-2">
                    <SCCalendar
                      label='Data'
                      control={control}
                      name='DataProgramada'
                      dateFormat='dd/mm/yy'
                      disabled={deleteAPagar || desabilitaCampos}
                      showIcon
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      name='ValorProgramado'
                      control={control}
                      errors={errors}
                      label='Valor'
                      disabled={deleteAPagar || desabilitaCampos}
                      min={0}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      allowEmpty={false}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                      onBlur={calculaValorProgramado}
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      name='ValorDescontoProgramado'
                      errors={errors}
                      control={control}
                      label='Desconto'
                      disabled={deleteAPagar || desabilitaCampos}
                      min={0}
                      allowEmpty={false}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                      onBlur={calculaValorProgramado}
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      name='ValorMultaProgramado'
                      errors={errors}
                      control={control}
                      label='Multa'
                      disabled={deleteAPagar || desabilitaCampos}
                      min={0}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                      allowEmpty={false}
                      onBlur={calculaValorProgramado}
                    />
                  </div>

                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      name='ValorJurosProgramado'
                      errors={errors}
                      control={control}
                      label='Juros'
                      disabled={deleteAPagar || desabilitaCampos}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      min={0}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                      allowEmpty={false}
                      onBlur={calculaValorProgramado}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <SCInputNumber
                      name='ValorApagarProgramado'
                      value={valorApagarProgramado}
                      label='Valor a Pagar'
                      disabled
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      allowEmpty={false}
                      currency='BRL'
                      mode='currency'
                      locale='pt-BR'
                    />
                  </div>
                </div>
                <div className='grid'>

                  <div className="col-12 md:col-12">
                    <SCInputText
                      control={control}
                      errors={errors}
                      name="Referente"
                      label='Referente'
                      disabled={deleteAPagar || desabilitaCampos}
                      required={!deleteAPagar}
                      minLength={1}
                    />
                  </div>
                </div>
              </SCFieldset>

              <div className='flex'>
                <SCFieldset legend='Pagamento' className='col-8 mt-2 p-0'>
                  <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
                    <div className="col-12 md:col-3">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="CartaoInstitucionalId"
                        label='Cartão Institucional'
                        disabled={deleteAPagar || desabilitaCampos}
                        options={cartoes}
                        optionLabel='NomeCartao'
                        optionValue='CartoesId'
                        onChange={(e) => cartaoHandler(e.target.value)}
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <SCCalendar
                        label='Data'
                        errors={errors}
                        control={control}
                        name='DataPagamento'
                        dateFormat='dd/mm/yy'
                        disabled={deleteAPagar || desabilitaCampos || bloqueiaGrupoPagamento}
                        showIcon
                      />
                    </div>

                    <div className="col-12 md:col-3">
                      <SCInputNumber
                        name='ValorPagamento'
                        control={control}
                        errors={errors}
                        label='Valor Pago'
                        disabled={deleteAPagar || desabilitaCampos || bloqueiaGrupoPagamento}
                        min={0}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                        required={validaCampos}
                        allowEmpty={false}
                        onBlur={calculaDescontoConcedido}
                      />
                    </div>

                    <div className="col-12 md:col-3">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="FormasPagamentoId"
                        label='Forma de Pagamento'
                        options={formasPag}
                        optionLabel='Descricao'
                        optionValue='FormasPagamentoId'
                        disabled={deleteAPagar || desabilitaCampos || formaDePagamentoDisabled || bloqueiaGrupoPagamento}
                        required={validaCampos}
                      />
                    </div>

                  </div>
                  <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
                    <div className="col-12 md:col-3">
                      <SCInputText
                        control={control}
                        errors={errors}
                        name="NumeroDocumentoPagamento"
                        label='Número do Documento'
                        disabled={deleteAPagar || desabilitaNumeroDocumentoPag || desabilitaCampos || bloqueiaGrupoPagamento}
                        required={!desabilitaNumeroDocumentoPag}
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <SCDropdown
                        control={control}
                        errors={errors}
                        name="FonteId"
                        label='Fonte'
                        options={fontes}
                        optionLabel='Codigo'
                        optionValue='FonteId'
                        disabled={deleteAPagar || desabilitaCampos || fonteDisabled || bloqueiaGrupoPagamento}
                        required={validaCampos}
                      />
                    </div>

                    <div className="col-12 md:col-3">
                      <SCCalendar
                        errors={errors}
                        label='Bom Para'
                        control={control}
                        name='DataBomPara'
                        dateFormat='dd/mm/yy'
                        disabled={deleteAPagar || desabilitaCampos || bloqueiaGrupoPagamento}
                        showIcon
                        required={validaCampos}
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <SCInputNumber
                        name='DescontoConcedido'
                        value={descontoConcedido}
                        label='Desconto Concedido'
                        disabled
                        allowEmpty={false}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        currency='BRL'
                        mode='currency'
                        locale='pt-BR'
                      />
                    </div>
                  </div>
                </SCFieldset>
                {contaApagar?.FaturaCartao == true ?
                  <SCFieldset legend='Fatura' className='col-4 mt-2 p-0'>
                    {contaApagar?.LiquidaFaturaId != 0 ?
                      <>
                        <div className='grid'>
                          <div className='col-6'>
                            <SCInputText
                              control={control}
                              errors={errors}
                              name='LiquidaFaturaId'
                              disabled
                              value={NFatura}
                              label='Nº da Fatura'
                            />
                          </div>
                          <div className='col-6'>
                            <SCInputText
                              control={control}
                              errors={errors}
                              name='LancamentoDataString'
                              disabled
                              value={lancamentoData}
                              label='Data do Pagamento'
                            />
                          </div>
                        </div><div className='grid'>
                          <div className='col-6'>
                            <SCInputText
                              control={control}
                              errors={errors}
                              name='LancamentoHora'
                              disabled
                              value={lancamentoHora}
                              label='Hora do Pagamento'
                            />
                          </div>
                          <div className='col-6'>
                            <SCInputText
                              control={control}
                              errors={errors}
                              name='LancamentoOperador'
                              disabled
                              value={lancamentoOperador}
                              label='Pagamento efetuado por'
                            />
                          </div>
                        </div>
                      </>
                      :
                      <div className="md:col-12 ">
                        <div className=' p-4' style={{ backgroundColor: '#0772B3', color: 'white', alignItems: 'center' }}>
                          <b>Esse pagamento está condicionado à quitação da fatura do cartão!</b>
                        </div>
                      </div>
                    }
                  </SCFieldset> : null
                }
              </div>
            </TabPanel>

            <TabPanel header="Observações">
              <SCFieldset legend='Observação' className='col-12 mt-3'>
                <div className='grid'>
                  <div className="col-12">
                    <SCInputTextarea
                      style={{ minHeight: 300 }}
                      control={control}
                      errors={errors}
                      name="Observacao"
                      disabled={deleteAPagar}
                      rows={10}
                    />
                  </div>
                </div>
              </SCFieldset>
            </TabPanel>
          </TabView>

          {deleteAPagar ? (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />

              <div className="col-6">

                <div className='grid align-items-center justify-content-around'>
                  <span style={{ color: 'red', fontWeight: 'bold' }}>Informe o Código do Estorno</span>

                  <div className='col-12 md:col-7 p-0'>
                    <SCDropdown
                      control={control}
                      errors={errors}
                      name="CodigosEstornoId"
                      options={codigoEstorno}
                      optionLabel='Descricao'
                      optionValue='CodigosEstornoId'
                      required
                      showClear={false}
                    />
                  </div>
                </div>
              </div>
              <Button label="Confirmar Exclusão" icon="pi pi-check"
                className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
                onClick={gravaCodigoEstorno} type='button' disabled={watchCodigoEstornoId ? false : true} />
            </div>
          ) : (
            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />

              {!desabilitaCampos && (
                <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" />
              )}

            </div>
          )}

        </form>
      </FormProvider >
    </>
  );
};

export default ContasAPagarForm
