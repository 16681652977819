import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, TURNOS } from '../utilities/constantes';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCButton, SCCalendar, SCCheckbox, SCDialog, SCDropdown, SCFieldset, SCInputText, SCInputTextarea } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { useAuth } from '../providers/auth';


const HistoricoObservacao = () => {
  const toast = useToast();
  const tarefa = 'HistoricoObservacao';

  const { periodoSelecionado } = useAuth();
  const [observacoes, setObservacoes] = useState<any>(null);
  const [desabilitaEdit, setDesabilitaEdit] = useState<boolean>(true);
  const [historicoObs, setHistoricoObs] = useState<any>();

  const [filter, setFilter] = useState<any>(null);
  const [alunos, setAlunos] = useState<any>(null);
  const [nivelEscolaridade, setNivelEscolaridade] = useState<any>(null);
  const [curso, setCurso] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm();
  const { handleSubmit, control, formState: { errors }, getValues, watch, setValue } = methods;

  const watchAlunos = watch('Matricula')
  const watchNivelEscolaridade = watch('NivelEscolaridade')
  const watchCursoId = watch('CursoId')
  const watchObs = watch('HistoricoObs')

  const load = async () => {
    const result = await api.get('/historicoObservacao/list');
    if (result.data) {
      setObservacoes(result.data);
    } else {
      setObservacoes([]);
    }

    const combo = await api.get('/historicoObservacao/combo');
    if (combo.data) {
      setAlunos(combo.data)
    } else {
      setAlunos([])
    }

    // Carregando o dropdown Curso
    const dropCurso = await api.get('/curso');

    if (dropCurso.data) {
      setCurso(dropCurso.data || [])
    }
  }

  useEffect(() => {
    load();
  }, []);

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  const filtroAlunos = async () => {
    setLoading(true)
    setValue('HistoricoObs', '')
    let result;

    try {
      result = await api.get('/historicoObservacao/list', {
        params: {
          Matricula: watchAlunos,
          CursoId: watchCursoId
        }
      });

    } catch (error) {
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: "Erro na pesquisa" + error });
      setLoading(false)
    }

    if (result) {
      setObservacoes(result.data)
      setLoading(false)
    }

  }

  const gravar = async () => {
    setLoading(true)

    try {
      await api.put(`/historicoObservacao`, {
        HistoricoObservacaoId: historicoObs.HistoricoObservacaoId,
        HistoricoObs: watchObs
      });
    } catch (error: any) {
      setLoading(false)
      toast.showToast({ severity: 'error', summary: 'Atenção', detail: 'Erro ao salvar o registro' + error });
    }

    setLoading(false)
    toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
    load()
    setValue('HistoricoObs', '')
  }

  const editar = (item: any) => {
    setDesabilitaEdit(false)
    setHistoricoObs(item)
    setValue('HistoricoObs', item.HistoricoObs)
  };

  const cancelar = () => {
    setValue('HistoricoObs', '')
    setDesabilitaEdit(true)
  }

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Histórico Observação</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>


          <div className='grid mt-2'>
            <div className="col-12 md:col-5">
              <SCDropdown
                options={alunos}
                optionLabel='Nome'
                optionValue='Matricula'
                name="Aluno"
                label='Nome do Aluno'
                value={watchAlunos}
                onChange={(e) => setValue('Matricula', e.target.value)}
                placeholder='Selecione o Aluno'
                style={{ width: '100%' }}
              />
            </div>

            <div className="col-12 md:col-5">
              <SCDropdown
                options={curso}
                optionLabel='Descricao'
                optionValue='CursoId'
                name="CursoId"
                label='Curso'
                value={watchCursoId}
                onChange={(e) => setValue('CursoId', e.target.value)}
                placeholder='Selecione o Curso'
                style={{ width: '100%' }}
              />
            </div>
            <div className='col-12 md:col-2 flex align-items-end justify-content-between gap-3'>
              <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={filtroAlunos} />

              <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
              <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
            </div>
          </div>
          <Divider />
          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={observacoes}
              dataKey="HistoricoObservacaoId"
              paginator
              rows={20}
              rowsPerPageOptions={[10, 20, 40]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={filter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column field="Nome" header="Nome" sortable style={{ width: 200 }} bodyStyle={{ fontSize: 12 }} />
              <Column field="Matricula" header="Matrícula" sortable style={{ width: 90 }} bodyStyle={{ fontSize: 12 }} />
              <Column field="CursoDescricao" header="Curso" sortable style={{ width: '30%' }} bodyStyle={{ fontSize: 12 }} />
              <Column field="HistoricoObs" header="Observação" style={{ width: '30%' }} bodyStyle={{ fontSize: 12 }} />
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  </div>
                );
              }} style={{ width: 40 }}
              />
            </DataTable>
          )}
        </div>
        <SCFieldset legend='Observação' className='col-12 mt-3'>
          <div className='grid'>
            <div className="col-12">
              <SCInputTextarea
                style={{ minHeight: 150, width: '100%' }}
                control={control}
                errors={errors}
                name="HistoricoObs"
                rows={5}
                disabled={desabilitaEdit}
              />
            </div>
          </div>
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='button' onClick={gravar} disabled={desabilitaEdit} />
          </div>

        </SCFieldset>
      </div>
    </div>
  );
};

export default HistoricoObservacao;
