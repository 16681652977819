import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputNumber, SCInputText, SCRadioButton, SCFieldset } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import { IParcelaExtraDTO } from '../interfaces/IParcelaExtra';
import { IParcelaExtra } from '../interfaces/IParcelaExtra';
import { useAuth } from '../providers/auth';
import { IPeriodo } from '../interfaces/IUsuario';
import { CheckboxChangeParams } from 'primereact/checkbox';


interface Props {
  parcelaExtra?: IParcelaExtra;
  novoComBase: boolean;
  setNovoComBase(param: boolean): void;
  setParcelaExtraDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deleteParcelaExtra: boolean;
  setDeleteParcelaExtra(param: boolean): void;
}

interface Documento {
  DocumentoId: number;
  // Outras propriedades...
}

const ParcelaExtraForm: React.FC<Props> = ({ setParcelaExtraDialog, parcelaExtra, reload, deletar, deleteParcelaExtra, setDeleteParcelaExtra, setNovoComBase, novoComBase }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'ParcelaExtraForm';

  const toast = useToast();

  const { periodoSelecionado } = useAuth()

  const defaultValues: IParcelaExtra = parcelaExtra || {
    ParcelaExtraId: undefined,
    Descricao: '',
    RegistraFicha: false,
    MatriculaParcelada: false,
    ImpostoRenda: false,
    NegociacaoDebito: false,
    Dependencia: false,
    Desativada: false,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;
  //const [contas, setContas] = useState<any>(null);
  const [contasEntrada, setContasEntrada] = useState<any>(null);
  const [contasSaida, setContasSaida] = useState<any>(null);
  const [habilita, setHabilita] = useState<boolean>(false);

  const onSubmit = async (data: IParcelaExtraDTO) => {
    let resp: any = undefined;

    data.Descricao = data.ParcelaDescricao?.toUpperCase()
    let { Classificacao, Conta, ContabilizaDQ, ContabilizaIR, Desativado, DescricaoSimplificada,
      PLConta, ParcelaDescricao, Percentual, PlanoContaId, Tipo, Valor, ...newData } = data

    try {
      if (data.ParcelaExtraId === undefined) {
        console.log(novoComBase)
        console.log(data)
        console.log(newData)
        resp = await api.post('/parcelaExtra', newData);

      } else {
        resp = await api.put('/parcelaExtra', data);
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setNovoComBase(false)
      setParcelaExtraDialog(false);
    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const cancelar = (e: any) => {
    setDeleteParcelaExtra(false);
    setParcelaExtraDialog(false);
    setNovoComBase(false)
    reset()
  }

  const load = async () => {
    // const resultContas = await api.get('/planoContas/list');
    // setContas(resultContas.data);
    const resultContasEntrada = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
    setContasEntrada(resultContasEntrada.data);

    // console.log('ENTRADA', resultContasEntrada.data)

    console.log(parcelaExtra)

    const resultContasSaida = await api.get('/planoContas/list', { params: { Tipo: 'SAÍDA' } });
    setContasSaida(resultContasSaida.data);

    // console.log('SAIDA', resultContasSaida.data)
  }

  const habilitaCodigo = () => {
    if (deleteParcelaExtra) {
      setHabilita(true);
    } else if (parcelaExtra?.ParcelaExtraId && !novoComBase) {
      setHabilita(true);
    } else if (novoComBase) {
      setHabilita(false);
      setValue('ParcelaExtraId', undefined)
    }
  };

  useEffect(() => {
    habilitaCodigo();
  }, []);

  useEffect(() => {
    load()
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid align-items-center'>
        <div className="col-2">
          <SCInputNumber
            autoFocus={true}
            control={control}
            errors={errors}
            name="Parcela"
            label='Parcela'
            min={61}
            maxLength={3}
            max={999}
            required={!deleteParcelaExtra}
            disabled={habilita}
          />
        </div>
        <div className="col-6">
          <SCInputText
            control={control}
            errors={errors}
            style={{ textTransform: 'uppercase' }}
            name="ParcelaDescricao"
            label='Descrição'
            maxLength={50}
            required={!deleteParcelaExtra}
            tarefa={tarefa}
            disabled={deleteParcelaExtra}
            tooltip='Máximo de 50 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className='col-12 md:col-2 mt-4'>
          <SCCheckbox
            control={control}
            name='Desativada'
            label='Desabilitar Parcela'
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-12">
          <div className='pb-2'>Gerar registro na Ficha Financeira do aluno <AjudaForm tarefa={tarefa} campo='PermiteLancamentoNotas' /></div>
          <div className="flex align-items-center">
            <SCRadioButton
              control={control}
              name="RegistraFicha"
              label='Sim'
              value={true}
              disabled={deleteParcelaExtra}
            />
            <SCRadioButton
              control={control}
              name="RegistraFicha"
              label='Não'
              value={false}
              className='ml-3'
              disabled={deleteParcelaExtra}
            />
          </div>
        </div>
      </div>
      <div className='grid'>
        <div className="col-12">
          <div className='pb-2'>Liberar para ser utilizada no parcelamento de matrícula <AjudaForm tarefa={tarefa} campo='PermiteLancamentoNotas' /></div>
          <div className="flex align-items-center">
            <SCRadioButton
              control={control}
              name="MatriculaParcelada"
              label='Sim'
              value={true}
              disabled={deleteParcelaExtra}
            />
            <SCRadioButton
              control={control}
              name="MatriculaParcelada"
              label='Não'
              value={false}
              className='ml-3'
              disabled={deleteParcelaExtra}
            />
          </div>
        </div>
      </div>
      <div className='grid'>
        <div className='col-12'>
          <div className="flex align-items-center">
            <SCCheckbox
              checked={true}
              control={control}
              name="ImpostoRenda"
              label='Contabilizar para a declaração de imposto de renda'
              disabled={deleteParcelaExtra}
              tarefa={tarefa}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className="flex align-items-center">
            <SCCheckbox
              checked={true}
              control={control}
              name="NegociacaoDebito"
              label='Essa parcela pode ser utilizada para NEGOCIAÇÃO de débito'
              disabled={deleteParcelaExtra}
              tarefa={tarefa}
            />
          </div>
        </div>
        <div className='col-12'>
          <div className="flex align-items-center">
            <SCCheckbox
              checked={true}
              control={control}
              name="Dependencia"
              label='Essa parcela pode ser utilizada para DEPENDÊNCIA'
              disabled={deleteParcelaExtra}
              tarefa={tarefa}
            />
          </div>
        </div>

        <SCFieldset legend='Contas' className='col-12 mt-3' >
          <div className='md:flex col-12 pl-0'>
            <div className="col-12 md:col-6">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaValorId"
                label='Valor'
                options={contasEntrada}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                tarefa={tarefa}
                placeholder='Selecione a conta...'
                required
              />
            </div>

            <div className="col-12 md:col-6">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaDescontoId"
                label='Desconto'
                options={contasSaida}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                tarefa={tarefa}
                placeholder='Selecione a conta...'
                required
              />
            </div>
          </div>
          <div className='md:flex col-12 pl-0'>
            <div className="col-12 md:col-6">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaMultaId"
                label='Multa'
                options={contasEntrada}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                tarefa={tarefa}
                placeholder='Selecione a conta...'
                required
              />
            </div>

            <div className="col-12 md:col-6">
              <SCDropdown
                control={control}
                errors={errors}
                name="ContaJurosId"
                label='Juros'
                options={contasEntrada}
                optionLabel='ContaDescricao'
                optionValue='PlanoContaId'
                tarefa={tarefa}
                placeholder='Selecione a conta...'
                required
              />
            </div>
          </div>
        </SCFieldset>
      </div>
      <Divider />
      <div className='grid'>
        {deleteParcelaExtra ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>

    </form >

  );
};

export default ParcelaExtraForm;
