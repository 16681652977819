import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import IFormaPagamento from '../interfaces/IFormaPagamento';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { FormProvider, useForm } from 'react-hook-form';
import { SCCheckbox, SCDialog, SCInputText } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import { IoMdCheckboxOutline } from "react-icons/io";
import FormaRecebimentoForm from './FormaRecebimentoForm';


const FormaRecebimento = () => {
  const toast = useToast();
  const tarefa = 'FormaRecebimentoForm';

  const { usuario } = useAuth();

  let emptyFormaPagamento: IFormaPagamento = {
    FormasPagamentoId: undefined,
    Codigo: '',
    Descricao: '',
    Desativado: false,
    Tipo: '',
    Fonte: 0
  };

  const [formasPagamento, setFormasPagamento] = useState<any>(null);
  const [formasPagamentoOriginal, setFormasPagamentoOriginal] = useState<any>(null);
  const [formaPagamentoDialog, setFormaPagamentoDialog] = useState<boolean>(false);
  const [deleteFormaPagamento, setDeleteFormaPagamento] = useState<boolean>(false);
  const [formaPagamento, setFormaPagamento] = useState<IFormaPagamento>(emptyFormaPagamento);
  const [selectedFormaPagamento, setSelectedPagamento] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [somenteAtivos, setSomenteAtivos] = useState<boolean>(true);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyFormaPagamento });
  const { handleSubmit, control, formState: { errors }, getValues } = methods;

  const load = async () => {
    const result = await api.get('/formasPagamento/list', { params: { Descricao: getValues()?.Descricao } });
    // console.log(result.data)
    if (result.data) {
      setFormasPagamento(result.data.filter((item: any) => !item.Desativado));
      setFormasPagamentoOriginal(result.data);
    } else
      setFormasPagamento([]);
  }

  useEffect(() => {
    load();
  }, []);

  const openNew = () => {
    setFormaPagamento(emptyFormaPagamento);
    setSubmitted(false);
    setFormaPagamentoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setFormaPagamentoDialog(false);
  };
  const editar = (item: any) => {
    setFormaPagamento(item);
    setFormaPagamentoDialog(true);
  };

  const confirmDelete = (item: any) => {
    setFormaPagamento(item);
    setDeleteFormaPagamento(true);
    setFormaPagamentoDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/formasPagamento', { params: { FormasPagamentoId: formaPagamento.FormasPagamentoId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteFormaPagamento(false);
      setFormaPagamentoDialog(false);
      setFormaPagamento(emptyFormaPagamento);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }

  };

  const onSubmit = async (data: any) => {
    load();
  }

  const handleCheckbox = (e: any) => {
    if (e === true) {
      setFormasPagamento(formasPagamento.filter((item: any) => !item.Desativado))
    } else {
      setFormasPagamento(formasPagamentoOriginal)
    } 
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];


  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Formas de Recebimento</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCInputText
                  control={control}
                  errors={errors}
                  name="Descricao"
                  label='Descrição'
                  className='w-full'
                />
              </div>
              <div className="col-12 md:col-2 mt-3">
                <SCCheckbox
                  name="Ativos"
                  label="Somente Ativos"
                  checked={somenteAtivos}
                  onChange={(e) => {
                    setSomenteAtivos(e.target.checked)
                    handleCheckbox(e.target.checked)
                  }}
                />
              </div>
              <div className='col-12 md:col-4 flex align-items-end justify-content-around'>
                <Button label='Pesquisar' icon="pi pi-search" className="p-button-info p-button-outlined " type='button' onClick={load} />
                {usuario?.SuperMaster == 1 && (
                  <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />
                )}

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />

          <DataTable
            size='small'
            stripedRows
            ref={dt}
            value={formasPagamento}
            onSelectionChange={(e) => setSelectedPagamento(e.value)}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} a {last} de {totalRecords}"
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            header={null}
            responsiveLayout="scroll">
            <Column field="Codigo" header="Código" sortable headerStyle={{ width: 100 }}></Column>
            <Column field="Descricao" header="Descrição" sortable headerStyle={{ width: 500 }}></Column>
            <Column hidden={!util.isDesktop()} field="Tipo" header="Tipo"
              body={(rowData: IFormaPagamento) => (
                rowData.Tipo == "1" ? 'Dinheiro' : rowData.Tipo == "2" ? 'Cheque' : rowData.Tipo == "3" ? "Cartão" : "Outro"
              )}

            />
            <Column field="FonteDescricao" header="Fonte Sugerida" sortable headerStyle={{ width: 220 }}></Column>
            <Column bodyStyle={{ width: 60 }} field="Desativado" header="Ativado" align={'center'} alignHeader={'center'} sortable body={(rowData: any) => {
              return (
                <IoMdCheckboxOutline size={20} color={rowData.Desativado ? 'red' : "#01902f"} />
              )
            }} ></Column>
            <Column body={(rowData: any) => {
              return (
                <div className="actions flex align-items-center justify-content-between">
                  <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                  {usuario?.SuperMaster == 1 && (
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  )}
                </div>
              );
            }} style={{ width: 90 }}
            />
          </DataTable>

          <SCDialog maximized={!util.isDesktop()} visible={formaPagamentoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${formaPagamento.FormasPagamentoId === undefined ? 'Cadastrando ' : (deleteFormaPagamento ? 'Excluíndo' : 'Alterando')} Forma de Recebimento`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <FormaRecebimentoForm
              setFormaPagamentoDialog={setFormaPagamentoDialog}
              formaPagamento={formaPagamento}
              reload={load}
              deleteFormaPagamento={deleteFormaPagamento}
              setDeleteFormaPagamento={setDeleteFormaPagamento}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default FormaRecebimento;
