import { useState, useEffect, useRef, SetStateAction } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS, SITUACAO_ALUNO_R_M } from '../utilities/constantes';
import { useForm } from 'react-hook-form';
import { SCCheckbox, SCDropdown, SCFieldset, SCInputNumber, SCRadioButton } from '../components';
import AjudaFormTela from './AjudaFormTela';
import { Fieldset } from 'primereact/fieldset';
import { Divider } from 'primereact/divider';
import { CheckboxChangeParams } from 'primereact/checkbox';
import { SlReload } from 'react-icons/sl';


const ZeraMensalidades = () => {
    const toast = useToast();
    const { periodoSelecionado } = useAuth();
    const tarefa = 'ZeraMensalidades';

    const [globalFilter, setGlobalFilter] = useState<any>(null);
    const [turmas, setTurmas] = useState<any>(null);
    const [cursos, setCursos] = useState<any>(null);
    const [convenios, setConvenios] = useState<any>([]);
    const [alunos, setAlunos] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [todosSelecionados, setTodosSelecionados] = useState(false);
    const [contaVisivel, setContaVisivel] = useState<boolean>(false);
    const [contas, setContas] = useState<any>([]);
    const [alunosFiltro, setAlunosFiltro] = useState<any>([]);

    const methods = useForm();
    const { control, watch, getValues, setValue } = methods;

    const load = async () => {
        setValue('ParcelaInicial', 1)
        setValue('ParcelaFinal', 12)
        const turma = await api.get('/turma/listaVagasOcupadas', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
                Situacao: 'M'
            }
        });

        if (turma.data) {
            const turmasComCodigoDescricao = turma.data.map((turma: any) => ({
                ...turma,
                CodigoDescricao: `${turma.Codigo} - ${turma.Descricao}`
            }));
            setTurmas(turmasComCodigoDescricao);

        } else {
            setTurmas([]);
        }

        const cursos = await api.get('/curso');
        if (cursos.data) {
            const cursosComCodigoDescricao = cursos.data.map((curso: any) => ({
                ...curso,
                CodigoDescricao: `${curso.Codigo} - ${curso.Descricao}`
            }));
            setCursos(cursosComCodigoDescricao)
        } else {
            setCursos([])
        }

        const convenios = await api.get('/convenio/list');
        if (convenios.data) {
            const conveniosComCodigoDescricao = convenios.data.map((convenio: any) => ({
                ...convenio,
                CodigoDescricao: `${convenio.Codigo} - ${convenio.Descricao}`
            }));
            setConvenios(conveniosComCodigoDescricao)
        } else {
            setConvenios([])
        }

        const alunos = await api.get('/alunosPeriodo/listaAlunos', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        });
        if (alunos.data) {
            setAlunosFiltro(alunos.data)
        } else {
            setAlunosFiltro([])
        }

        setValue('Zerar', 6)
    };

    const watchCurso = watch('CursoId')
    const watchAlunos = watch('Alunos')
    const watchConvenio = watch('ConvenioId')
    const watchCodigoTurma = watch('Codigo');
    //const watchSituacao = watch('Value');
    const watchParcelaInicial = watch('ParcelaInicial');
    const watchParcelaFinal = watch('ParcelaFinal');
    const watchZerar = watch('Zerar');
    const watchSituacaoM = watch('SituacaoM')
    const watchSituacaoR = watch('SituacaoR')
    const watchSituacaoA = watch('SituacaoA')
    const watchSituacaoC = watch('SituacaoC')
    const watchSituacaoT = watch('SituacaoT')
    const watchSituacaoS = watch('SituacaoS')
    const watchPlanoConta = watch('PlanoContaId')

    useEffect(() => {
        load();
    }, []);

    const handleCheckboxChange = (rowData: any, checked: boolean) => {
        const updatedAlunos = alunos.map(item => {
            if (item.AlunosPeriodoId === rowData.AlunosPeriodoId) {
                return { ...item, Selecionado: checked ? 1 : 0 };
            }
            return item;
        });

        setAlunos(updatedAlunos);
    };

    const handleSelectAllChange = (e: any) => {
        const isChecked = e.target.checked;
        setTodosSelecionados(isChecked);

        const updatedAlunos = alunos.map(item => {
            if (item.AlunosPeriodoId === e.AlunosPeriodoId) {
                return { ...item, Selecionado: isChecked ? 1 : 0 };
            }
            return item;
        });

        if (isChecked) {
            updatedAlunos.forEach(item => {
                item.Selecionado = 1
            })
            setAlunos(updatedAlunos)
        } else {
            updatedAlunos.forEach(item => {
                item.Selecionado = 0
            })
            setAlunos(updatedAlunos)
        }
    };

    const onSubmit = async () => {
        try {
            setLoading(true);

            const promises = alunos.map(async (item: any) => {
                if (item.Selecionado == 1) {
                    let campos = {};

                    switch (watchZerar) {
                        case 1:
                            campos = {
                                DataVencimento: null,
                                DataVencimentoNegociado: null,
                                ValorMensalidade: 0,
                                ValorDesconto: 0,
                                ValorDescontoNormal: 0,
                                NumeroBoleta: '',
                                NumeroDocumento: '',
                                NossoNumeroDV: '',
                                FlgParcelaNegociada: 0,
                                BancoId: item.BancoId == '' ? null : 0,
                                LinhaDigitavel: '',
                                CodigoBarras: '',
                                ArquivoRemessaData: null,
                                ArquivoRemessaUsuarioId: 0,
                                ArquivoRemessaNomeArquivo: '',
                                DataSaldo: null,
                                GeracaoData: null,
                            };
                            break;
                        case 2:
                            campos = {
                                ValorMensalidade: 0,
                                ValorDesconto: 0,
                                ValorDescontoNormal: 0,
                                NumeroBoleta: '',
                                NumeroDocumento: '',
                                NossoNumeroDV: '',
                                FlgParcelaNegociada: 0,
                                BancoId: item.BancoId == '' ? null : 0,
                                LinhaDigitavel: '',
                                CodigoBarras: '',
                                ArquivoRemessaData: null,
                                ArquivoRemessaUsuarioId: 0,
                                ArquivoRemessaNomeArquivo: '',
                                DataSaldo: null,
                                GeracaoData: null,
                            };
                            break;
                        case 3:
                            campos = {
                                ValorDesconto: 0,
                                ValorDescontoNormal: 0,
                                NumeroBoleta: '',
                                NumeroDocumento: '',
                                NossoNumeroDV: '',
                                FlgParcelaNegociada: 0,
                                BancoId: item.BancoId == '' ? null : 0,
                                LinhaDigitavel: '',
                                CodigoBarras: '',
                                ArquivoRemessaData: null,
                                ArquivoRemessaUsuarioId: 0,
                                ArquivoRemessaNomeArquivo: '',
                                DataSaldo: null,
                                GeracaoData: null,
                            };
                            break;
                        case 4:
                            const contas = [
                                'ContaValorComplementar01',
                                'ContaValorComplementar02',
                                'ContaValorComplementar03',
                                'ContaValorComplementar04',
                                'ContaValorComplementar05',
                                'ContaValorComplementar06'
                            ];
                            contas.forEach(async campo => {
                                if (item[campo] !== '' && item[campo] !== undefined && item[campo] !== null) {
                                    if (item[campo] == watchPlanoConta) {
                                        const novoCampo = campo.replace('Conta', '');
                                        console.log(`Campo ${novoCampo} tem valor: ${item[campo]}`);

                                        await api.put('/mensalidade/zeraValorComplementarDeducao', {
                                            EmpresaId: periodoSelecionado?.EmpresaId,
                                            Ano: periodoSelecionado?.Ano,
                                            Sequencial: periodoSelecionado?.Sequencial,
                                            Matricula: item.Matricula,
                                            ParcelaInicial: watchParcelaInicial,
                                            ParcelaFinal: watchParcelaFinal,
                                            ValorComplementar: novoCampo,
                                        });
                                    }
                                }
                            });
                            break;
                        case 5:
                            if (item['ContaValorDeducao'] !== '' && item['ContaValorDeducao'] !== undefined && item['ContaValorDeducao'] !== null) {
                                if (item['ContaValorDeducao'] == watchPlanoConta) {
                                    const novoCampo = 'ContaValorDeducao'.replace('Conta', '');
                                    console.log(`Campo ${novoCampo} tem valor: ${item['ContaValorDeducao']}`);

                                    await api.put('/mensalidade/zeraValorComplementarDeducao', {
                                        EmpresaId: periodoSelecionado?.EmpresaId,
                                        Ano: periodoSelecionado?.Ano,
                                        Sequencial: periodoSelecionado?.Sequencial,
                                        Matricula: item.Matricula,
                                        ParcelaInicial: watchParcelaInicial,
                                        ParcelaFinal: watchParcelaFinal,
                                        ValorDeducao: novoCampo,
                                    });
                                }
                            }
                            break;
                    }

                    if (watchZerar != 4 && watchZerar != 5) {
                        console.log('é diferente memo', watchZerar)
                        await api.put('/mensalidade/zeraMensalidade', {
                            EmpresaId: periodoSelecionado?.EmpresaId,
                            Ano: periodoSelecionado?.Ano,
                            Sequencial: periodoSelecionado?.Sequencial,
                            Matricula: item.Matricula,
                            ParcelaInicial: watchParcelaInicial,
                            ParcelaFinal: watchParcelaFinal,
                            Campos: campos,
                        });
                    }
                }
            });

            await Promise.all(promises);
            setLoading(false);
            toast.showToast({ severity: 'success', summary: 'Atenção', detail: 'Mensalidades zeradas com Sucesso!' });
            window.location.reload();
        } catch (e: any) {
            setLoading(false);
            toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
        }
    };

    const clickHandler = async () => {

        const alunos = await api.get('/alunosPeriodo/listaAlunos', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        });

        let alunosFiltrados = alunos.data.map((aluno: any) => ({
            ...aluno,
            Selecionado: 1
        }));

        if (alunosFiltrados) {
            if (watchCodigoTurma) {
                alunosFiltrados = alunosFiltrados.filter((item: any) => {
                    const turmaFiltrada = item.TurmaCodigo;
                    return turmaFiltrada == watchCodigoTurma;
                })
                if (watchConvenio) {
                    alunosFiltrados = alunosFiltrados.filter((item: any) => {
                        const convenioFiltrado = item.ConvenioId;
                        return convenioFiltrado == watchConvenio;
                    })
                }
            } else {
                if (watchConvenio) {
                    alunosFiltrados = alunosFiltrados.filter((item: any) => {
                        const convenioFiltrado = item.ConvenioId;
                        return convenioFiltrado == watchConvenio;
                    })
                }
            }
            if (watchCurso) {
                alunosFiltrados = alunosFiltrados.filter((item: any) => {
                    const cursoFiltrado = item.CursoId;
                    return cursoFiltrado == watchCurso;
                })
            }
            if (watchAlunos) {
                alunosFiltrados = alunosFiltrados.filter((item: any) => {
                    const alunoFiltrado = item.Matricula;
                    return alunoFiltrado == watchAlunos;
                })
            }

            const situacoesSelecionadas: any[] = [];

            if (watchSituacaoM) situacoesSelecionadas.push('M');
            if (watchSituacaoR) situacoesSelecionadas.push('R');
            if (watchSituacaoA) situacoesSelecionadas.push('A');
            if (watchSituacaoC) situacoesSelecionadas.push('C');
            if (watchSituacaoT) situacoesSelecionadas.push('T');
            if (watchSituacaoS) situacoesSelecionadas.push('S');

            if (situacoesSelecionadas.length > 0) {
                alunosFiltrados = alunosFiltrados.filter((item: any) => {
                    return situacoesSelecionadas.includes(item.SituacaoAcademica);
                });
            }
        }

        const alunosSelecionados: SetStateAction<any[]> = [];
        const mensalidades = await api.get('/mensalidades/mensalidadesEmAberto', {
            params: {
                EmpresaId: periodoSelecionado?.EmpresaId,
                Ano: periodoSelecionado?.Ano,
                Sequencial: periodoSelecionado?.Sequencial,
            }
        });

        mensalidades.data.map((dadosMensalidade: any) => {
            alunosFiltrados.forEach((dadosAlunos: any) => {
                if (dadosMensalidade.Matricula == dadosAlunos.Matricula) {
                    const alreadyExists = alunosSelecionados.some((aluno: any) => aluno.AlunosPeriodoId === dadosAlunos.AlunosPeriodoId);
                    if (!alreadyExists) {
                        alunosSelecionados.push(dadosAlunos);
                    }
                }
            })
        })
        if (alunosSelecionados.length == 0) {
            toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Nenhum registro encontrado!' });
        }
        setAlunos(alunosSelecionados)

        setTodosSelecionados(true)
    }

    const parcelasChange = (e: any) => {
        let parcelaInicial: any, parcelaFinal: any;
        parcelaInicial = watchParcelaInicial;
        parcelaFinal = watchParcelaFinal;

        if (parcelaInicial < 1) {
            setValue('ParcelaInicial', 1)
            parcelaInicial = 1;
        }

        if (parcelaFinal < 1) {
            setValue('ParcelaFinal', 1)
            parcelaFinal = 1;
        }

        if (watchParcelaInicial > watchParcelaFinal) {
            setValue('ParcelaInicial', parcelaFinal)
            parcelaInicial = parcelaFinal;
        }
    }

    useEffect(() => {
        const fetchContas = async () => {
            try {
                if (watchZerar === 4) {
                    setContaVisivel(true);
                    const resultContas = await api.get('/planoContas/combo', { params: { Tipo: 'ENTRADA' } });
                    setContas(resultContas.data);
                } else if (watchZerar === 5) {
                    setContaVisivel(true);
                    const resultContas = await api.get('/planoContas/combo', { params: { Tipo: 'SAÍDA' } });
                    setContas(resultContas.data);
                } else {
                    setContaVisivel(false);
                }
            } catch (error) {
                console.error('Erro ao buscar contas:', error);
            }
        };

        fetchContas()
    }, [watchZerar]);

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
                        <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Zerar Mensalidades</h5>
                        <AjudaFormTela tarefa={tarefa} campo='' />
                    </div>
                    <SCFieldset legend='Filtros' className='col-12 mt-2 pt-0'>
                        <div className='grid'>
                            <div className="col-12 md:col-6">
                                <SCDropdown
                                    name="Curso"
                                    options={cursos}
                                    optionLabel='CodigoDescricao'
                                    optionValue='CursoId'
                                    placeholder='Selecione o Curso'
                                    label='Curso'
                                    value={watchCurso}
                                    style={{ width: '100%' }}
                                    onChange={(e) => setValue('CursoId', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-6">
                                <SCDropdown
                                    name="Codigo"
                                    options={turmas}
                                    optionLabel="CodigoDescricao"
                                    optionValue='Codigo'
                                    placeholder='Selecione a Turma'
                                    label='Turma'
                                    style={{ width: '100%' }}
                                    value={watchCodigoTurma}
                                    onChange={(e) => setValue('Codigo', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-6">
                                <SCDropdown
                                    name="Alunos"
                                    options={alunosFiltro}
                                    optionLabel="Nome"
                                    optionValue='Matricula'
                                    placeholder='Selecione o Aluno'
                                    label='Aluno'
                                    style={{ width: '100%' }}
                                    value={watchAlunos}
                                    onChange={(e) => setValue('Alunos', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-4">
                                <SCDropdown
                                    name="Convenio"
                                    options={convenios}
                                    optionLabel='CodigoDescricao'
                                    optionValue='ConvenioId'
                                    placeholder='Selecione o Convênio'
                                    label='Convênio'
                                    style={{ width: '100%' }}
                                    value={watchConvenio}
                                    onChange={(e) => setValue('ConvenioId', e.target.value)}
                                />
                            </div>
                        </div>
                        <SCFieldset legend='Situação Acadêmica' className='col-12 mt-2 pt-0'>
                            <div className='grid'>
                                <div className='col-12 md:col-2 mt-2'>
                                    <SCCheckbox
                                        control={control}
                                        name='SituacaoM'
                                        label='Matriculado'
                                    />
                                </div>
                                <div className='col-12 md:col-2 mt-2'>
                                    <SCCheckbox
                                        control={control}
                                        name='SituacaoR'
                                        label='Registrado'
                                    />
                                </div>
                                <div className='col-12 md:col-2 mt-2'>
                                    <SCCheckbox
                                        control={control}
                                        name='SituacaoA'
                                        label='Abandono'
                                    />
                                </div>
                                <div className='col-12 md:col-2 mt-2'>
                                    <SCCheckbox
                                        control={control}
                                        name='SituacaoC'
                                        label='Cancelado'
                                    />
                                </div>
                                <div className='col-12 md:col-2 mt-2'>
                                    <SCCheckbox
                                        control={control}
                                        name='SituacaoT'
                                        label='Transferido'
                                    />
                                </div>
                                <div className='col-12 md:col-2 mt-2'>
                                    <SCCheckbox
                                        control={control}
                                        name='SituacaoS'
                                        label='Trancado'
                                    />
                                </div>
                            </div>
                        </SCFieldset>
                        <div className='col-12 md:col-2 flex align-items-end'>
                            <Button label='Pesquisar' icon="pi pi-search" className="p-button-info" type='button' onClick={clickHandler} />
                        </div>
                    </SCFieldset>
                    <SCFieldset legend='Alunos Encontrados' style={{ fontWeight: 'bold', color: '#0772B3' }} className='col-12 mt-2 pt-0'>
                        <DataTable
                            size='small'
                            stripedRows
                            dataKey="id"
                            value={alunos}
                            currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            emptyMessage="Nenhum registro encontrado."
                            editMode='cell'
                            responsiveLayout="scroll"
                        >

                            <Column field="TurmaCodigo" header="Turma" sortable headerStyle={{ width: '11%' }} />
                            <Column field="Matricula" header="Matrícula" sortable headerStyle={{ width: '11%' }} />
                            <Column field="Nome" header="Nome" sortable headerStyle={{ width: '30%' }} />
                            <Column field="SituacaoAcademica" header="Situação" body={(rowData: any) => {
                                const situacoesMap: { [key: string]: string } = {
                                    'M': 'Matriculado',
                                    'R': 'Registrado',
                                    'A': 'Abandono',
                                    'C': 'Cancelado',
                                    'T': 'Transferido',
                                    'S': 'Trancado'
                                };

                                const situacao = rowData.SituacaoAcademica as string;
                                return (
                                    <div className="col-12 flex justify-content-center">
                                        <span>{situacoesMap[situacao] || 'Desconhecido'}</span>
                                    </div>
                                );
                            }}
                                alignHeader='center'
                                align='center' />
                            <Column field="Descricao" header="Convênio" sortable headerStyle={{ width: '20%' }} />
                            <Column
                                header={
                                    <div>
                                        <SCCheckbox
                                            label='Selecionar Todos'
                                            name='selecionarTodos'
                                            checked={todosSelecionados}
                                            onChange={handleSelectAllChange}
                                        />
                                    </div>
                                }
                                body={(rowData: any) => (
                                    <div className="col-12 flex justify-content-center">
                                        <SCCheckbox
                                            key={rowData.TurmaId}
                                            checked={rowData.Selecionado === 1}
                                            onChange={(e: CheckboxChangeParams) => handleCheckboxChange(rowData, e.checked)}
                                            id={rowData.AlunosPeriodoId}
                                            name="Selecionado"
                                            value={rowData.Selecionado === 1}
                                        />
                                    </div>
                                )}
                                alignHeader='center'
                                align='center'
                            />
                        </DataTable>
                    </SCFieldset>
                    <SCFieldset legend='Parcelas' className='col-12 mt-2'>
                        <div className='grid'>
                            <div className="col-12 md:col-4">
                                <div className='flex flex-column gap-1'>
                                    <label>Parcela Inicial</label>
                                    <SCInputNumber
                                        name='ParcelaInicial'
                                        mode="decimal"
                                        showButtons
                                        min={1}
                                        max={100}
                                        value={watchParcelaInicial}
                                        onChange={(e) => setValue('ParcelaInicial', e.value)}
                                        onBlur={(e) => parcelasChange(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className='flex flex-column gap-1'>
                                    <label>Parcela Final</label>
                                    <SCInputNumber
                                        name='ParcelaFinal'
                                        mode="decimal"
                                        showButtons
                                        min={0}
                                        max={100}
                                        value={watchParcelaFinal}
                                        onChange={(e) => setValue('ParcelaFinal', e.value)}
                                        onBlur={(e) => parcelasChange(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </SCFieldset>
                    <SCFieldset legend='O que fazer?' className='col-12 mt-2'>
                        <div className='grid'>
                            <div className="col-12 md:col-12 flex">
                                <SCRadioButton
                                    control={control}
                                    name="Zerar"
                                    label='Zerar Dados Principais  (Limpa os dados de Data de Vencimento e valores de Mensalidade e Desconto)'
                                    value={1}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-12 flex">
                                <SCRadioButton
                                    control={control}
                                    name="Zerar"
                                    label='Zerar Valores de Mensalidades (Limpa os dados de valores de Mensalidade e Desconto)'
                                    value={2}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-12 flex">
                                <SCRadioButton
                                    control={control}
                                    name="Zerar"
                                    label='Zerar Valores de Desconto (Limpa somente os dados de Desconto)'
                                    value={3}
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-12 flex">
                                <SCRadioButton
                                    control={control}
                                    name="Zerar"
                                    label='Zerar Valores de Acréscimo (Limpa os dados de Valor Complementar, da conta de entrada que for selecionada)'
                                    value={4} />
                            </div>
                        </div>
                        <div className='grid'>
                            <div className="col-12 md:col-12 flex">
                                <SCRadioButton
                                    control={control}
                                    name="Zerar"
                                    label='Zerar Valores de Dedução (Limpa os dados de Valor Complementar, da conta de saída que for selecionada)'
                                    value={5}
                                />
                            </div>
                            {/* <div className="col-12 md:col-3 flex">
                                <SCRadioButton
                                    control={control}
                                    name="Zerar"
                                    label='Zerar Tudo'
                                    value={6}
                                />
                            </div> */}
                        </div>
                        <div className='grid'>
                            {contaVisivel && (
                                <div className="col-12 md:col-6">
                                    <SCDropdown
                                        name="PlanoContaId"
                                        label='Contas'
                                        options={contas}
                                        optionLabel='ContaDescricao'
                                        optionValue='PlanoContaId'
                                        tarefa={tarefa}
                                        value={watchPlanoConta}
                                        onChange={(e) => setValue('PlanoContaId', e.target.value)}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            )}
                        </div>
                    </SCFieldset>
                    <div className='grid flex justify-content-center'>
                        <div className='col-12 md:col-10 pt-4 flex justify-content-center align-items-center'></div>
                        <div className='col-12 md:col-2 flex justify-content-end mt-2 '>
                            <Button label='Executar' icon="pi pi-plus-o" className="p-button-success" type='button' style={{ width: '100%' }} onClick={onSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ZeraMensalidades;