import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import { SCButton, SCCheckbox, SCDropdown, SCInputText, SCInputMask, SCFieldset, SCInputNumber } from '../components';
import { useToast } from '../context/ToastContext';
import IPlanoConta from '../interfaces/IPlanoConta';
import api from "../services/api";
import { ALERTAS, TIPO_CONTA } from '../utilities/constantes';
import util from '../utilities/util';
import AjudaForm from './AjudaForm';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import { ToggleButton } from 'primereact/togglebutton';


interface Props {
  planoConta?: IPlanoConta;
  NovoComBase?: any;
  setPlanoContaDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  deletePlanoConta: boolean;
  setDeletePlanoConta(param: boolean): void;
}

const PlanoContaForm: React.FC<Props> = ({ setPlanoContaDialog, NovoComBase, planoConta, reload, deletar, deletePlanoConta, setDeletePlanoConta }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'PlanoContaForm';

  const toast = useToast();
  const [areaSelecionada, setAreaSelecionada] = useState<any>(null);
  const [desativaAprovaBaseMedia, setDesativaAprovaBaseMedia] = useState<boolean>(false);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [verificaClassificacao, setVerificaClassicacao] = useState<boolean>(true);
  const [checked, setChecked] = useState(false);
  const [verificaTipo, setVerificaTipo] = useState<boolean>(false);

  const defaultValues: IPlanoConta = planoConta || {
    PlanoContaId: undefined,
    Classificacao: '',
    Conta: '',
    Descricao: '',
    Desativado: false,
    Tipo: '',
    DescricaoSimplificada: '',
    ContabilizaIR: false,
    ContabilizaDQ: false,
    Valor: undefined,
    Percentual: undefined,
    temFilhos: false,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues, reset } = methods;

  const onSubmit = async (data: IPlanoConta) => {
    let resp: any = undefined;
    const conta = data.Conta?.toString().padStart(5, '0');
    const valor = getValues().Valor;
    const percentual = getValues().Percentual;

    const autoComplete = (str: any) => {
      switch (str.length) {
        case 3:
          return str + '0';
        case 5:
          return str + '0';
        case 7:
          return str + '000';
        case 8:
          return str + '00';
        case 9:
          return str + '0';
      }
    }

    data.Classificacao = typeof data.Classificacao === 'string' ? data.Classificacao.replace(/\./g, '') : '';
    // const classificacaoFinal = classificacao ? autoComplete(classificacao) : '';

    // data.Classificacao = classificacao;
    // data.Conta = conta;

    // if (valor != 0 && percentual != 0) {
    //   toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Apenas um campo permitido entre Percentual e Valor!' });
    //   return;
    // }

    if (valor?.toString() == '' && !isNaN(valor)) {
      data.Valor = 0;
    } else {
      const valorString = valor?.toString();
      if (valorString?.includes(',')) {
        data.Valor = parseFloat(valorString.replace(/,/g, '.'));
      } else {
        data.Valor = valor;
      }
    }

    // if (percentual?.toString() == '' && !isNaN(percentual)) {
    //   data.Percentual = 0;
    // } else {
    //   const percentualString = percentual?.toString();
    //   if (percentualString?.includes(',')) {
    //     data.Percentual = parseFloat(percentualString.replace(/,/g, '.'));
    //   } else {
    //     data.Percentual = percentual;
    //   }
    // }

    // Removendo o 'temFilhos do array'
    delete data.temFilhos;

    try {
      if (data.PlanoContaId === undefined)
        resp = await api.post('/planoContas', data);
      else
        resp = await api.put('/planoContas', data);

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setPlanoContaDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  }

  const watchClassi = watch('Classificacao')

  useEffect(() => {
    if (watchClassi?.length === 14)
      setVerificaClassicacao(false)
    else
      setVerificaClassicacao(true)
  }, [watchClassi])

  useEffect(() => {
    if (planoConta) {
      setChecked(planoConta.Desativado)
    }
  }, [planoConta]);

  useEffect(() => {
    setValue('Desativado', checked)
  }, [checked]);

  const watchTipo = watch('Tipo')
  //SAÍDA

  useEffect(() => {
    if (watchTipo === 'SAÍDA') {
      setVerificaTipo(true)
      setValue('ContabilizaIR', false)
      setValue('ContabilizaDQ', false)
    } else {
      setVerificaTipo(false)
    }

    if (watchTipo === null) {
      setValue('ContabilizaIR', false)
      setValue('ContabilizaDQ', false)
    }
  }, [watchTipo]);

  const cancelar = (e: any) => {
    setDeletePlanoConta(false);
    setPlanoContaDialog(false);
    reset();
    if (NovoComBase) {
      reload();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >
      <div className='grid'>
        <div className='col-12 pl-0'>
          <div className="col-12 md:col-2">
            <SCInputMask
              mask="9?.9.99.99.9999"
              //autoFocus={true}
              control={control}
              errors={errors}
              style={{ textTransform: 'uppercase' }}
              name="Classificacao"
              label='Classificação'
              maxLength={14}
              required={!deletePlanoConta}
              disabled={planoConta?.PlanoContaId !== undefined}
              tooltip='Máximo de 14 caracteres'
              tooltipOptions={{ event: 'focus' }}
            //tarefa={tarefa}
            />
          </div>
        </div>
        <div className="col-12 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            name="Conta"
            label='Conta'
            maxLength={5}
            required={!deletePlanoConta}
            disabled={planoConta?.PlanoContaId !== undefined}
            // onBlur={(e: any) => {
            //   const dados = getValues();
            //   if (dados.DescricaoHistorico?.trim() === '')
            //     setValue('DescricaoHistorico', e?.target?.value);
            // }}
            tarefa={tarefa}
            tooltip='Máximo de 5 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>
        <div className="col-12  md:col-4">
          <SCInputText
            control={control}
            errors={errors}
            name="Descricao"
            label='Descrição'
            required={!deletePlanoConta}
            tarefa={tarefa}
            tooltip='Máximo de 40 caracteres'
            tooltipOptions={{ event: 'focus' }}
          />
        </div>

        <div className='col-12 pl-0'>
          <div className="col-12  md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="DescricaoSimplificada"
              label='Descrição Simplificada'
              tarefa={tarefa}
              tooltip='Máximo de 15 caracteres'
              tooltipOptions={{ event: 'focus' }}
            />
          </div>
        </div>

        <div className="col-12 md:col-2">
          <SCDropdown
            control={control}
            errors={errors}
            name="Tipo"
            label='Tipo'
            options={TIPO_CONTA}
            optionLabel='descricao'
            disabled={deletePlanoConta || verificaClassificacao}
            tarefa={tarefa}
          />
        </div>

        <div className="col-12 md:col-6" style={{ marginTop: 10 }}>
          {(areaSelecionada?.detalhes) && (
            <div className='card'>{areaSelecionada.detalhes}</div>
          )}
        </div>

        <div className="col-12">
          <SCCheckbox
            control={control}
            name="ContabilizaIR"
            label='Contabilizar para a Declaração de Imposto de Renda.'
            disabled={deletePlanoConta || verificaClassificacao || verificaTipo}
            tarefa={tarefa}
          />
        </div>

        <div className="col-12">
          <SCCheckbox
            control={control}
            name="ContabilizaDQ"
            label='Contabilizar para a Declaração de Quitação.'
            disabled={deletePlanoConta || verificaClassificacao || verificaTipo}
            tarefa={tarefa}
          />
        </div>

        <SCFieldset legend='Cadastro de Taxas' className='col-12 mt-3' >
          <div className='grid'>
            <div className="col-6 md:col-3">
              <SCInputText
                control={control}
                errors={errors}
                name="Valor"
                label='Valor (R$)'
                disabled={deletePlanoConta || verificaClassificacao}
              />
            </div>
            {/* <div className="col-6 md:col-3">
              <SCInputText
                control={control}
                errors={errors}
                name="Percentual"
                label='Percentual (%)'
              />
            </div> */}
          </div>
        </SCFieldset>

        <Divider />
        {deletePlanoConta ? (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <Button label="Cancelar Exclusão" icon="pi pi-times" className="p-button w-auto p-button-danger" onClick={cancelar} type='button' />
            <Button label="Confirmar Exclusão" icon="pi pi-check"
              className={`p-button w-auto p-button-success p-button-outlined ${util.isDesktop() ? '' : ' ml-2'}`}
              onClick={deletar} type='button' />
          </div>
        ) : (
          <div className="flex flex-1 justify-content-between pt-3 px-2">
            <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
            <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
          </div>
        )}

      </div>
    </form>

  );
};

export default PlanoContaForm;
