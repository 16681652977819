import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SCButton, SCDropdown, SCFieldset, SCInputNumber, SCInputText } from '../components';
import { useToast } from '../context/ToastContext';
import api from "../services/api";
import { ALERTAS, FORMULAS_MATERIA, TIPO_DISCIPLINA } from '../utilities/constantes';
import util from '../utilities/util';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import IOfertaDisciplina, { IOfertaDisciplinaForm, IOfertaDisciplinaPut } from '../interfaces/IOfertaDisciplina';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ITurmas from '../interfaces/ITurmas';
import { sortAndDeduplicateDiagnostics } from 'typescript';

interface ITurmaOferta {
  TurmaId?: string;
  TurmaCodigo?: string;
  DisciplinaCodigo?: string;
}

interface Props {
  registro?: ITurmaOferta;
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
}

const OfertaDisciplinaMateriaForm: React.FC<Props> = ({ setShowDialog, registro, reload, deletar, showDelete, setShowDelete }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'OfertaDisciplinaForm';
  const toast = useToast();

  const { periodoSelecionado } = useAuth()

  const [loading, setLoading] = useState<boolean>(false);
  const [turma, setTurma] = useState<ITurmas>({} as ITurmas);
  const [listaDisciplinas, setListaDisciplinas] = useState<any[]>([]);
  const [lista, setLista] = useState<any[]>([]);
  const [listaInicial, setListaInicial] = useState<IOfertaDisciplinaPut[]>([]);
  const [materiasComNotas, setMateriasComNotas] = useState<any[]>([]);

  const defaultValues: IOfertaDisciplinaForm = {
    TurmaCodigo: registro?.TurmaCodigo,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, control, formState: { errors }, reset, setError, clearErrors, setValue, watch, getValues } = methods;

  useEffect(() => {

    async function load() {
      setLoading(true);
      if (registro?.TurmaId) {
        const turmaResult = await api.get('/turma', {
          params: {
            TurmaId: registro?.TurmaId,
          }
        });
        setTurma(turmaResult.data);

        const result = await api.get('/ofertaDisciplina/ofertaMaterias', {
          params: {
            TurmaCodigo: registro?.TurmaCodigo,
            EmpresaId: periodoSelecionado?.EmpresaId,
            Ano: periodoSelecionado?.Ano,
            Sequencial: periodoSelecionado?.Sequencial
          }
        });
        setListaInicial(JSON.parse(JSON.stringify(result.data)));
        setListaDisciplinas(result.data);

        const materiasComNotas: any[] = [];
        await Promise.all(
          result.data.map(async (item: any) => {
            const promises = Array.from({ length: 6 }, (_, i) => i + 1).map(async (indice) => {
              const resultBoletins = await api.get('/boletim/listNotas', {
                params: {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Ano: periodoSelecionado?.Ano,
                  Sequencial: periodoSelecionado?.Sequencial,
                  TurmaCodigo: turmaResult.data.Codigo,
                  DisciplinaCodigo: item.DisciplinaCodigo,
                  MateriaCodigo: item['MateriaCodigo' + indice],
                  SituacaoAcademica: 'M',
                },
              });

              if (resultBoletins.data.length > 0) {
                resultBoletins.data.forEach((item1: any) => {
                  const notas = ['NotaB1', 'NotaB2', 'NotaB3', 'NotaB4', 'NotaB5', 'NotaB6', 'NotaRI'];
                  notas.forEach((nota) => {
                    if (item1[nota] !== undefined && item1[nota] !== null) {
                      materiasComNotas.push({
                        materia: item['MateriaCodigo' + indice],
                        disciplina: item.DisciplinaDescricao,
                        nota: item1[nota],
                        tipoNota: nota,
                      });
                    }
                  });
                });
              }
            });
            await Promise.all(promises);
          })
        );

        setMateriasComNotas(materiasComNotas);

        const resultMaterias = await api.get('/materia', {
          params: {
            EmpresaId: periodoSelecionado?.EmpresaId,
          }
        });
        setLista(resultMaterias.data);


      }

      setLoading(false);
    }


    if (registro) {
      load();
    }
  }, [registro]);

  const onSubmit = async (data: any) => {
    clearErrors();

    setLoading(true);

    const materiaSemFormula = listaDisciplinas.find((item: any) => (
      (item.MateriaCodigo1 || item.MateriaCodigo2 || item.MateriaCodigo3 || item.MateriaCodigo4 || item.MateriaCodigo5 || item.MateriaCodigo6) && !item.Calculo
    ));

    if (materiaSemFormula) {
      console.log(materiaSemFormula);
      toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Existem matérias com cálculo não informado.' });
      setLoading(false);
      return false;
    }


    let resp: any = undefined;
    const oferta: IOfertaDisciplinaPut[] = listaDisciplinas.map((item: any) => ({
      OfertaDisciplinaId: item?.OfertaDisciplinaId,
      EmpresaId: item?.EmpresaId,
      Ano: item?.Ano,
      Sequencial: item?.Sequencial,
      TurmaCodigo: item?.TurmaCodigo,
      DisciplinaCodigo: item.DisciplinaCodigo,
      Ordem: item.Ordem,
      Chr: item.Chr,
      Tipo: item.Tipo,
      MateriaCodigo1: item.MateriaCodigo1,
      MateriaCodigo2: item.MateriaCodigo2,
      MateriaCodigo3: item.MateriaCodigo3,
      MateriaCodigo4: item.MateriaCodigo4,
      MateriaCodigo5: item.MateriaCodigo5,
      MateriaCodigo6: item.MateriaCodigo6,
      ProfessorMateria1: item.ProfessorMateria1,
      ProfessorMateria2: item.ProfessorMateria2,
      ProfessorMateria3: item.ProfessorMateria3,
      ProfessorMateria4: item.ProfessorMateria4,
      ProfessorMateria5: item.ProfessorMateria5,
      ProfessorMateria6: item.ProfessorMateria6,
      Calculo: item.Calculo,
      ProfessorDisciplina: item.ProfessorDisciplina,
      Formula: item.Formula,
      Link: item.Link,
      Cabecalho1: item.Cabecalho1,
      Cabecalho2: item.Cabecalho2,
      Cabecalho3: item.Cabecalho3,
      Cabecalho4: item.Cabecalho4,
      Cabecalho5: item.Cabecalho5,
      Cabecalho6: item.Cabecalho6,
      Rodape: item.Rodape,
    }));

    try {
      const alunos = await api.get('/alunosPeriodo/listaAlunosTurma', {
        params: {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: oferta[0]?.TurmaCodigo,
        },
      });

      if (!registro?.TurmaCodigo) {
        resp = await api.post('/ofertaDisciplina', { Lista: oferta });
      } else {
        resp = await api.put('/ofertaDisciplina', {
          EmpresaId: periodoSelecionado?.EmpresaId,
          Ano: periodoSelecionado?.Ano,
          Sequencial: periodoSelecionado?.Sequencial,
          TurmaCodigo: registro?.TurmaCodigo,
          Lista: oferta,
          ListaInicial: oferta
        });

        const mapaMateriaOferta = new Map<string, any>();
        oferta.forEach((itemOfert: any) => {
          for (let i = 1; i <= 6; i++) {
            const materiaCodigo = itemOfert[`MateriaCodigo${i}`];
            if (materiaCodigo) {
              mapaMateriaOferta.set(materiaCodigo, itemOfert);
            }
          }
        });

        const mapaMateriaInicial = new Map<string, any>();
        listaInicial.forEach((itemInicial: any) => {
          for (let i = 1; i <= 6; i++) {
            const materiaCodigo = itemInicial[`MateriaCodigo${i}`];
            if (materiaCodigo) {
              mapaMateriaInicial.set(materiaCodigo, itemInicial);
            }
          }
        });

        listaInicial.forEach((itemInicial: any) => {
          for (let i = 1; i <= 6; i++) {
            const materiaCodigoInicial = itemInicial[`MateriaCodigo${i}`];
            if (materiaCodigoInicial) {
              const existeEmOferta = mapaMateriaOferta.has(materiaCodigoInicial);

              if (!existeEmOferta) {
                const paramsDelete = {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Ano: periodoSelecionado?.Ano,
                  Sequencial: periodoSelecionado?.Sequencial,
                  TurmaCodigo: itemInicial?.TurmaCodigo,
                  DisciplinaCodigo: itemInicial.DisciplinaCodigo,
                  MateriaCodigo: materiaCodigoInicial,
                };
                // console.log('Excluindo boletim:', paramsDelete);
                api.delete('/boletim/deleteOferta', { params: paramsDelete })
              }
            }
          }
        });

        oferta.forEach((itemOfert: any) => {
          for (let i = 1; i <= 6; i++) {
            const materiaCodigoOfert = itemOfert[`MateriaCodigo${i}`];
            if (materiaCodigoOfert) {
              const itemInicial = mapaMateriaInicial.get(materiaCodigoOfert);

              if (!itemInicial) {
                alunos.data.forEach((itemAluno: any) => {
                  const novoBoletim = {
                    EmpresaId: periodoSelecionado?.EmpresaId,
                    Ano: periodoSelecionado?.Ano,
                    Sequencial: periodoSelecionado?.Sequencial,
                    Matricula: itemAluno.Matricula,
                    TurmaCodigo: itemOfert?.TurmaCodigo,
                    DisciplinaCodigo: itemOfert.DisciplinaCodigo,
                    MateriaCodigo: materiaCodigoOfert,
                    Tipo: itemOfert.Tipo,
                    Ordem: itemOfert.Ordem,
                    CargaHoraria: itemOfert.Chr,
                  };
                  // console.log('Criando novo boletim:', novoBoletim);
                  api.post('/boletim', novoBoletim)
                })
              } else {
                const envioBoletim = {
                  EmpresaId: periodoSelecionado?.EmpresaId,
                  Ano: periodoSelecionado?.Ano,
                  Sequencial: periodoSelecionado?.Sequencial,
                  DisciplinaCodigo: itemOfert.DisciplinaCodigo,
                  TurmaCodigo: itemOfert.TurmaCodigo,
                  MateriaCodigo: materiaCodigoOfert,
                  Boletim: { MateriaCodigo: materiaCodigoOfert },
                };
                // console.log('Atualizando boletim:', envioBoletim);
                api.put('/boletim/updateOferta', envioBoletim)
              }
            }
          }
        });
      }

      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroGravado });
      reload();
      setShowDialog(false);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
    setLoading(false);
  }

  const cancelar = (e: any) => {
    setShowDelete(false);
    setShowDialog(false);
    reset();
  }

  const setMateria = (coluna: string, dados: any, materia: any) => {
    setListaDisciplinas(listaDisciplinas.map((item: any) => {
      if (item.DisciplinaCodigo === dados.DisciplinaCodigo) {
        switch (coluna) {
          case 'MateriaCodigo1':
            if (item.MateriaCodigo2 != materia && item.MateriaCodigo3 != materia && item.MateriaCodigo4 != materia && item.MateriaCodigo5 != materia && item.MateriaCodigo6 != materia)
              item.MateriaCodigo1 = materia || '';
            else
              toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Matéria já cadastrada.' });
            break;
          case 'MateriaCodigo2':
            if (item.MateriaCodigo1 != materia && item.MateriaCodigo3 != materia && item.MateriaCodigo4 != materia && item.MateriaCodigo5 != materia && item.MateriaCodigo6 != materia)
              item.MateriaCodigo2 = materia || '';
            else
              toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Matéria já cadastrada.' });
            break;
          case 'MateriaCodigo3':
            if (item.MateriaCodigo1 != materia && item.MateriaCodigo2 != materia && item.MateriaCodigo4 != materia && item.MateriaCodigo5 != materia && item.MateriaCodigo6 != materia)
              item.MateriaCodigo3 = materia || '';
            else
              toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Matéria já cadastrada.' });
            break;
          case 'MateriaCodigo4':
            if (item.MateriaCodigo1 != materia && item.MateriaCodigo2 != materia && item.MateriaCodigo3 != materia && item.MateriaCodigo5 != materia && item.MateriaCodigo6 != materia)
              item.MateriaCodigo4 = materia || '';
            else
              toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Matéria já cadastrada.' });
            break;
          case 'MateriaCodigo5':
            if (item.MateriaCodigo1 != materia && item.MateriaCodigo2 != materia && item.MateriaCodigo3 != materia && item.MateriaCodigo4 != materia && item.MateriaCodigo6 != materia)
              item.MateriaCodigo5 = materia || '';
            else
              toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Matéria já cadastrada.' });
            break;
          case 'MateriaCodigo6':
            if (item.MateriaCodigo1 != materia && item.MateriaCodigo2 != materia && item.MateriaCodigo3 != materia && item.MateriaCodigo4 != materia && item.MateriaCodigo5 != materia)
              item.MateriaCodigo6 = materia || '';
            else
              toast.showToast({ severity: 'error', summary: 'Erro', detail: 'Matéria já cadastrada.' });
            break;
        }

        item.Calculo = item.Calculo == '' ? 'M' : item.Calculo;
      }
      return item;
    }));
  }

  const setCalculo = (DisciplinaCodigo: string, value: string) => {
    setListaDisciplinas(listaDisciplinas.map((item: any) => {
      if (item.DisciplinaCodigo === DisciplinaCodigo) {
        item.Calculo = value || '';
      }
      return item;
    }));
  }

  const rowClass = (data: any) => {
    return {
      'bg-orange-500 text-black': data.Calculo === '' && (data.MateriaCodigo1 != '' || data.MateriaCodigo2 != '' || data.MateriaCodigo3 != '' || data.MateriaCodigo4 != '' || data.MateriaCodigo5 != '' || data.MateriaCodigo6 != ''),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pt-3' >

      {loading ? <Loading /> : (
        <>
          <div className='grid'>
            <div className='col-6'>
              <SCInputText label='Turma' disabled value={`${turma?.Codigo} - ${turma?.Descricao}`} />
            </div>
          </div>

          <Divider />

          <Divider />
          <div className='grid'>
            <div className='col-12'>
              {/* <DataTable
                size='small'
                stripedRows
                rowClassName={rowClass}
                value={listaDisciplinas}
                scrollHeight='400px'
                dataKey="id"
                emptyMessage="Nenhum registro."
                responsiveLayout="scroll">
                <Column header="Disciplina" field='DisciplinaDescricao' sortable body={(rowData: any) => (
                  rowData?.DisciplinaCodigo + ' - ' + rowData?.DisciplinaDescricao
                )} />
                <Column header="Matéria 1" field='MateriaCodigo1' body={(rowData: any) => {
                  return <SCDropdown showClear emptyMessage="Nenhuma matéria cadastrada" key={rowData.DisciplinaCodigo + '_1'} optionValue='Codigo' optionLabel='Codigo' value={rowData.MateriaCodigo1} options={lista.filter((x: any) => x.DisciplinaCodigo == rowData.DisciplinaCodigo)} onChange={(sel: any) => setMateria('MateriaCodigo1', rowData, sel.value)} />
                }} />
                <Column header="Matéria 2" field='MateriaCodigo2' body={(rowData: any) => {
                  return <SCDropdown showClear emptyMessage="Nenhuma matéria cadastrada" key={rowData.DisciplinaCodigo + '_2'} optionValue='Codigo' optionLabel='Codigo' value={rowData.MateriaCodigo2} options={lista.filter((x: any) => x.DisciplinaCodigo == rowData.DisciplinaCodigo)} onChange={(sel: any) => setMateria('MateriaCodigo2', rowData, sel.value)} />
                }} />
                <Column header="Matéria 3" field='MateriaCodigo3' body={(rowData: any) => {
                  return <SCDropdown showClear emptyMessage="Nenhuma matéria cadastrada" key={rowData.DisciplinaCodigo + '_3'} optionValue='Codigo' optionLabel='Codigo' value={rowData.MateriaCodigo3} options={lista.filter((x: any) => x.DisciplinaCodigo == rowData.DisciplinaCodigo)} onChange={(sel: any) => setMateria('MateriaCodigo3', rowData, sel.value)} />
                }} />
                <Column header="Matéria 4" field='MateriaCodigo4' body={(rowData: any) => {
                  return <SCDropdown showClear emptyMessage="Nenhuma matéria cadastrada" key={rowData.DisciplinaCodigo + '_4'} optionValue='Codigo' optionLabel='Codigo' value={rowData.MateriaCodigo4} options={lista.filter((x: any) => x.DisciplinaCodigo == rowData.DisciplinaCodigo)} onChange={(sel: any) => setMateria('MateriaCodigo4', rowData, sel.value)} />
                }} />
                <Column header="Matéria 5" field='MateriaCodigo5' body={(rowData: any) => {
                  return <SCDropdown showClear emptyMessage="Nenhuma matéria cadastrada" key={rowData.DisciplinaCodigo + '_5'} optionValue='Codigo' optionLabel='Codigo' value={rowData.MateriaCodigo5} options={lista.filter((x: any) => x.DisciplinaCodigo == rowData.DisciplinaCodigo)} onChange={(sel: any) => setMateria('MateriaCodigo5', rowData, sel.value)} />
                }} />
                <Column header="Matéria 6" field='MateriaCodigo6' body={(rowData: any) => {
                  return <SCDropdown showClear emptyMessage="Nenhuma matéria cadastrada" key={rowData.DisciplinaCodigo + '_6'} optionValue='Codigo' optionLabel='Codigo' value={rowData.MateriaCodigo6} options={lista.filter((x: any) => x.DisciplinaCodigo == rowData.DisciplinaCodigo)} onChange={(sel: any) => setMateria('MateriaCodigo6', rowData, sel.value)} />
                }} />
                <Column header="Cálculo" field='Calculo' body={(rowData: any) => {
                  return <SCDropdown errors={errors} key='Calculo' optionValue='value' optionLabel='descricao' value={rowData.Calculo ? rowData.Calculo : ''} options={FORMULAS_MATERIA} onChange={(sel: any) => setCalculo(rowData.DisciplinaCodigo, sel.value)} />
                }} />
              </DataTable> */}
              <DataTable
                size="small"
                stripedRows
                rowClassName={rowClass}
                value={listaDisciplinas}
                scrollHeight="400px"
                dataKey="id"
                emptyMessage="Nenhum registro."
                responsiveLayout="scroll"
              >
                <Column
                  header="Disciplina"
                  field="DisciplinaDescricao"
                  sortable
                  body={(rowData: any) => (
                    rowData?.DisciplinaCodigo + " - " + rowData?.DisciplinaDescricao
                  )}
                />
                {[...Array(6)].map((_, index) => {
                  const materiaField = `MateriaCodigo${index + 1}`;
                  return (
                    <Column
                      key={materiaField}
                      header={`Matéria ${index + 1}`}
                      field={materiaField}
                      body={(rowData: any) => {
                        const materiaEncontrada = materiasComNotas.some(
                          (nota: any) =>
                            nota.disciplina === rowData.DisciplinaDescricao &&
                            nota.materia === rowData[materiaField]
                        );

                        return (
                          <SCDropdown
                            showClear
                            emptyMessage="Nenhuma matéria cadastrada"
                            key={`${rowData.DisciplinaCodigo}_${index + 1}`}
                            optionValue="Codigo"
                            optionLabel="Codigo"
                            value={rowData[materiaField]}
                            options={lista.filter(
                              (x: any) => x.DisciplinaCodigo === rowData.DisciplinaCodigo
                            )}
                            onChange={(sel: any) =>
                              setMateria(materiaField, rowData, sel.value)
                            }
                            disabled={materiaEncontrada}
                          />
                        );
                      }}
                    />
                  );
                })}
                <Column
                  header="Cálculo"
                  field="Calculo"
                  body={(rowData: any) => {
                    return (
                      <SCDropdown
                        errors={errors}
                        key="Calculo"
                        optionValue="value"
                        optionLabel="descricao"
                        value={rowData.Calculo ? rowData.Calculo : ""}
                        options={FORMULAS_MATERIA}
                        onChange={(sel: any) =>
                          setCalculo(rowData.DisciplinaCodigo, sel.value)
                        }
                      />
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
          <div className='grid'>

            <Divider />

            <div className="flex flex-1 justify-content-between pt-3 px-2">
              <SCButton label="Cancelar" icon="pi pi-times" className="p-button w-auto p-button-danger p-button-outlined" type='button' onClick={cancelar} />
              <SCButton label="Gravar" icon="pi pi-check" className="p-button w-auto p-button-success" type='submit' />
            </div>

          </div>
        </>
      )}
    </form >

  );
};

export default OfertaDisciplinaMateriaForm;
