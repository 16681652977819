import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { ALERTAS } from '../utilities/constantes';
import ICartao from '../interfaces/ICartao';
import util from '../utilities/util';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCDropdown, SCDialog } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import CartaoForm from './CartaoForm';
import AjudaFormTela from './AjudaFormTela';
import IFormaPagamento from '../interfaces/IFormaPagamento';
import Loading from '../components/Loading';

const Cartao = () => {
  const toast = useToast();
  const tarefa = 'cartao';

  let emptyCartao: ICartao = {
    CartoesId: undefined,
    Codigo: '',
    Tipo: '',
    QtdParcelas: 0,
    QtdDiasRecebimento: 0,
    TarifaTipo: 0,
    TarifaValor: 0,
    ContaId: null,
    FonteId: null,
  };

  let defaultFormatoPagamento: IFormaPagamento = {
    FormasPagamentoId: 0,
    Codigo: '',
    Descricao: '',
    Desativado: false,
    Tipo: '',
    Fonte: 0,
    FonteDescricao: '',
  }

  const [formasPagamento, setFormasPagamento] = useState<any>(null);
  const [cartoes, setCartoes] = useState<any>(null);
  const [cartaoDialog, setCartaoDialog] = useState<boolean>(false);
  const [deleteCartao, setDeleteCartao] = useState<boolean>(false);
  const [cartao, setCartao] = useState<ICartao>(emptyCartao);
  const [selectedCartao, setSelectedCartao] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [codigoSelecionado, setCodigoSelecionado] = useState('');
  const [cartoesFiltrados, setCartoesFiltrados] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: defaultFormatoPagamento });
  const { handleSubmit, control, formState: { errors }, watch, setValue, getValues } = methods;

  const load = async () => {
    const result = await api.get('/cartoes/list', { params: { Institucional: 0 } });
    const result2 = await api.get('/formasPagamento/list', { params: { Descricao: getValues()?.Descricao } });
    if (result.data)
      setCartoes(result.data);
    else
      setCartoes([]);

    if (result2.data)
      setFormasPagamento(result2.data.filter((item: { Tipo: any; }) => item.Tipo === '3'));
    else
      setFormasPagamento([]);
  }

  const watchFormaPagamento = watch('Codigo')

  useEffect(() => {
    load().finally(() => clearState());
  }, []);

  // Filtrando 
  useEffect(() => {
    const consultaCartoes = async () => {
      setLoading(true)
      if (codigoSelecionado !== '' && codigoSelecionado !== undefined) {
        const result = await api.get('/cartoes/filtroLista', { params: { Codigo: codigoSelecionado } })
        if (result.data)
          setCartoesFiltrados(result.data);
        else
          setCartoesFiltrados([]);
      } else {
        setCartoesFiltrados(cartoes);
      }
    }

    consultaCartoes()
    setLoading(false)

  }, [codigoSelecionado, cartoes]);

  const openNew = () => {
    setCartao(emptyCartao);
    setSubmitted(false);
    setCartaoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCartaoDialog(false);
  };
  const editar = (item: any) => {
    setCartao(item);
    setCartaoDialog(true);
  };

  const confirmDelete = (item: any) => {
    setCartao(item);
    setDeleteCartao(true);
    setCartaoDialog(true);
  };

  const deletar = async () => {
    try {
      const resp = await api.delete('/cartoes', { params: { CartoesId: cartao.CartoesId } });
      toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
      load();
      setDeleteCartao(false);
      setCartaoDialog(false);
      setCartao(emptyCartao);

    } catch (e: any) {
      console.log(e.response.data)
      toast.showToast({ severity: 'error', summary: 'Erro', detail: e?.response?.data?.error });
    }
  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const clearState = () => {
    dt?.current?.reset();
  }

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Cartão</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className='pt-2'>
            <div className='grid'>
              <div className="col-12 md:col-6">
                <SCDropdown
                  name="Codigo"
                  options={formasPagamento}
                  optionLabel='Descricao'
                  optionValue='Codigo'
                  placeholder='Selecione a Forma de Pagamento'
                  style={{ width: 400 }}
                  value={watchFormaPagamento}
                  onChange={(e) => {
                    setCodigoSelecionado(e.value)
                    setValue('Codigo', e.target.value)
                  }}
                />
              </div>
              <div className='col-12 md:col-6 flex align-items-end justify-content-around'>
                {/* <Button className="p-button-info p-button-outlined " type='button' onClick={load} disabled={true}
                  style={{ backgroundColor: "#fff", borderColor: "#fff" }} /> */}
                <Button label='Novo' icon="pi pi-file-o" className="p-button-success" type='button' onClick={openNew} />

                <SlideMenu ref={menu} model={opcoes} popup viewportHeight={opcoes.length * 37} menuWidth={175}></SlideMenu>
                <Button label='Opções' className='p-button-secondary' type="button" icon="pi pi-plus" onClick={openMenu}></Button>
              </div>
            </div>
          </form>
          <Divider />

          {loading ? (
            <Loading full={false} />
          ) : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={cartoesFiltrados}
              onSelectionChange={(e) => setSelectedCartao(e.value)}
              dataKey="id"
              stateKey='tabCartao'
              stateStorage='local'
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">

              <Column
                field="Maquina"
                header="Máquina"
                sortable
                headerStyle={{ width: 120 }}
              />
              <Column
                field="FonteCodigo"
                header="Fonte"
                sortable
                headerStyle={{ width: 120 }}
              />
              <Column
                field="FormaDescricao"
                header="Cartão"
                sortable
                headerStyle={{ width: 100 }}
              />
              <Column
                hidden={!util.isDesktop()}
                field="Tipo"
                header="Tipo"
                align="center"
                body={(rowData: ICartao) => (
                  rowData.Tipo == "C" ? 'Crédito' : rowData.Tipo == "D" ? 'Débito' : rowData.Tipo == "X" ? 'PIX' : "Parcelado"
                )}
                headerStyle={{ width: 140 }}
              />

              <Column
                hidden={!util.isDesktop()}
                field="QtdParcelas"
                header="Qtd. Máxima de Parcelas"
                headerStyle={{ width: 100 }}
                align="center"
              />

              {/* <Column
              field="TarifaTipo"
              header="Tarifa"
              body={(rowData: ICartao) => (
                rowData.TarifaTipo === 1 ? 'Percentual (%)' : 'Valor (R$)'
              )}
              headerStyle={{ width: 100 }}
            /> */}
              <Column
                hidden={!util.isDesktop()}
                field="QtdDiasRecebimento"
                header="Qtd. Dias para Recebimento"
                headerStyle={{ width: 140 }}
                align="center"
              />

              <Column
                field="TarifaValor"
                header="Tarifa"
                body={(rowData: ICartao) => (
                  rowData.TarifaTipo === 1 ? `${rowData.TarifaValor}` + ' %' : 'R$ ' + `${rowData.TarifaValor?.toFixed(2).replace('.', ',')}`
                )}
              />
              <Column body={(rowData: any) => {
                return (
                  <div className="actions flex align-items-center justify-content-between">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDelete(rowData)} />
                  </div>
                );
              }} style={{ width: 90 }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={cartaoDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${cartao.CartoesId === undefined ? 'Cadastrando ' : (deleteCartao ? 'Excluíndo' : 'Alterando')} Cartão`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <CartaoForm
              setCartaoDialog={setCartaoDialog}
              cartao={cartao}
              reload={load}
              deleteCartao={deleteCartao}
              setDeleteCartao={setDeleteCartao}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default Cartao;
