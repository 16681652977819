import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import api from "../services/api";
import { IAlunoForm } from '../interfaces/IAlunos';
import { useAuth } from '../providers/auth';
import Loading from '../components/Loading';
import { IAlunosPeriodo } from '../interfaces/IAlunosPeriodo';
import { SCCalendar, SCCheckbox, SCDropdown, SCFieldset, SCInputMask, SCInputNumber, SCInputText } from '../components';
import { Divider } from 'primereact/divider';
import AlunoResumo from '../components/AlunoResumo';
import { addDays } from 'date-fns';

interface Props {
  aluno: IAlunoForm;
  alunoPeriodo: IAlunosPeriodo
  mensaliade?: any;
  pagamentos?: any[];
  setShowDialog(param: boolean): void;
  deletar(): void;
  reload(): void;
  showDelete: boolean;
  setShowDelete(param: boolean): void;
  loading: boolean;
  setLoading(param: boolean): void;
}

const RecebimentoMensalidade: React.FC<Props> = ({ aluno, alunoPeriodo, pagamentos, reload, deletar, showDelete, setShowDelete, setLoading, loading }) => {
  // é utilizado para o cadastro da ajuda da tela (todas as telas devem ter essa informação)
  // por padrão vamos utilizar o mesmo nome do componente
  const tarefa = 'AlunoForm';
  const { periodoSelecionado } = useAuth();
  const { control, formState: { errors }, getValues, setValue, reset, watch } = useFormContext();

  const [isCheque, setIsCheque] = useState<boolean>(false);
  const [isCartao, setIsCartao] = useState<boolean>(false);
  const [contas, setContas] = useState<any[]>([]);
  const [formasPag, setFormasPag] = useState<any[]>([]);
  const [fontes, setFontes] = useState<any[]>([]);

  const [codigoEstorno, setCodigoEstorno] = useState<any[]>([]);
  const [desabilitaCampos, setDesabilitaCampos] = useState<boolean>(false);
  const [desabilita, setDesabilita] = useState<boolean>(false);
  const [deleteRecebimentos, setDeleteRecebimentos] = useState<boolean>(showDelete);
  const [cartoesFiltrados, setCartoesFiltrados] = useState<any[]>([]);
  const [desabilitaNumeroDocumentoPag, setDesabilitaNumeroDocumentoPag] = useState<boolean>(false);
  const [maquinaSelecionada, setMaquinaSelecionada] = useState<any>(null);
  const [centroDeCusto, setCentroDeCusto] = useState<any>(null);
  const [contaId, setContaId] = useState<any>(null);
  const [qtdDiasRecebimento, setQtdDiasRecebimento] = useState<any>(null);

  const watchFormaPagamento = watch('FormasPagamentoId');
  const watchDataPagamento = watch('DataPagamento');
  const watchValor = watch('Valor')
  const watchValorPagar = watch('ValorPagar')
  const watchValorMulta = watch('ValorMulta')
  const watchValorJuros = watch('ValorJuros')
  const watchPagamentoSemValor = watch('PagamentoSemValor')
  const watchCartao = watch('CartoesId')
  const watchValorPagamento = watch('ValorPagamento')
  const watchTarifaTipo = watch('TarifaTipo')

  const loadConta = async () => {
    try {
      const { data } = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
      setValue('PlanoContas', data || []);
      setContas(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      setContas([]);
    }
  };

  const loadFormasPagamento = async () => {
    try {
      const { data } = await api.get('/formasPagamento/combo');
      setFormasPag(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Formas de Pagamento:', error);
      setFormasPag([]);
    }
  };

  const loadFontes = async () => {
    try {
      const { data } = await api.get('/fontes/fonteAtivas', { params: { EmpresaId: periodoSelecionado?.EmpresaId } });
      setFontes(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Fontes:', error);
      setFontes([]);
    }
  };

  const loadCodigoEstorno = async () => {
    try {
      const { data } = await api.get('/codigosestorno/combo');

      setCodigoEstorno(data || [])
    } catch (error) {
      console.error('Erro ao carregar os Códigos de Estorno:', error);
      setCodigoEstorno([]);
    }
  }

  const mensaliade = getValues('Mensalidade');

  useEffect(() => {
    loadConta()
    loadFormasPagamento()
    loadFontes()
    loadCodigoEstorno()

    setValue('DataPagamento', new Date());    
    setValue('FormasPagamentoId', 1);    
    setValue('ValorAPagar', mensaliade?.ValorMensalidade-(mensaliade?.ValorPago||0))
  }, []);

  useEffect(() => {
    if (!watchFormaPagamento || formasPag.length < 1) return;

    const formaPagamentoSelecionada = formasPag.find((fp: any) => fp.FormasPagamentoId === watchFormaPagamento);

    const isCartao = formaPagamentoSelecionada?.Tipo === '3';
    const codigoCartao = formaPagamentoSelecionada?.Codigo;
    const isMoney = formaPagamentoSelecionada?.Tipo === '1'

    setIsCartao(isCartao);
    setDesabilitaNumeroDocumentoPag(!isCartao);

    if (!watchCartao) {
      setValue('CartaoSelecionado', undefined);
      setValue('TarifaTipo', null);
      setValue('TarifaValor', '');
      setValue('FonteId', watchFormaPagamento && formaPagamentoSelecionada.Codigo === '00' ? formaPagamentoSelecionada.Fonte : null);
      setValue('NumeroDocumento', '');
    }

    const buscaCartao = async () => {
      if (!codigoCartao) {
        setCartoesFiltrados([]);
        return;
      }

      try {
        const { data } = await api.get('/cartoes/filtroLista', { params: { Codigo: codigoCartao } });

        if (data?.length > 0) {
          const cartoesInfo = data.map((cartao: any) => ({
            CartoesId: cartao.CartoesId,
            Maquina: `${cartao.Maquina} - ${cartao.FonteCodigo} - ${cartao.FormaDescricao} - ${cartao.Tipo === 'C' ? 'Crédito' : cartao.Tipo === 'D' ? 'Débito' : cartao.Tipo === 'P' ? 'Parcelado' : 'Desconhecido'
              } - ${cartao.QtdParcelas} Parcela(s) - ${cartao.QtdDiasRecebimento} dia(s)`
          }));

          setCartoesFiltrados(cartoesInfo);

          const cartaoSelecionado = data.find((cartao: any) => cartao.CartoesId === watchCartao);

          if (cartaoSelecionado) {
            setValue('CartaoSelecionado', cartaoSelecionado);
            setValue('TarifaTipo', cartaoSelecionado.TarifaTipo || null);
            setValue('TarifaValor', cartaoSelecionado.TarifaValor);
            setValue('FonteId', cartaoSelecionado.FonteId);
            setMaquinaSelecionada(cartaoSelecionado.Maquina || null);
            setCentroDeCusto(cartaoSelecionado.CentroCustoId || null);
            setContaId(cartaoSelecionado.ContaId || null);
            setQtdDiasRecebimento(cartaoSelecionado.QtdDiasRecebimento || null);

            const dataProjetada = watchDataPagamento && watchCartao ? addDays(watchDataPagamento, cartaoSelecionado.QtdDiasRecebimento) : null
            setValue('DataBomPara', dataProjetada)
            setValue('DataDisponivel', dataProjetada)
          }

        } else {
          setCartoesFiltrados([]);
        }
      } catch (error) {
        console.error('Erro ao buscar cartão:', error);
        setCartoesFiltrados([]);
      }
    };

    // Caso a FORMA DE PAGTO for ** DINHEIRO **
    setValue('DataBomPara', isMoney ? watchDataPagamento || null : null);
    setValue('DataDisponivel', isMoney ? watchDataPagamento || null : null);

    if (isCartao) {
      buscaCartao();
    } else {
      setValue('CartoesId', null);
      setCartoesFiltrados([]);
    }

  }, [watchFormaPagamento, formasPag, watchCartao]);

  useEffect(() => {
    setDesabilita(!watchDataPagamento);

    if (!mensaliade?.DataPagamento) {
      if (watchDataPagamento) {
        setValue('FormasPagamentoId', formasPag?.find((f: any) => f.Tipo === '1')?.FormasPagamentoId || null);
        setValue('FonteId', fontes?.find((f: any) => f.Tipo == 0)?.FonteId || null);
      } else {
        setValue('FormasPagamentoId', null);
        setValue('FonteId', null);
      }

      if (!isCartao) {
        setValue('FonteId', null);
      }
    }

  }, [watchDataPagamento, formasPag, fontes]);

  useEffect(() => {
    if (!watchPagamentoSemValor) {
      const valorPagamento = (watchValorPagar || 0) + (watchValorMulta || 0) + (watchValorJuros || 0);

      if (watchDataPagamento) {
        setValue('ValorPagamento', valorPagamento);
      } else {
        setValue('ValorPagamento', 0);
        setValue('ValorMulta', 0);
        setValue('ValorJuros', 0);
      }
    } else {
      setValue('ValorPagamento', 0);
      setValue('ValorMulta', 0);
      setValue('ValorJuros', 0);
    }
  }, [watchDataPagamento, watchValorPagar, watchValorMulta, watchValorJuros, watchPagamentoSemValor]);


  return (
    <>
      {loading && <Loading full={true} />}
      <div className='grid'>
        <AlunoResumo aluno={aluno} mostraPlanoPagamento={true} mostraFoto mostraTurma alteraFoto/>
      </div>
      <Divider />
      <div className='grid'>
        <div className="col-4 md:col-1">
          <SCInputText
            control={control}
            errors={errors}
            className='readonly-light'
            style={{ textTransform: 'uppercase' }}
            name="Mensalidade.Parcela"
            label='Parcela'
            disabled
          />
        </div>
        <div className="col-8 md:col-6 ">
          <SCInputText
            control={control}
            errors={errors}
            label='Descrição'
            className='readonly-light'
            style={{ textTransform: 'uppercase' }}
            name="Mensalidade.ParcelaDescricao"
            disabled
          />
        </div>
        <div className="col-4 md:col-2">
          <SCInputText
            className='readonly-light'
            label='Data do Venicmento'
            control={control}
            errors={errors}
            name="Mensalidade.DataVencimentoFormatado"
            disabled
          />
        </div>
      </div>
      <div className='grid'>
        <div className="col-4 md:col-2">
          <SCInputNumber
            className='readonly-light'
            control={control}
            errors={errors}
            name="Mensalidade.ValorMensalidade"
            label='Valor Mensalidade'      
            allowEmpty={false}
            mode="decimal"
            minFractionDigits={2}
            min={0}
            locale='BRL'
            disabled
          />
        </div>
        <div className="col-8 md:col-2">
          <SCInputNumber
            control={control}
            errors={errors}
            className='readonly-light'
            name='ValorAdiantado'            
            label='Valor Adiantado'
            allowEmpty={false}
            mode="decimal"
            minFractionDigits={2}
            min={0}            
            locale='BRL'
            disabled
          />
        </div>
        <div className="col-4 md:col-2">
          <SCInputNumber
              control={control}
              errors={errors}
              className='readonly-light'
              label='Valor a Pagar'            
              name="ValorAPagar"
              allowEmpty={false}
              mode="decimal"
              minFractionDigits={2}
              min={0}            
              locale='BRL'
              disabled
              inputClassName='input-destack'
            />
        </div>
      </div>

      <Divider />
      
      <SCFieldset legend='Pagamento' className='col-12 m-0'>
        <div className='grid align-items-center' style={{ marginTop: -20 }}>
          <div className="col-12 md:col-2">
            <SCCalendar
              label='Data'
              errors={errors}
              control={control}
              name='DataPagamento'
              dateFormat='dd/mm/yy'
              maxDate={new Date()}
              disabled={deleteRecebimentos || desabilitaCampos}
              showIcon
            />
          </div>

          <div className="col-12 md:col-5 mt-2 mb-2 md:mt-4">
            <SCCheckbox
              control={control}
              name="PagamentoSemValor"
              label='Pagamento sem valor recebido (Cortesia)'
              disabled={deleteRecebimentos || desabilitaCampos || desabilita}
            />
          </div>
        </div>

        <div className='grid flex align-items-center' style={{ marginTop: -10 }}>
          <div className="col-12 md:col-2">
            <SCInputNumber
              autoFocus={true}                         
              name='ValorMulta'
              control={control}
              errors={errors}
              label='Multa'
              allowEmpty={false}
              disabled={deleteRecebimentos || desabilitaCampos || desabilita || watchPagamentoSemValor}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              // required={validaCampos}
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputNumber
              name='ValorJuros'
              control={control}
              errors={errors}
              label='Juros'
              allowEmpty={false}
              disabled={deleteRecebimentos || desabilitaCampos || desabilita || watchPagamentoSemValor}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              // required={validaCampos}
            />
          </div>

          <div className="col-12 md:col-2">
            <SCInputNumber
              name='ValorDesconto'
              control={control}
              errors={errors}
              label='Desconto'
              allowEmpty={false}
              disabled={deleteRecebimentos || desabilitaCampos || desabilita || watchPagamentoSemValor}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              // required={validaCampos}
            />
          </div>
          <div className="col-12 md:col-2">
            <SCInputNumber
              name='ValorPagamento'
              control={control}
              errors={errors}
              label='Valor Pago'
              allowEmpty={false}
              disabled={deleteRecebimentos || desabilitaCampos || desabilita || watchPagamentoSemValor}
              min={0}
              max={10000}
              minFractionDigits={2}
              maxFractionDigits={2}
              currency='BRL'
              mode='currency'
              locale='pt-BR'
              required={!!watchDataPagamento && watchValorPagamento > 0}
            />
          </div>

          <div className="col-12 md:col-4">
            <SCDropdown
              control={control}
              errors={errors}
              name="FormasPagamentoId"
              label='Forma de Pagamento'
              options={formasPag}
              optionLabel='Descricao'
              optionValue='FormasPagamentoId'
              disabled={deleteRecebimentos || desabilitaCampos || desabilita}
            />
          </div>
        </div>

        <div className='grid flex align-items-end'>

          <div className="col-12 md:col-5">
            <SCDropdown
              control={control}
              errors={errors}
              name="CartoesId"
              label='Cartão'
              options={cartoesFiltrados}
              optionLabel='Maquina'
              optionValue='CartoesId'
              disabled={deleteRecebimentos || desabilitaCampos || desabilita || !isCartao}
              style={{ width: "100%" }}
            />
          </div>

          <div className="col-12 md:col-1">
            <SCInputText
              control={control}
              errors={errors}
              name="TarifaValor"
              label={watchTarifaTipo == 1 ? 'Tarifa(%)' : watchTarifaTipo == 2 ? 'Tarifa(R$)' : 'Tarifa'}
              disabled
              style={{ color: "#000", fontWeight: 'bold', width: "100%" }}
            />
          </div>
          <div className="col-12 md:col-2">
            <SCInputText
              control={control}
              errors={errors}
              name="NumeroDocumento"
              label='Número do Documento'
              disabled={deleteRecebimentos || desabilitaCampos || !watchCartao || !isCartao}
              required={watchCartao ? true : false}
            />
          </div>

          <div className="col-12 md:col-4">
            <SCDropdown
              control={control}
              errors={errors}
              name="FonteId"
              label='Fonte'
              options={fontes}
              optionLabel='Codigo'
              optionValue='FonteId'
              disabled={deleteRecebimentos || desabilitaCampos || desabilita}              
            />
          </div>
        </div>
        <div className='grid flex align-items-center'>
          <div className="col-12 md:col-2">
            <SCCalendar
              errors={errors}
              label='Bom Para'
              control={control}
              name='DataBomPara'
              dateFormat='dd/mm/yy'
              disabled={deleteRecebimentos || desabilitaCampos || desabilita}
              showIcon
            />
          </div>

          <div className="col-12 md:col-2">
            <SCCalendar
              errors={errors}
              label='Disponível Em'
              control={control}
              name='DataDisponivel'
              dateFormat='dd/mm/yy'
              disabled={deleteRecebimentos || desabilitaCampos || desabilita}
              showIcon
            />
          </div>
        </div>

      </SCFieldset>
    </>
  );
};

export default RecebimentoMensalidade;
