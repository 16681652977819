import { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useAuth } from '../providers/auth';
import api from "../services/api";
import { useToast } from '../context/ToastContext';
import { Divider } from 'primereact/divider';
import { useForm } from 'react-hook-form';
import { SCCalendar, SCDialog, SCDropdown, SCFieldset, SCInputText, SCRadioButton } from '../components';
import { SlideMenu } from 'primereact/slidemenu';
import AjudaFormTela from './AjudaFormTela';
import Loading from '../components/Loading';
import { format } from 'date-fns';
import IOutrosRecebimentos from '../interfaces/IOutrosRecebimentos';
import util from '../utilities/util';
import OutrosRecebimentosForm from './OutrosRecebimentosForm';
import { ALERTAS } from '../utilities/constantes';

const OutrosRecebimentos = () => {
  const toast = useToast();
  const { periodoSelecionado, usuario } = useAuth();
  const tarefa = 'OutrosRecebimentosForm';

  let emptyOutrosRecebimentos: IOutrosRecebimentos = {
    OutrosRecebimentosId: undefined,
    EmpresaId: periodoSelecionado?.EmpresaId,
    Ano: periodoSelecionado?.Ano,
    Sequencial: periodoSelecionado?.Sequencial,
    NumeroRecibo: 0,
    Matricula: 0,
    Nome: '',
    DataVencimento: null,
    Valor: 0,
    ValorPagar: 0,
    ValorMulta: 0,
    ValorJuros: 0,
    ValorDesconto: 0,
    DataPagamento: null,
    DataDisponivel: null,
    DataBomPara: null,
    ValorPagamento: 0,
    PlanoContaId: null,
    ConvenioId: 0,
    Referencia: '',
    OperadorLancamento: '',
    OperadorAtualizacao: '',
    NumeroDocumento: '',
    FormasPagamentoId: null,
    FonteId: null,
    FlgMeuCaixa: null,
    Observacao: '',
    ChequeResgate: 0,
    DataInicialVencimento: null,
    DataFinalVencimento: null,
    DataInicialPagamento: null,
    DataFinalPagamento: null,
    NomeFiltro: '',
    FiltroOptions: 0,
    PagamentoSemValor: false,
    CartoesId: null,
    TarifaValor: null,
    TarifaTipo: null,
    CodigosEstornoId: null
  };

  const [outrosRecebimentos, setOutrosRecebimentos] = useState<any>(null);
  const [todosRegistros, setTodosRegistros] = useState<any>(null);
  const [outrosRecebimentosDialog, setOutrosRecebimentosDialog] = useState<boolean>(false);
  const [deleteOutrosRecebimentos, setDeleteOutrosRecebimentos] = useState<boolean>(false);
  const [outroRecebimento, setOutroRecebimento] = useState<IOutrosRecebimentos>(emptyOutrosRecebimentos);
  const [selectedOutroRecebimento, setSelectedOutrorecebimento] = useState<any>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmaEstorno, setConfirmaEstorno] = useState<boolean>(false);
  const [contas, setContas] = useState<any>(null);

  const dt = useRef<any>(null);
  const menu = useRef<any>(null);

  const methods = useForm({ defaultValues: emptyOutrosRecebimentos });
  const { handleSubmit, control, formState: { errors }, getValues, setValue, watch } = methods;

  const watchDataInicialVencimento = watch('DataInicialVencimento');
  const watchDataFinalVencimento = watch('DataFinalVencimento');
  const watchDataInicialPagamento = watch('DataInicialPagamento');
  const watchDataFinalPagamento = watch('DataFinalPagamento');
  const watchFiltro = watch('FiltroOptions');

  const load = async () => {
    setLoading(true)

    const dataVencInicial = getValues().DataInicialVencimento;
    const dataIV = dataVencInicial ? format(dataVencInicial, 'yyyy-MM-dd') : '';

    const dataVencFinal = getValues().DataFinalVencimento;
    const dataFV = dataVencFinal ? format(dataVencFinal, 'yyyy-MM-dd') : '';

    const dataPagInicial = getValues().DataInicialPagamento;
    const dataIP = dataPagInicial ? format(dataPagInicial, 'yyyy-MM-dd') : '';

    const dataPagFinal = getValues().DataFinalPagamento;
    const dataFP = dataPagFinal ? format(dataPagFinal, 'yyyy-MM-dd') : '';

    let resp;
    resp = await api.get('/outrosRecebimentos/filtroLista', {
      params: {
        EmpresaId: periodoSelecionado?.EmpresaId,
        Ano: periodoSelecionado?.Ano,
        Sequencial: periodoSelecionado?.Sequencial,
        DataIV: dataIV,
        DataFV: dataFV,
        DataIP: dataIP,
        DataFP: dataFP,
        Nome: getValues()?.Nome,
        Filtro: watchFiltro,
        PlanoContaId: watchConta
      }
    })

    if (resp.data) {
      setLoading(false)
      setOutrosRecebimentos(resp.data)
    } else {
      setLoading(false)
      setOutrosRecebimentos([])
    }
  }

  const loadConta = async () => {
    try {
      const { data } = await api.get('/planoContas/list', { params: { Tipo: 'ENTRADA' } });
      setContas(data || []);
    } catch (error) {
      console.error('Erro ao carregar as Contas:', error);
      setContas([]);
    }
  };

  const watchConta = watch('PlanoContaId')

  useEffect(() => {
    const validateDates = (startDate: any, endDate: any, setStartValue: any) => {
      if (startDate && endDate && startDate > endDate) {
        setStartValue(endDate);
        toast.showToast({
          severity: 'error',
          summary: 'Atenção',
          detail: 'Período Inválido!',
        });
      }
    };

    validateDates(watchDataInicialVencimento, watchDataFinalVencimento,
      (value: any) => setValue('DataInicialVencimento', value)
    );

    validateDates(watchDataInicialPagamento, watchDataFinalPagamento,
      (value: any) => setValue('DataInicialPagamento', value)
    );
  }, [watchDataInicialVencimento, watchDataFinalVencimento,
    watchDataInicialPagamento, watchDataFinalPagamento]);

  useEffect(() => {
    if (watchDataInicialVencimento && !watchDataFinalVencimento) {
      setValue('DataFinalVencimento', watchDataInicialVencimento);
    }
  }, [watchDataInicialVencimento, watchDataFinalVencimento]);

  useEffect(() => {
    if (watchDataInicialPagamento && !watchDataFinalPagamento) {
      setValue('DataFinalPagamento', watchDataInicialPagamento);
    }
  }, [watchDataInicialPagamento, watchDataFinalPagamento]);

  useEffect(() => {
    if (watchDataFinalVencimento && !watchDataInicialVencimento) {
      setValue('DataInicialVencimento', watchDataFinalVencimento);
    }

    if (watchDataFinalPagamento && !watchDataInicialPagamento) {
      setValue('DataInicialPagamento', watchDataFinalPagamento);
    }
  }, [watchDataFinalVencimento, watchDataFinalPagamento]);

  useEffect(() => {
    setValue('FiltroOptions', 4)
    load();
    loadConta();
  }, []);

  const openNew = () => {
    setOutroRecebimento(emptyOutrosRecebimentos);
    setSubmitted(false);
    setOutrosRecebimentosDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setOutrosRecebimentosDialog(false);
  };

  const editar = (outpag: any) => {
    setOutrosRecebimentosDialog(true);

    // Converte as datas no documento
    outpag.DataVencimento = outpag.DataVencimento ? new Date(outpag.DataVencimento) : null;
    outpag.DataDisponivel = outpag.DataDisponivel ? new Date(outpag.DataDisponivel) : null;
    outpag.DataPagamento = outpag.DataPagamento ? new Date(outpag.DataPagamento) : null;
    outpag.DataBomPara = outpag.DataBomPara ? new Date(outpag.DataBomPara) : null;

    setOutroRecebimento(outpag);
  };


  const confirmDelete = (outpag: any) => {
    setOutroRecebimento(outpag);
    setDeleteOutrosRecebimentos(true);
    setOutrosRecebimentosDialog(true);

    // Converte as datas
    outpag.DataVencimento = outpag.DataVencimento ? new Date(outpag.DataVencimento) : null;
    outpag.DataDisponivel = outpag.DataDisponivel ? new Date(outpag.DataDisponivel) : null;
    outpag.DataBomPara = outpag.DataBomPara ? new Date(outpag.DataBomPara) : null;
    outpag.DataPagamento = outpag.DataPagamento ? new Date(outpag.DataPagamento) : null;
  };

  const confirmEstorno = (outpag: any) => {
    setOutroRecebimento(outpag);
    setConfirmaEstorno(true);
    setOutrosRecebimentosDialog(true);

    // Converte as datas
    outpag.DataVencimento = outpag.DataVencimento ? new Date(outpag.DataVencimento) : null;
    outpag.DataDisponivel = outpag.DataDisponivel ? new Date(outpag.DataDisponivel) : null;
    outpag.DataBomPara = outpag.DataBomPara ? new Date(outpag.DataBomPara) : null;
    outpag.DataPagamento = outpag.DataPagamento ? new Date(outpag.DataPagamento) : null;
  };

  const deletar = async () => {
    toast.showToast({ severity: 'success', summary: 'Atenção', detail: ALERTAS.registroExcluido });
    load();
    setDeleteOutrosRecebimentos(false);
    setOutrosRecebimentosDialog(false);
    setOutroRecebimento(emptyOutrosRecebimentos);
  };

  const onSubmit = async (data: any) => {
    load();
  }

  const opcoes = [
    {
      label: 'Imprimir',
      icon: 'pi pi-print',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Exp. para Excel',
      icon: 'pi pi-file-excel',
      command: () => {
        dt.current.exportCSV();
      }
    },
    {
      label: 'Ajuda',
      icon: 'pi pi-question-circle',
      command: () => {
        dt.current.exportCSV();
      }
    },
  ];

  const openMenu = (event: any) => {
    menu?.current?.toggle(event)
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <div className='flex justify-content-center align-items-center p-2 border-round-top-md text-white' style={{ backgroundColor: '#0772B3' }}>
            <h5 className="m-0 flex flex-1 align-items-center justify-content-center">Outros Recebimentos</h5>
            <AjudaFormTela tarefa={tarefa} campo='' />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="pt-2">
            <SCFieldset legend="Filtros" className="col-12 mt-1 p-0">
              <div className="grid">
                {/* Div principal contendo filtros e botões */}
                <div className="col-12 md:col-12">
                  <div className="grid">
                    {/* Div contendo os filtros */}
                    <div className="col-12 lg:col-10">
                      <div className="grid">
                        <div className="col-12 lg:col-6">
                          <SCInputText
                            control={control}
                            errors={errors}
                            name="Nome"
                            placeholder="Digite o nome"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div className="col-12 md:col-12 lg:col-6">
                          <SCDropdown
                            name="PlanoContaId"
                            placeholder='Selecione a Conta'
                            options={contas}
                            optionLabel='ContaDescricao'
                            optionValue='PlanoContaId'
                            onChange={(e: any) => setValue('PlanoContaId', e.target.value)}
                            value={watchConta}
                            style={{ width: "100%" }}

                          />
                        </div>

                        <div className="grid col-12 md:col-12">
                          <div className="col-12 sm:col-6 md:col-3">
                            <SCCalendar
                              control={control}
                              name="DataInicialVencimento"
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              placeholder="Vencimento Inicial"
                              showIcon
                            />
                          </div>

                          <div className="col-12 sm:col-6 md:col-3">
                            <SCCalendar
                              control={control}
                              name="DataFinalVencimento"
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              placeholder="Vencimento Final"
                              showIcon
                            />
                          </div>

                          <div className="col-12 sm:col-6 md:col-3">
                            <SCCalendar
                              control={control}
                              name="DataInicialPagamento"
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              placeholder="Pagamento Inicial"
                              inputStyle={{ background: "#c9e1f7" }}
                              showIcon
                            />
                          </div>

                          <div className="col-12 sm:col-6 md:col-3">
                            <SCCalendar
                              control={control}
                              name="DataFinalPagamento"
                              dateFormat="dd/mm/yy"
                              mask="99/99/9999"
                              placeholder="Pagamento Final"
                              inputStyle={{ background: "#c9e1f7" }}
                              showIcon
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex col-12 justify-content-center">
                        <div className="flex justify-content-center gap-3">
                          <SCRadioButton
                            control={control}
                            name="FiltroOptions"
                            label="Somente Pagos"
                            value={1}
                          />
                          <SCRadioButton
                            control={control}
                            name="FiltroOptions"
                            label="Somente Vencidos"
                            value={2}
                          />
                          <SCRadioButton
                            control={control}
                            name="FiltroOptions"
                            label="Somente a Receber"
                            value={3}
                          />
                          <SCRadioButton
                            control={control}
                            name="FiltroOptions"
                            label="Todos"
                            value={4}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Div contendo os botões alinhados à direita */}
                    <div className="col-12 lg:col-2 flex flex-column align-items-end">
                      <Button
                        label="Pesquisar"
                        icon="pi pi-search"
                        className="p-button-info p-button-outlined mb-2"
                        type="button"
                        onClick={load}
                        style={{ width: "100%" }}
                      />
                      <Button
                        label="Novo"
                        icon="pi pi-file-o"
                        className="p-button-success mb-2"
                        type="button"
                        onClick={openNew}
                        style={{ width: "100%" }}
                      />
                      <Button
                        label="Opções"
                        className="p-button-secondary"
                        type="button"
                        icon="pi pi-plus"
                        onClick={openMenu}
                        style={{ width: "100%" }}
                      />
                      <SlideMenu
                        ref={menu}
                        model={opcoes}
                        popup
                        viewportHeight={opcoes.length * 37}
                        menuWidth={175}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SCFieldset>
          </form>

          <Divider />

          {loading ? <Loading full={false} /> : (
            <DataTable
              size='small'
              stripedRows
              ref={dt}
              value={outrosRecebimentos}
              onSelectionChange={(e) => setSelectedOutrorecebimento(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} a {last} de {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="Nenhum registro encontrado."
              header={null}
              responsiveLayout="scroll">
              <Column field="NumeroRecibo" header="Nº Recibo" sortable headerStyle={{ width: '10%', fontSize: 12 }} bodyStyle={{ fontSize: 11 }} ></Column>
              <Column field="Nome" header="Nome" sortable headerStyle={{ width: '35%', fontSize: 12 }} bodyStyle={{ fontSize: 11 }}></Column>
              <Column field="Referencia" header="Referente" sortable headerStyle={{ width: '30%', fontSize: 12 }} bodyStyle={{ fontSize: 11 }}></Column>
              <Column field="Valor" header="Valor" align='center' headerStyle={{ width: '30%', fontSize: 12 }} bodyStyle={{ fontSize: 11 }} sortable body={(rowData: any) => {
                return (
                  <span>R$ {rowData.Valor?.toFixed(2).replace('.', ',')}</span>
                );
              }} style={{ width: '15%' }}
              />

              <Column field="DataVencimento" header="Vencimento" sortable body={(rowData: any) => {
                if (rowData.DataVencimento) {
                  return (
                    <span>{format(rowData.DataVencimento, 'dd/MM/yyyy')}</span>
                  );
                }
                return null;
              }} style={{ width: '10%' }} bodyStyle={{ fontSize: 11 }} headerStyle={{ fontSize: 12 }} />

              <Column field="DataPagamento" header="Data Pagto" sortable body={(rowData: any) => {
                if (rowData.DataPagamento) {
                  return (
                    <span>{format(rowData.DataPagamento, 'dd/MM/yyyy')}</span>
                  );
                }
                return null;
              }} style={{ width: '12%' }} bodyStyle={{ fontSize: 11 }} headerStyle={{ fontSize: 12 }} />

              <Column
                headerStyle={{ width: '30%' }}
                body={(rowData: any) => {
                  return (
                    <div className="actions flex align-items-center justify-content-start" style={{ gap: '5px' }}>
                      <Button icon="pi pi-pencil" className="p-button-rounded p-button-info" onClick={() => editar(rowData)} />
                      <Button className="p-button-rounded p-button-warning" onClick={() => confirmEstorno(rowData)} disabled={!rowData.DataPagamento}
                        label='Estorno' />
                      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" disabled={rowData.DataPagamento} onClick={() => confirmDelete(rowData)} />
                    </div>
                  );
                }}
              />
            </DataTable>
          )}

          <SCDialog maximized={!util.isDesktop()} visible={outrosRecebimentosDialog} style={{ width: util.isDesktop() ? '80%' : '100%' }} closable={!util.isDesktop()}
            header={`${outroRecebimento.OutrosRecebimentosId === undefined ? 'Cadastrando ' : outroRecebimento.DataPagamento ? 'Consultando' : (deleteOutrosRecebimentos ? 'Excluíndo' : 'Alterando')} Outros Recebimentos`}
            modal className="p-fluid" onHide={hideDialog}
          >
            <OutrosRecebimentosForm
              setOutrosRecebimentosDialog={setOutrosRecebimentosDialog}
              outroRecebimento={outroRecebimento}
              reload={load}
              deleteOutrosRecebimentos={deleteOutrosRecebimentos}
              setDeleteOutrosRecebimentos={setDeleteOutrosRecebimentos}
              confirmaEstorno={confirmaEstorno}
              setConfirmaEstorno={setConfirmaEstorno}
              deletar={deletar}
            />
          </SCDialog>
        </div>
      </div>
    </div>
  );
};

export default OutrosRecebimentos;
